import { cloneDeep } from "lodash";

import { defaultModelState } from "src/prediction-model/utility/default-model-values";

import {
  defaultHeatpumpConfiguration,
  defaultComponent,
  webConstants,
  quote,
} from "./default-values";
import { ProjectState } from "./types";

export const defaultProjectState: ProjectState = {
  id: "project123",
  uid: "",
  quote,
  quoteId: "",
  profile: "green",
  pdfURL: "",
  version: 1,
  type: "b2c",
  shortId: -1,
  accessCount: 0,
  projectName: "",
  energyProductionParams: {
    roofPitch: 30.0,
    increasedCapacityFromAdminPanel: 0,
    productionReducedDueToShade: 0,
    productionByBatterySimulation: 0,
  },

  components: {
    waterHeating: false,
    inverter: {
      items: [{
        ...cloneDeep(defaultComponent),
        quantity: 1,
      }],
      preferences:  cloneDeep(defaultComponent.preferences),
    },
    wallbox: cloneDeep(defaultComponent),
    cars: [],
    heatpump: {
      ...cloneDeep(defaultComponent),
      configuration: cloneDeep(defaultHeatpumpConfiguration),
    },
    battery: {
      ...cloneDeep(defaultComponent),
    },
    solarPanel: {
      ...cloneDeep(defaultComponent),
    },
    additionalSoftware: {
      ...cloneDeep(defaultComponent),
      quantity: 1,
    },
    additionalHardware: {
      ...cloneDeep(defaultComponent),
      quantity: 1,
    },
    EMS: {
      ...cloneDeep(defaultComponent),
      quantity: 1,
    },
  },

  energy: {
    solarPanelProduction: 0,
    yearlyConsumption: 0,
  },

  images: {
    houseTop: {
      buildingYearId: "Before 1980",
      imageURLs: [],
      description: "",
    },
    houseBottom: {
      imageURLs: [],
      description: "",
    },
    electricMeter: {
      imageURLs: [],
      meterTypeId: "Digital meter",
      description: "",
    },
    ThreeDModels: {
      imageURLs: [],
      description: "",
      planYourSelf: false,
      pvSolModifyModel: false,
      threeDpvModelSunnyDesign: false,
      threeDpvModelSolarEdgeDesign: false,
      selectedFiles: [],
    },
  },

  bakeModel: {
    id: "",
    artifacts: null,
  },

  threeDModels: {
    active: null,
    models: {},
  },
  trackProgress: {
    percentage: 0,
    time: "",
    elapsed: 0,
  },

  bakeModelAdminGenerated: {},

  household: {
    buildingType: "single",
    consumption: 3500,
    calculatedConsumption: 0,
    consumptionProfile: 1,
    kwaPerEmployee: 0,
    people: 4,
    selfSupply: 56,
    systemConsept: "Vollstromlieferung (Mieterstrom)",
    households: 4,
    expectedTenants: 4,
    electricityPrice: 0.21,
    expectedSolar: 1500,
    expectedHardware: 2400,
    expectedSoftware: 49,
    measuringPoint: "Metergrid rental",
    generalElectricity: 2000,
    multiTenantLicenceFees: 6,
    operationConcept: "Single-counter-model",
    operationModal: "Voll-Service",
  },

  financialDetails: {
    costs: {
      appliedProjectCost: 0,
      totalFinancingCost: 0,
      totalProjectCost: 0,
      componentCosts: {
        carBuyingCost: 0,
        constantsCost: 0,
        totalCost: 0,
        batteryCost: 0,
        wallboxCost: 0,
        solarPanelCost: 0,
        heatpumpCost: 0,
        inverterCost: 0,
        additionalSoftwareCost: 0,
        additionalHardwareCost: 0,
        EMSCost: 0,
      },
      savings: {
        feedInSavings: 0,
        electricityCostSavings: 0,
        mobilitySavings: 0,
        gasSavings: 0,
        totalSavings: 0,
        futureOilGasCostIncrease: 5,
      },
      gridCosts: {
        evGridCost: 0,
        heatpumpGridCost: 0,
        householdGridCost: 0,
        heatingRodGridCost: 0,
        totalGridCost: 0,
      },
    },
    amortizationTime: 0,
    buy: {},
    estimatedYearlyPriceIncrease: 5,
    expectedReturnTime: 25,
    feedInSavings: 0,
    feedInTariff: 0.068,
    increaseRateByBank: 2,
    isFinancing: false,
    loan: {
      totalFinancingAmount: 0,
      yearlyFinancingAmount: 0,
    },
    pricePerKwh: 0.35,
    selectedFeedInReason: "feed-in-tariff",
  },

  vendor: {
    freeChoiceInstaller: false,
    selected: [],
    suggested: [],
  },

  payment: {
    paymentIntent: "",
    status: "pending",
  },

  constants: {
    JAZ: 3,
    heatpumpShare: 80,
  },

  webConstants: {
    country: "germany",
    constants: cloneDeep(webConstants),
  },

  deal: {
    _id: "",
    address: "",
    dealId: "",
    uid: "",
  },

  model: cloneDeep(defaultModelState),

  selectedInstallers: [],
  installerDetails: [],
  threeDObjDetails: {
    createdLines: [],
    createdPoints: [],
    createdLineLoops: [],
    areas: [],
    perimeters: [],
    panelsQuantity: 0,
    scale: 0
  },
  googleModel: false,
  polygonViseDetails: [],
  maxWidth3DViewer: false,
  maxWidth3DViewerWithImages: false,
  installer: "",
  projectPdf: "",
  subsidy: [],
  manualSubsidyDetails: undefined,
};

export const initialState: ProjectState = cloneDeep(defaultProjectState);
