import { clone } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addAdditionalHardware = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addAdditionalHardware>,
): any => {
  const item = clone(payload);

  return {
    ...currentState,
    components: {
      ...currentState.components,
      additionalHardware: {
        ...currentState.components.additionalHardware,
        item,
      },
    },
  };
};
