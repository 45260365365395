import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ProductDB } from "src/db-types";
import { getAverageCapacity } from "src/helpers";
import { findOptimalInverter } from "src/utils";

import { ProjectActions } from "src/redux/actionCreators";
import { getInverters } from "src/redux/products/selector";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

export const useSynchronizeInverterPreference = (): void => {
  const dispatch = useDispatch();

  const filteredSolarPanels = useSelector(ProductSelectors.getFilteredSolarPanels);
  const { quantity } = useSelector(ProjectSelectors.getSolarPanel);
  const heatDimension = useSelector(ProjectSelectors.getHeatpump)?.configuration?.heatpumpDimension;
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const inverters = useSelector(getInverters);

  useEffect(() => {
    let systemCapacity = quantity * getAverageCapacity(filteredSolarPanels);
    if (solarPanelProduct?.item) {
      systemCapacity = (Number(solarPanelProduct?.item.capacity) * quantity);
    }
    dispatch(ProjectActions.deleteInverter())
    const bestCombination = findOptimalInverter(systemCapacity,inverters);
    if(bestCombination.inverters) {
      bestCombination.inverters.forEach((inverter) => {
        dispatch(
          ProjectActions.addInverter(inverter)
        )
      })
    }
    
    // dispatch(
    //   ProjectActions.updateInverter({
    //     preferences: {
    //       applicableMaxCapacity: systemCapacity,
    //     },
    //   }),
    // );
    dispatch(
      ProjectActions.updateHeatpump({
        preferences: {  
          applicableMaxCapacity: (heatDimension * 1000),
        },
      }),
    );
  }, [dispatch, filteredSolarPanels, quantity]);
};
