import { useEffect } from "react";
import { useSelector } from "react-redux";

import { WEB_CONSTANTS_KEYS } from "src/data";
import { InstallerDB, ProductDB } from "src/db-types";
import { getProductCost } from "src/helpers/product";
import { getAverageProductCost } from "src/helpers/product/getProductCostByFilter";
import { RecursivePartial, getNumber } from "src/utils";

import { ProjectActions } from "src/redux/actionCreators";
import { FilteredProductsMap } from "src/redux/products";
import {
  BaseComponent,
  CarComponent,
  ProjectFinancialDetails,
  ProjectType,
} from "src/redux/project";
import { FilterableBaseComponent } from "src/redux/project/types/components";
import { ProjectWebConstants, WebConstant } from "src/redux/project/types/project-constants";
import { ProductSelectors, ProjectSelectors, UserSelectors } from "src/redux/selectors";

import { ProductFilters, useAppDispatch } from ".";

interface GetComponentCostProps {
  comp: BaseComponent;
  projectType: ProjectType;
}
export const getComponentCost = ({ comp, projectType }: GetComponentCostProps): number => {
  const priceKey: keyof ProductDB = projectType === "b2b" ? "priceB2b" : "priceB2c";
  let cost = 0;

  const hasNoItem = comp.quantity === 0;
  if (hasNoItem) return cost;

  if (comp.isExcludeCost) return 0;

  const productCost = comp.quantity * getNumber(comp.item?.[priceKey]);

  let extendedWarrantyCost = 0;
  const hasWarranty = comp.extendedWarranty;
  if (hasWarranty) {
    const defaultWarranty = productCost * 0.01;
    const customWarranty = comp.item?.extendedWarrantyCost;
    extendedWarrantyCost = getNumber(customWarranty ?? defaultWarranty);
  }

  cost = productCost + extendedWarrantyCost;
  return cost;
};

interface GetExtraCostForConstantProps {
  constant: WebConstant;
  projectType: ProjectType;
  solarPanelProduction: number;
  solarPanelQty: number;
  allComponentsCost: number;
}
const getExtraCostForConstant = ({
  constant,
  projectType,
  solarPanelProduction,
  allComponentsCost,
  solarPanelQty,
}: GetExtraCostForConstantProps): number => {
  const priceKey = projectType ? "priceB2b" : "priceB2c";

  switch (constant.type) {
    case WEB_CONSTANTS_KEYS.FIX_FOR_PROJECT:
      return getNumber(String(constant[priceKey] || "0"));

    case WEB_CONSTANTS_KEYS.DEPENDING_ON_KWH:
      return (getNumber(String(constant[priceKey] || "0")) * solarPanelProduction) / 1000;

    case WEB_CONSTANTS_KEYS.FIX_PER_PANEL:
      return getNumber(String(constant[priceKey] || "0")) * solarPanelQty;

    case WEB_CONSTANTS_KEYS.PERCENTAGE_OF_PROJECT_COST:
      return (getNumber(String(constant[priceKey] || "0")) * allComponentsCost) / 100;

    case WEB_CONSTANTS_KEYS.DISTANCE_TO_PROJECT:
      return getNumber(String(constant[priceKey] || "0"));
    default:
      return 0;
  }
};

interface GetFinancingCostProps {
  totalCost: number;
  increaseRateByBank: number;
}
export const getFinancingCost = ({
  increaseRateByBank,
  totalCost,
}: GetFinancingCostProps): number => {
  const DURATION_YEARS = 12;
  const DURATION_MONTHS = DURATION_YEARS * 12;

  const interestRate = increaseRateByBank / 100;

  const A = 1 + interestRate / 12;
  const B = A ** DURATION_MONTHS * (A - 1);
  const C = A ** DURATION_MONTHS - 1;
  const monthlyPaymentCost = totalCost * (B / C);

  const totalFinancingCost = monthlyPaymentCost * DURATION_MONTHS;
  return totalFinancingCost;
};

const calculateTaxedPrice = (value: number, tax: number | undefined) => {
  return value * (1 + (tax || 0) / 100);
};

interface IGetProjectCosts {
  cars: CarComponent[];
  filteredProducts: FilteredProductsMap;
  webConstants: ProjectWebConstants;
  increaseRateByBank: number;
  isFinancing: boolean;
  projectType: ProjectType;
  solarPanelProduction: number;
  solarPanel: FilterableBaseComponent;
  heatpump: FilterableBaseComponent;
  inverter: {
    items: BaseComponent[];
    preferences: ProductFilters;
  };
  wallbox: FilterableBaseComponent;
  battery: FilterableBaseComponent;
  EMS: FilterableBaseComponent;
  installer: InstallerDB | null;
}
export const getProjectCosts = (
  props: IGetProjectCosts,
): RecursivePartial<ProjectFinancialDetails> => {
  const {
    cars,
    filteredProducts,
    increaseRateByBank,
    isFinancing,
    webConstants,
    projectType,
    solarPanelProduction,
    solarPanel,
    battery,
    heatpump,
    wallbox,
    inverter,
    EMS,
    installer,
  } = props;

  const carsCost = cars
    .filter((car) => !car.isLeasing)
    .filter((car) => car.item)
    .reduce(
      (acc, car) => acc + 25 * 12 * getProductCost({ product: car.item as ProductDB, projectType }),
      0,
    );

  const getBulkPrice = (item: ProductDB, quantity: number, installerId: string) => {
    const bulkPriceProduct = item?.bulkPrice?.[installerId];
    if (!bulkPriceProduct) return item?.priceB2c;

    const relevantBulkPrice = bulkPriceProduct
      .filter((bulkPrice) => Number(bulkPrice.quantity) <= quantity)
      .sort((a, b) => Number(b.quantity) - Number(a.quantity))[0];

    return relevantBulkPrice?.priceb2c || item?.priceB2c;
  };

  const solarPanelCost = solarPanel?.item
    ? calculateTaxedPrice(
        getBulkPrice(solarPanel?.item, solarPanel?.quantity, `${installer?._id || "ADMIN"}`) *
          solarPanel?.quantity,
        solarPanel?.item?.tax,
      )
    : getAverageProductCost(filteredProducts.solarPanels);
  const heatpumpCost = heatpump?.item
    ? calculateTaxedPrice(
        getBulkPrice(heatpump?.item, heatpump?.quantity, `${installer?._id || "ADMIN"}`) *
          heatpump?.quantity,
        heatpump?.item?.tax,
      )
    : getAverageProductCost(filteredProducts.heatpumps);
  const inverterCost = Array.isArray(inverter?.items)
    ? inverter.items.reduce((total, item) => {
      if (!item?.item) return total;
        const price = getBulkPrice(item.item, item?.quantity, `${installer?._id || "ADMIN"}`) * item?.quantity;
        return total + calculateTaxedPrice(price, item?.item?.tax);
      }, 0)
    : getAverageProductCost(filteredProducts.inverters);
  const wallboxCost = wallbox?.item
    ? calculateTaxedPrice(
        getBulkPrice(wallbox?.item, wallbox?.quantity, `${installer?._id || "ADMIN"}`) *
          wallbox?.quantity,
        wallbox?.item?.tax,
      )
    : getAverageProductCost(filteredProducts.wallboxes);
  const batteryCost = battery?.item
    ? calculateTaxedPrice(
        getBulkPrice(battery?.item, battery?.quantity, `${installer?._id || "ADMIN"}`) *
          battery?.quantity,
        battery?.item?.tax,
      )
    : getAverageProductCost(filteredProducts.batteries);

  const EMSCost = EMS?.item
    ? calculateTaxedPrice(
        getBulkPrice(EMS?.item, EMS?.quantity, `${installer?._id || "ADMIN"}`) * EMS?.quantity,
        EMS?.item?.tax,
      )
    : getAverageProductCost(filteredProducts.batteries);
  const componentsCost =
    carsCost + solarPanelCost + inverterCost + heatpumpCost + wallboxCost + batteryCost + EMSCost;

  const allConstantsCost = webConstants.constants.reduce(
    (acc, constant) =>
      acc +
      getExtraCostForConstant({
        constant,
        projectType,
        allComponentsCost: componentsCost,
        solarPanelProduction,
        solarPanelQty: solarPanel.quantity,
      }),
    0,
  );
  const projectCost = componentsCost + allConstantsCost;
  const finCost = getFinancingCost({ increaseRateByBank, totalCost: projectCost });

  const appliedCost = isFinancing ? finCost : projectCost;

  return {
    costs: {
      totalFinancingCost: finCost,
      appliedProjectCost: appliedCost,
      totalProjectCost: projectCost,
      componentCosts: {
        constantsCost: allConstantsCost,
        carBuyingCost: carsCost,
        heatpumpCost,
        inverterCost,
        EMSCost,
        solarPanelCost,
        wallboxCost,
        batteryCost,
        totalCost: componentsCost,
      },
    },
  };
};

export const useProjectCosts = (): void => {
  const dispatch = useAppDispatch();

  const { isFinancing, increaseRateByBank } = useSelector(ProjectSelectors.getFinancialDetails);
  const webConstants = useSelector(ProjectSelectors.getWebConstants);
  const projectType = useSelector(ProjectSelectors.getProjectType);
  const installer = useSelector(UserSelectors.getInstaller);
  const {
    battery,
    cars,
    heatpump,
    inverter,
    solarPanel,
    wallbox,
    additionalHardware,
    additionalSoftware,
    EMS,
  } = useSelector(ProjectSelectors.getComponents);
  const filteredProducts = useSelector(ProductSelectors.getAllFilteredProducts);
  const { solarPanelProduction } = useSelector(ProjectSelectors.getEnergy);

  useEffect(() => {
    const carsCost = cars
      .filter((car) => !car.isLeasing)
      .filter((car) => car.item)
      .reduce((acc, car) => acc + getNumber(car.item?.buyingCarPrice), 0);

    const getBulkPrice = (item: ProductDB, quantity: number, installerId: string) => {
      const bulkPriceProduct = item?.bulkPrice?.[installerId];
      if (!bulkPriceProduct) return item?.priceB2c;

      const relevantBulkPrice = bulkPriceProduct
        .filter((bulkPrice) => Number(bulkPrice.quantity) <= quantity)
        .sort((a, b) => Number(b.quantity) - Number(a.quantity))[0];

      return relevantBulkPrice?.priceb2c || item?.priceB2c;
    };

    const solarPanelCost = solarPanel?.item
      ? calculateTaxedPrice(
          getBulkPrice(solarPanel?.item, solarPanel?.quantity, `${installer?._id || "ADMIN"}`) *
            solarPanel?.quantity,
          solarPanel?.item?.tax,
        )
      : getAverageProductCost(filteredProducts.solarPanels);

    const heatpumpCost = heatpump.isExcludeCost
      ? 0
      : heatpump?.item
      ? calculateTaxedPrice(
          getBulkPrice(heatpump?.item, heatpump?.quantity, `${installer?._id || "ADMIN"}`) *
            heatpump?.quantity,
          heatpump?.item?.tax,
        )
      : getAverageProductCost(filteredProducts.heatpumps);

      const inverterCost = Array.isArray(inverter?.items) && inverter.items.length > 0
      ? inverter.items.reduce((total, item) => {
          if (!item?.item) return total;
          const price = getBulkPrice(item.item, item.quantity, `${installer?._id || "ADMIN"}`) * item.quantity;
          return total + calculateTaxedPrice(price, item.item.tax);
        }, 0)
      : getAverageProductCost(filteredProducts.inverters);

    const wallboxCost = wallbox.isExcludeCost
      ? 0
      : wallbox?.item
      ? calculateTaxedPrice(
          getBulkPrice(wallbox?.item, wallbox?.quantity, `${installer?._id || "ADMIN"}`) *
            wallbox?.quantity,
          wallbox?.item?.tax,
        )
      : getAverageProductCost(filteredProducts.wallboxes);

    const batteryCost = battery.isExcludeCost
      ? 0
      : battery?.item
      ? calculateTaxedPrice(
          getBulkPrice(battery?.item, battery?.quantity, `${installer?._id || "ADMIN"}`) *
            battery?.quantity,
          battery?.item?.tax,
        )
      : getAverageProductCost(filteredProducts.batteries);

    const additionalSoftwareCost = additionalSoftware?.item
      ? calculateTaxedPrice(
          getBulkPrice(
            additionalSoftware?.item,
            additionalSoftware?.quantity,
            `${installer?._id || "ADMIN"}`,
          ) * additionalSoftware?.quantity,
          additionalSoftware?.item?.tax,
        )
      : 0;

    const additionalHardwareCost = additionalHardware?.item
      ? calculateTaxedPrice(
          getBulkPrice(
            additionalHardware?.item,
            additionalHardware?.quantity,
            `${installer?._id || "ADMIN"}`,
          ) * additionalHardware?.quantity,
          additionalHardware?.item?.tax,
        )
      : 0;

    const EMSCost = EMS?.item
      ? calculateTaxedPrice(
          getBulkPrice(EMS?.item, EMS?.quantity, `${installer?._id || "ADMIN"}`) * EMS?.quantity,
          EMS?.item?.tax,
        )
      : 0;

    const componentsCost =
      carsCost +
      solarPanelCost +
      inverterCost +
      heatpumpCost +
      wallboxCost +
      batteryCost +
      additionalSoftwareCost +
      additionalHardwareCost +
      EMSCost;

    const allConstantsCost = webConstants.constants.reduce(
      (acc, constant) =>
        acc +
        getExtraCostForConstant({
          constant,
          projectType,
          allComponentsCost: componentsCost,
          solarPanelProduction,
          solarPanelQty: solarPanel.quantity,
        }),
      0,
    );

    const projectCost = componentsCost + allConstantsCost;
    const finCost = getFinancingCost({ increaseRateByBank, totalCost: projectCost });

    const appliedCost = isFinancing ? finCost : projectCost;

    dispatch(
      ProjectActions.updateFinancialDetails({
        costs: {
          totalFinancingCost: finCost,
          appliedProjectCost: appliedCost,
          totalProjectCost: projectCost,
          componentCosts: {
            constantsCost: allConstantsCost,
            carBuyingCost: carsCost,
            heatpumpCost,
            inverterCost,
            solarPanelCost,
            wallboxCost,
            batteryCost,
            additionalSoftwareCost,
            additionalHardwareCost,
            EMSCost,
            totalCost: componentsCost,
          },
        },
      }),
    );
  }, [
    battery,
    cars,
    dispatch,
    filteredProducts.batteries,
    filteredProducts.heatpumps,
    filteredProducts.inverters,
    filteredProducts.solarPanels,
    filteredProducts.wallboxes,
    heatpump,
    increaseRateByBank,
    inverter,
    isFinancing,
    projectType,
    solarPanel,
    solarPanelProduction,
    wallbox,
    webConstants.constants,
    additionalSoftware,
    additionalHardware,
    EMS,
  ]);
};
