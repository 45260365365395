import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ProductDB, ProductWithPrice } from "src/db-types";
import { getProductCost, sortProductByPrice } from "src/helpers";

import { ProductViewCard } from "./components";

import { ProjectSelectors, UserSelectors } from "src/redux/selectors";

const getProductQuantity = (
  product: ProductDB,
  qtyToBuy: number,
  solarPanelCount: number,
): number => {
  let qtyForThisProduct = qtyToBuy;
  const isMicroInverter = product.type === "inverter" && product.subtype === "premium";
  if (isMicroInverter) {
    qtyForThisProduct = solarPanelCount;
  }

  return qtyForThisProduct;
};
interface ProductListProps {
  products: ProductWithPrice[];
  qtyToBuy: number;
  productName: string;
  handleCloseModal?: () => void;
}
export const ProductListing: React.FC<ProductListProps> = (props: ProductListProps) => {
  const { products, qtyToBuy, productName, handleCloseModal } = props;

  const { quantity: solarPanelCount } = useSelector(ProjectSelectors.getSolarPanel);
  const projectType = useSelector(ProjectSelectors.getProjectType);
  const installer = useSelector(UserSelectors.getInstaller);

  const [sortedProducts, setSortedProducts] = useState<ProductDB[]>([]);

  useEffect(() => {
    if (installer?._id) {
      const standardProducts = products.filter(
        (product) => product.installerAccess?.[installer?._id]?.isStandard,
      );
      const nonStandardProducts = products.filter(
        (product) => !product.installerAccess?.[installer?._id]?.isStandard,
      );
      const sortedNonStandardProducts = sortProductByPrice(nonStandardProducts);
      const sortedProducts = [...standardProducts, ...sortedNonStandardProducts];
      setSortedProducts(sortedProducts);
    } else {
      setSortedProducts(sortProductByPrice(products));
    }
  }, [products, projectType, qtyToBuy, solarPanelCount, installer]);

  return (
    <div className="product-view-listing">
      {sortedProducts.map((product) => {
        let qtyForThisProduct = qtyToBuy;
        const isMicroInverter = product.type === "inverter" && product.subtype === "premium";
        if (isMicroInverter) {
          qtyForThisProduct = solarPanelCount;
        }

        return (
          <div key={product._id}>
            <ProductViewCard
              product={product}
              quantityToBuy={qtyForThisProduct}
              productName={productName}
              handleCloseModal={handleCloseModal}
            />
            <Divider />
          </div>
        );
      })}
    </div>
  );
};
