import { cloneDeep } from "lodash";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { DEFAULT_BATTERY_KWH } from "src/data";

import { useAddBatteryInverterChange } from "./useAddBatteryInverterChange";
import { defaultFilters } from "./useProductFilter";

import { ProjectActions } from "src/redux/actionCreators";
import { defaultHeatpumpConfiguration } from "src/redux/project/default-values";
import { ProjectSelectors } from "src/redux/selectors";

import { useAppSelector } from ".";

const useAddDeleteComponent = () => {
  const dispatch = useDispatch();
  const { restoreInverterPreference } = useAddBatteryInverterChange();
  const { convertInverterToHybrid } = useAddBatteryInverterChange();
  const { heatpumpShare } = useAppSelector(ProjectSelectors.getConstants);

  const deleteBattery = useCallback(() => {
    dispatch(ProjectActions.deleteBattery());
    restoreInverterPreference();
  }, [dispatch, restoreInverterPreference]);

  const deleteWallbox = useCallback(() => {
    dispatch(ProjectActions.deleteWallbox());
  }, [dispatch]);

  const deleteHeatpump = useCallback(() => {
    dispatch(ProjectActions.deleteHeatpump());
  }, [dispatch]);

  const deleteEMS = useCallback(() => {
    dispatch(ProjectActions.deleteEMS());
  }, [dispatch]);

  const addBatteryHandler = useCallback(() => {
    dispatch(
      ProjectActions.addBattery({
        item: null,
        extendedWarranty: false,
        isExcludeCost: false,
        quantity: 1,
        preferences: {
          ...cloneDeep(defaultFilters),
          applicableMaxCapacity: DEFAULT_BATTERY_KWH,
        },
      }),
    );
    const result = convertInverterToHybrid();
    if (!result) {
      dispatch(ProjectActions.deleteBattery());
    }
  }, [convertInverterToHybrid, dispatch]);

  const addHeatpump = useCallback(() => {
    const blockingFactor = 24 / (24 - defaultHeatpumpConfiguration.blockingTimes);
    const capacity =
      defaultHeatpumpConfiguration.buildingStandard *
      defaultHeatpumpConfiguration.totalAreaToBeHeated *
      blockingFactor;
    const dimension = (capacity * heatpumpShare) / 100;

    dispatch(
      ProjectActions.addHeatpump({
        item: null,
        extendedWarranty: false,
        isExcludeCost: false,
        quantity: 1,
        preferences: {
          ...cloneDeep(defaultFilters),
          applicableMaxCapacity: dimension * 1000,
        },
        configuration: {
          ...cloneDeep(defaultHeatpumpConfiguration),
        },
      }),
    );
  }, [dispatch, heatpumpShare]);

  const addWallbox = useCallback(() => {
    dispatch(
      ProjectActions.addWallbox({
        item: null,
        extendedWarranty: false,
        isExcludeCost: false,
        quantity: 1,
        preferences: {
          ...cloneDeep(defaultFilters),
        },
      }),
    );
  }, [dispatch]);

  const addEMS = useCallback(() => {
    dispatch(
      ProjectActions.addEMS({
        item: null,
        extendedWarranty: false,
        isExcludeCost: false,
        quantity: 1,
        preferences: {
          ...cloneDeep(defaultFilters),
        }
    }),
    );
  }, [dispatch]);

  return {
    deleteBattery,
    deleteWallbox,
    deleteHeatpump,
    addWallbox,
    addHeatpump,
    addBatteryHandler,
    addEMS,
    deleteEMS
  };
};

export default useAddDeleteComponent;
