import React from "react";
import { useSelector } from "react-redux";

import { useIsComponentAdded } from "src/hooks";

import useAddDeleteComponent from "src/hooks/useAddDeleteComponent";
import { useProject } from "src/pages/UserOverview/components/ProjectCard/hooks";
import { useLoadAllProjects } from "src/pages/UserOverview/hooks";

import ProductSection from "./ProductSection";

import { PopupSelectors, ProjectSelectors } from "src/redux/selectors";

interface OverviewTypes {
  documentRef: any;
}

const OverviewSelection: React.FC<OverviewTypes> = ({documentRef}) => {
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const batteryProduct = useSelector(ProjectSelectors.getBattery);
  const inverterProduct = useSelector(ProjectSelectors.getInverter);
  const heatPumpProduct = useSelector(ProjectSelectors.getHeatpump);
  const wallBoxProduct = useSelector(ProjectSelectors.getWallbox);
  const {
    deleteBattery,
    addBatteryHandler,
    addHeatpump,
    addWallbox,
    deleteHeatpump,
    deleteWallbox,
  } = useAddDeleteComponent();
  const { isBatteryAdded, isWallboxAdded, isHeatpumpAdded, isInvertedAdded } = useIsComponentAdded();

  const {
    data: { standalone },
  } = useSelector(PopupSelectors.getProductPopup);

  return (
    <div key="battery">
      <ProductSection
        productName="solarPanel"
        item={solarPanelProduct.item}
        isToShowButtons={false}
        isItemAdded={true}
        standalone={standalone}
        isToShowReplaceButton={false}
        documentRef={documentRef}
      />
      {isInvertedAdded &&
        inverterProduct?.map((itm) => (
          <ProductSection
            key={itm.item?._id}
            productName="inverter"
            item={itm.item}
            isToShowButtons={false}
            isItemAdded={isInvertedAdded}
          />
        ))}
      {isBatteryAdded && (
        <ProductSection
          productName="battery"
          item={batteryProduct.item}
          onAddProduct={addBatteryHandler}
          onDeleteClick={deleteBattery}
          isItemAdded={isBatteryAdded}
          standalone={standalone}
        />
      )}
      {isWallboxAdded && (
        <ProductSection
          productName="wallbox"
          item={wallBoxProduct.item}
          onAddProduct={addWallbox}
          onDeleteClick={deleteWallbox}
          isItemAdded={isWallboxAdded}
          standalone={standalone}
        />
      )}
      {isHeatpumpAdded && (
        <ProductSection
          productName="heatpump"
          item={heatPumpProduct.item}
          onAddProduct={addHeatpump}
          onDeleteClick={deleteHeatpump}
          isItemAdded={isHeatpumpAdded}
          standalone={standalone}
        />
      )}
    </div>
  );
};

export default OverviewSelection;
