import { clone } from "lodash";


import { actionCreators, ProjectState } from "src/redux/project";

export const deleteSubsidy = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.deleteSubsidy>,
): ProjectState => {
  const newState = clone(currentState);

  const subsidyID = payload._id;

  const subsidyUpdated = newState?.subsidy?.filter(
    (subsidy: { _id: any }) => subsidy._id !== subsidyID,
  );

  return { ...newState, subsidy: subsidyUpdated };
};

export const deleteManualSubsidy = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.deleteSubsidy>,
): ProjectState => {
  const newState = clone(currentState);


  return { ...newState, manualSubsidyDetails: null };
};
