import { ChevronDown, Download, TriangleAlert } from "lucide-react";
import { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import AutoStringInput from "src/components/configuration/AutoStringInput";

import { useNumberFormat } from "src/hooks";

import { PopupSkeleton, TabBar } from "src/shared/components";

import { POPUP_KEYS } from "src/redux/popups";
import { closePopup } from "src/redux/popups/action/action.creators";

interface ISystemInspection {
  data: any;
}

export const SystemInspection: React.FC<ISystemInspection> = ({ data }: ISystemInspection) => {
  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();
  const dispatch = useDispatch();

  const TAB_KEYS = {
    WIRINGDESIGN: "wiring design",
    INTERCONNECTIONQUALITY: "interconnection quality",
  };

  const criteria = [
    { label: t("Number of inverters (as small as possible)"), value: 100 - (data.numberOfInverter * 10) },
    { label: t("Number of inverter models (as small as possible)"), value: 50 },
    { label: t("Number of interconnections (as small as possible)"), value: 100 - (data.numberOfStrings * 5) },
    { label: t("Dimensioning factors (as close to 115% as possible)"), value: 50 },
    { label: t("Difference in dimensioning factors (as small as possible)"), value: 50 },
    { label: t("Efficiency loss of inverters (as small as possible)"), value: 50 },
  ];

  const systemData = [
    {
      icon: "✔️",
      title: t("MPP Voltage in V"),
      mppMaxValue: data.selectedProducts.inverter.maxDCInputVoltage,
      mppMinValue: data.selectedProducts.inverter.minDCInputVoltage,
      details: (
        <div>
          <div>
            {data?.selectedProducts?.inverter?.minDCInputVoltage && (
              <>
                {t("Min. MPP Voltage")}: {data.selectedProducts.inverter.minDCInputVoltage}V
              </>
            )}
          </div>
          <div className="flex justify-between">
            <div className="flex gap-4">
              {data.selectedProducts.inverter.minDCInputVoltage && <div>{t("Umin")}: {data.selectedProducts.inverter.minDCInputVoltage}V</div>}
              {data.selectedProducts.inverter.maxDCInputVoltage && <div>{t("Umax")}: {data.selectedProducts.inverter.maxDCInputVoltage}V</div>}
            </div>
            <div>
              {data?.selectedProducts?.inverter?.maxDCInputVoltage && (
                <>
                  {t("Max. MPP Voltage")}: {data.selectedProducts.inverter.maxDCInputVoltage}V
                </>
              )}
            </div>
          </div>
        </div>
      ),
      range: [100, 1000],
      description: [t("Umin at 70°C, 1000 W/m2"), t("Umax at 15°C, 1000 W/m2")],
      valueBar: [
        { color: "bg-yellow-500", value: 100 },
        { color: "bg-green-500", value: 300 },
        { color: "bg-orange-500", value: 100 },
        { color: "bg-red-500", value: 500 },
      ],
    },
    {
      icon: "✔️",
      title: t("Open Circuit Voltage in V"),
      details: (
        <div className="flex justify-between">
          <div>{t("U0")}: 220.1V</div>
          <div>
            {data?.maxVoltage && (
              <>
                {t("Max. System Voltage Inverter")}: {formatNumber(data.maxVoltage)}V
              </>
            )}
          </div>
        </div>
      ),
      range: [200, 1050],
      description: [
        t("U0 at {{temp}}°C, 1000 W/m2", {
          temp: data?.selectedProducts?.inverter?.T_MIN || "N/A",
        }),
      ],
      valueBar: [
        { color: "bg-green-500", value: 300 },
        { color: "bg-orange-500", value: 100 },
        { color: "bg-red-500", value: 450 },
      ],
    },
    {
      icon: "✔️",
      title: t("Current in A"),
      details: (
        <div>
          <div className="flex gap-2 justify-end">
            {data?.selectedProducts?.solarPanel?.maxDCCurrent && (
              <div>
                {t("Max. Input Current")}: {data.selectedProducts.solarPanel.maxDCCurrent}V
              </div>
            )}
            {data?.selectedProducts?.solarPanel?.T_STC && (
              <div>
                {t("Max. Short Circuit Current")}: {data.selectedProducts.solarPanel.T_STC}V
              </div>
            )}
          </div>
          <div className="flex">
            {data?.selectedProducts?.solarPanel?.maxDCCurrent && (
              <div>
                {t("Current at MPP")}: {data.selectedProducts.solarPanel.maxDCCurrent}V
              </div>
            )}
          </div>
        </div>
      ),
      range: [10, 30],
      valueBar: [
        { color: "bg-green-500", value: 5 },
        { color: "bg-orange-500", value: 5 },
        { color: "bg-red-500", value: 10 },
      ],
    },
    {
      icon: "✔️",
      title: t("Power in kW"),
      details: (
        <div className="flex justify-between">
          {data?.selectedProducts?.inverter?.maxOutputPower && (
            <div>
              {t("Power")}: {data.selectedProducts.inverter.maxOutputPower}P
            </div>
          )}
          {data?.selectedProducts?.inverter?.maxDCInputVoltage && (
            <div>
              {t("Max. Input Power")}: {data.selectedProducts.inverter.maxDCInputVoltage}P
            </div>
          )}
        </div>
      ),
      range: [1, 10],
      valueBar: [
        { color: "bg-green-500", value: 6 },
        { color: "bg-orange-500", value: 1 },
        { color: "bg-red-500", value: 3 },
      ],
    },
  ];

  const tabs = useMemo(() => {
    return [
      { key: TAB_KEYS.WIRINGDESIGN, label: t("wiring design") },
      { key: TAB_KEYS.INTERCONNECTIONQUALITY, label: t("interconnection quality") },
    ];
  }, [t]);
  const [selectedTab, setSelectedTab] = useState(TAB_KEYS.WIRINGDESIGN);

  const handleTabClick = useCallback((tabKey) => {
    setSelectedTab(tabKey);
  }, []);

  const closeDialog = (): void => {
    dispatch(closePopup(POPUP_KEYS.inverter_auto_string_details));
  };

  return (
    <PopupSkeleton handleClose={closeDialog} open={true}>
      <div>
        <div className="w-full min-h-[575px] h-full overflow-auto overflow-x-hidden m-0 p-4 pt-5 flex justify-start">
          {/* Left Panel */}
          <div className="w-[35%]">
            <div className="flex mb-7 gap-7 text-xl">
            <h2 className="font-semibold">{t("System inspection")}</h2>
            <div className="flex items-center text-lg cursor-pointer text-[#2D4764]">
              <Download className="w-4 h-4 mr-2" /> {t("PDF Print")}
            </div>
            </div>
            <div className="space-y-4">
              <div className="bg-white p-2 rounded-lg">
                <div className="flex items-center gap-2 mb-4">
                  <ChevronDown className="w-5 h-5 text-[#2B3674]" />
                  <h2 className="text-lg font-medium text-[#2B3674]">
                    {data?.selectedProducts?.inverter?.name}
                  </h2>
                  {/* <span className="bg-[#FFD66B] px-2 py-0.5 rounded-full text-sm">{90}%</span> */}
                </div>

                <div className="ml-6 space-y-2 text-[#2B3674]">
                  <p>
                    {data?.cablingLoops?.reduce((curr: any, prev: any) => curr + prev?.length, 0)} * {data?.selectedProducts.solarPanel?.applicableMaxCapacity} kWp ={" "}
                    {((data?.cablingLoops?.reduce((curr: any, prev: any) => curr + prev?.length, 0) * (data?.selectedProducts.solarPanel?.applicableMaxCapacity ?? 0))/1000).toFixed(2)} kWP
                  </p>
                  {!!data?.maxDcVoltage && (
                    <p>
                      {t("Maximum voltage")} (Voc): {data?.maxDcVoltage.toFixed(2)} V
                    </p>
                  )}
                  {!isNaN(data?.maxDcCurrent) && !!data?.maxDcCurrent && (
                    <p>
                      {t("Maximum current")} (Isc): {data?.maxDcCurrent.toFixed(2)} A
                    </p>
                  )}
                  {data?.selectedProducts?.inverter?.maxOutputPower && (
                    <p>
                      {t("Power")} {data?.selectedProducts?.inverter?.maxOutputPower} kWp
                    </p>
                  )}
                  <div className="mt-4">
                    {data?.cablingLoops?.map((loop: any, index: number) => (
                      <AutoStringInput
                        loop={loop}
                        onEdit={data?.onEdit}
                        key={index}
                        index={index}
                        selectedProducts={data?.selectedProducts}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Panel */}
          <div className="w-[65%] mt-5 border-l border-[#D3D3D3] pl-7">
            <TabBar tabs={tabs} clickHandler={handleTabClick} selected={selectedTab} />
            {selectedTab === TAB_KEYS.WIRINGDESIGN && (
              <div className="flex flex-col gap-8 my-8 px-4 max-w-4xl">
                {systemData.map((item, index) => (
                  <div key={index} className="flex w-full mb-8">
                    <div className="flex items-center mb-2 w-[40%]">
                      <span className="text-green-500 text-lg mr-2">
                      <div className="relative w-6 h-6">
                        <div className={`absolute left-1 top-0 w-2 h-4 border-b-2 border-r-2 ${item?.mppMinValue ? "border-[#FFD75D]" : "border-[#34A853]"} rotate-45`}></div>
                      </div>
                      </span>
                      <h2 className="text-xs font-semibold text-[#2D4764]">{item.title}</h2>
                    </div>
                    <div className="w-[60%]">
                      <div className="text-[10px]">{item.details}</div>
                      <div className="relative flex items-center mb-2">
                        {item.valueBar.map((bar, i) => (
                          <div
                            key={i}
                            className={`${bar.color} h-3`}
                            style={{ width: `${bar.value}%` }}
                          ></div>
                        ))}
                        {item?.mppMaxValue &&
                          <div
                            className="absolute w-5 top-[-2px] h-4 border-l-2 border-yellow-500"
                            style={{ left: `${item?.mppMaxValue}px` }}
                          ></div>
                        }
                        {item?.mppMinValue &&
                          <div
                            className="absolute w-5 top-[-2px] h-4 border-l-2 border-yellow-500"
                            style={{ left: `${item?.mppMinValue}px` }}
                          ></div>
                        }
                      </div>
                      <div className="text-[9px] text-[#2D4764] font-semibold">
                        {item.description?.map((desc, i) => (
                          <p key={i}>{desc}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="flex justify-end gap-2 mt-6">
                  <h3 className="text-xs text-[#2D4764] font-semibold">{t("Legend")}:</h3>
                  <div className="flex flex-col text-xs text-[#2D4764]">
                    <div className="flex items-center gap-1">
                      <div className="bg-green-500 w-4 h-4"></div>
                      <span>{t("Design Range")}</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="bg-yellow-500 w-4 h-4"></div>
                      <span>{t("Tolerance Range")}</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="bg-orange-500 w-4 h-4"></div>
                      <span>{t("Curtailment Range")}</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <div className="bg-red-500 w-4 h-4"></div>
                      <span>{t("Forbidden Range")}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === TAB_KEYS.INTERCONNECTIONQUALITY && (
              <div className="max-w-4xl mx-auto my-8 px-4">
                <h1 className="text-lg font-semibold mb-8">
                  {t("The following criteria determine the quality of interconnection")}*:
                </h1>
                <ul className="space-y-4">
                  {criteria.map((item, index) => (
                    <ol key={index} className="flex w-full">
                      <p className="text-xs font-medium w-[60%]">
                        {index + 1}. {item.label}
                      </p>
                      <div className="flex items-center w-[40%]">
                        <div className="w-full bg-gray-200 h-4 border border-[#2D4764] overflow-hidden">
                          <div
                            className="bg-[#247D38] h-4"
                            style={{ width: `${item.value}%` }}
                          ></div>
                        </div>
                        <span className="ml-3 text-sm font-medium">{item.value}%</span>
                      </div>
                    </ol>
                  ))}
                </ul>
                <p className="mt-6 text-xs italic text-[#2D4764] font-bold float-end">
                  *{t("The criteria are weighted into the quality")}
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Footer Section */}
        <div className="w-full p-5">
          <div className="flex justify-between gap-6 w-full">
            <p className="text-xs flex items-center">
              <TriangleAlert className="h-3 w-3 mr-1 text-yellow-400" />
              {t(
                "Inverter 1: Maximum inverter power of 5.5 kW exceeded (currently 5.8 kW). Please change the inverter or the interconnection.",
              )}
            </p>
            <div className="text-xs underline">{t("Change Now")}</div>
          </div>
          <div className="flex justify-between gap-6 w-full">
            <p className="text-xs flex items-center">
              <TriangleAlert className="h-3 w-3 mr-1 text-yellow-400" />
              {t(
                "For dimensioning factors of more than 115% (DC/AC), a simulation with minute values is recommended. You can enable this function on the 'System Type, Climate, and Grid' page.",
              )}
            </p>
            <div className="text-xs underline">{t("Change Now")}</div>
          </div>
        </div>
      </div>
    </PopupSkeleton>
  );
};
