import { cloneDeep } from "lodash";

import { ProductFilters } from "src/hooks";

import {
  CarRequestDB,
  CarResponseDB,
  ComponentRequestDB,
  ComponentResponseDB,
  ElectricMeterRequestDB,
  FinancialDetailsRequestDB,
  FinancialDetailsResponseDB,
  HeatpumpRequestDB,
  HeatpumpResponseDB,
  HouseBottomRequestDB,
  HouseTopRequestDB,
  HouseholdDetailsRequestDB,
  HouseholdDetailsResponseDB,
  InverterRequestDB,
  InverterResponseDB,
  ProjectImagesResponseDB,
  ProjectResponseDB,
  ProjectSaveRequestDB,
  ThreeDModelsRequestDB,
} from "src/db-types/project";

import { convertQuoteFromDB } from "./quote";

import { defaultFilters } from "src/redux/application/reducer/application.reducer";
import {
  BaseHouseImages,
  CarComponent,
  ElectricMeterImages,
  HeatpumpComponent,
  HouseTopImages,
  HouseholdEnergyParams,
  ProjectImages,
  ProjectState,
  QuoteProfileName,
} from "src/redux/project";
import { defaultComponent } from "src/redux/project/default-values";
import { defaultProjectState, initialState } from "src/redux/project/initialState";
import { BaseComponent, FilterableBaseComponent } from "src/redux/project/types/components";
import { FeedInReason, ProjectFinancialDetails } from "src/redux/project/types/financial-details";
import { ThreeDModels } from "src/redux/project/types/house-images";
import { PaymentStatus } from "src/redux/project/types/payment";

export const convertFinancialDetailsToDB = (
  details: ProjectFinancialDetails,
): FinancialDetailsRequestDB => {
  const {
    amortizationTime,
    estimatedYearlyPriceIncrease,
    expectedReturnTime,
    feedInSavings,
    feedInTariff,
    increaseRateByBank,
    isFinancing,
    loan,
    pricePerKwh,
    selectedFeedInReason,
    buy,
  } = details;
  return {
    amortizationTime,
    buy,
    electricityCostBefore: 0, // TODO why???
    estimatedYearlyPriceIncrease,
    expectedReturnTime,
    feedInSavings,
    feedInTariff,
    increaseRateByBank,
    isFinancing,
    loan,
    pricePerKwh,
    selectedFeedInReason,
  };
};

export const convertSolarPanelToDB = (solarPanel: FilterableBaseComponent): ComponentRequestDB => {
  const { extendedWarranty, item, quantity, isExcludeCost, preferences } = solarPanel;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    preferences,
  };
};

export const convertInverterToDB = (inverter: BaseComponent): InverterRequestDB => {
  const { extendedWarranty, item, quantity, isExcludeCost } = inverter;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
  };
};

export const convertEMSToDB = (EMS: FilterableBaseComponent): ComponentRequestDB => {
  const { extendedWarranty, item, quantity, isExcludeCost, preferences } = EMS;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    preferences,
  };
};

export const convertBatteryToDB = (battery: FilterableBaseComponent): ComponentRequestDB => {
  const { extendedWarranty, item, quantity, isExcludeCost, preferences } = battery;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    preferences,
  };
};

export const convertWallboxToDB = (wallbox: FilterableBaseComponent): ComponentRequestDB => {
  const { extendedWarranty, item, quantity, isExcludeCost, preferences } = wallbox;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    preferences,
  };
};

export const convertHeatpumpToDB = (heatpump: HeatpumpComponent): HeatpumpRequestDB => {
  const { extendedWarranty, item, quantity, configuration, isExcludeCost, preferences } = heatpump;
  const {
    blockingTimes,
    buildingStandard,
    consumptionHeatpump,
    electricityCostHeatpump,
    energyDemand,
    heatDistribution,
    heatLoadBuilding,
    heatingCapacity,
    heatpumpDimension,
    oilGasCost,
    solarPowerForHeatpump,
    totalAreaToBeHeated,
    futureOilGasCostIncrease,
  } = configuration;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    configuration: {
      blockingTimes,
      buildingStandard,
      consumptionHeatpump,
      electricityCostHeatpump,
      energyDemand,
      heatDistribution,
      heatingCapacity,
      heatLoadBuilding,
      oilGasCost,
      solarPowerForHeatpump,
      totalAreaToBeHeated,
      futureOilGasCostIncrease,
      heatpump_dimension: heatpumpDimension,
    },
    preferences,
  };
};

export const convertCarToDB = (car: CarComponent): CarRequestDB => {
  const { configuration, extendedWarranty, id, isLeasing, item, name, quantity } = car;

  const {
    buildingStandard,
    consumptionPer100Km,
    electricityCostPer100Km,
    evKmsTravelled,
    evYearlyConsumption,
    gasPricePer100Km,
    solarPowerForVehicle,
  } = configuration;

  return {
    buildingStandard,
    consumptionPer100Km,
    electricityCostPer100Km,
    evKmsTravelled,
    evYearlyConsumption,
    gasPricePer100Km,
    id,
    isLeasing,
    quantity,
    solarPowerForVehicle,
    item: item?._id ?? null,
  };
};

export const convertHousholdToDB = (
  household: HouseholdEnergyParams,
): HouseholdDetailsRequestDB => {
  const {
    buildingType,
    calculatedConsumption,
    consumption,
    consumptionProfile,
    kwaPerEmployee,
    people,
    selfSupply,
    systemConsept,
    households,
    expectedTenants,
    electricityPrice,
    expectedSolar,
    expectedHardware,
    expectedSoftware,
    measuringPoint,
    multiTenantLicenceFees,
    generalElectricity,
    operationConcept,
    operationModal,
  } = household;

  return {
    buildingType,
    consumptionProfile,
    enteredConsumption: consumption || calculatedConsumption,
    kwaPerEmployee,
    people,
    selfSupply,
    systemConsept,
    households,
    expectedTenants,
    electricityPrice,
    expectedSolar,
    multiTenantLicenceFees,
    expectedHardware,
    expectedSoftware,
    measuringPoint,
    generalElectricity,
    operationConcept,
    operationModal,
  };
};

export const convertHouseTopImageToDB = (houseTopImage: HouseTopImages): HouseTopRequestDB => {
  const { buildingYearId, description, imageURLs } = houseTopImage;
  return {
    buildingYearId,
    description,
    imageURLs,
  };
};

export const convertHouseBottomToDB = (houseBottom: BaseHouseImages): HouseBottomRequestDB => {
  const { description, imageURLs } = houseBottom;
  return {
    description,
    imageURLs,
  };
};

export const convertDroneDataToDB = (ThreeDModels: ThreeDModels): ThreeDModelsRequestDB => {
  const {
    description,
    imageURLs,
    planYourSelf,
    pvSolModifyModel,
    threeDpvModelSunnyDesign,
    threeDpvModelSolarEdgeDesign,
  } = ThreeDModels;
  return {
    description,
    imageURLs,
    planYourSelf,
    pvSolModifyModel,
    threeDpvModelSunnyDesign,
    threeDpvModelSolarEdgeDesign,
  };
};

export const convertElectricMeterToDB = (
  electricMeter: ElectricMeterImages,
): ElectricMeterRequestDB => {
  const { description, imageURLs, meterTypeId } = electricMeter;
  return {
    meterTypeId,
    description,
    imageURLs,
  };
};

const defaultBakeModeldata = {
  id: "",
  artifacts: null,
};

const defaultBakeModelProgressdata = {
  percentage: "",
  time: null,
  elapsed: 0,
};

export const convertProjectToSaveRequest = (project: ProjectState): ProjectSaveRequestDB => {
  return {
    quoteID: project.quoteId,
    profile: project.profile,
    vendor: project.vendor, // todo pure function
    payment: project.payment, // todo pure function
    bakeModel: project?.bakeModel || defaultBakeModeldata, // todo pure funciton
    trackProgress: project?.trackProgress || defaultBakeModelProgressdata,
    projectPdf: project.projectPdf || "",
    projectName: project.projectName || "",
    financialDetails: convertFinancialDetailsToDB(project.financialDetails),
    accessCount: project?.accessCount,
    energyProductionParams: project.energyProductionParams,
    household: convertHousholdToDB(project.household),
    images: {
      electricMeter: convertElectricMeterToDB(project.images.electricMeter),
      houseBottom: convertHouseBottomToDB(project.images.houseBottom),
      houseTop: convertHouseTopImageToDB(project.images.houseTop),
      ThreeDModels: convertDroneDataToDB(project.images.ThreeDModels),
    },
    components: {
      battery: convertBatteryToDB(project.components.battery),
      inverter: {
        items: project.components.inverter.items.map((item) => convertInverterToDB(item)),
        preferences: convertDBPreferences(project.components.inverter.preferences),
      },
      solar_panel: convertSolarPanelToDB(project.components.solarPanel),
      heatpump: convertHeatpumpToDB(project.components.heatpump),
      waterHeating: project.components.waterHeating,
      wallBox: convertWallboxToDB(project.components.wallbox),
      cars: project.components.cars.map((c) => convertCarToDB(c)),
      EMS: convertEMSToDB(project.components.EMS),
    },
    threeDObjDetails: {
      createdLines: project?.threeDObjDetails?.createdLines,
      createdPoints: project?.threeDObjDetails?.createdPoints,
      areas: project?.threeDObjDetails?.areas,
      perimeters: project?.threeDObjDetails?.perimeters,
      createdLineLoops: project?.threeDObjDetails?.createdLineLoops,
      panelsQuantity: project?.threeDObjDetails?.panelsQuantity,
      panelPlacement: project?.threeDObjDetails?.panelPlacement,
      panelName: project?.threeDObjDetails?.panelName,
      scale: project?.threeDObjDetails?.scale,
    },
    googleModel: project.googleModel,
    polygonViseDetails: project.polygonViseDetails,
    maxWidth3DViewer: project?.maxWidth3DViewer,
    maxWidth3DViewerWithImages: project?.maxWidth3DViewerWithImages,
    installer: project?.installer,
    iid: project?.iid,
    userInfo: project?.userInfo,
    subsidy: project?.subsidy,
    manualSubsidyDetails: project?.manualSubsidyDetails,
  };
};

export const convertDBPreferences = (pref: Record<string, any>): ProductFilters => {
  pref = (pref as ProductFilters) ?? {};
  return {
    ...defaultFilters,
    ...pref,
  };
};

export const convertDBBattery = (data: ComponentResponseDB): FilterableBaseComponent => {
  const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBEMS = (data: ComponentResponseDB): FilterableBaseComponent => {
  const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBSolarPanel = (data: ComponentResponseDB): FilterableBaseComponent => {
  const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBAdditionalSoftware = (data: ComponentResponseDB): FilterableBaseComponent => {
  if (data) {
    const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
    return {
      extendedWarranty,
      item: item ?? null,
      quantity,
      isExcludeCost,
      preferences: convertDBPreferences(preferences),
    };
  } else {
    return defaultComponent;
  }
};

export const convertDBAdditionalHardware = (data: ComponentResponseDB): FilterableBaseComponent => {
  if (data) {
    const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
    return {
      extendedWarranty,
      item: item ?? null,
      quantity,
      isExcludeCost,
      preferences: convertDBPreferences(preferences),
    };
  } else {
    return defaultComponent;
  }
};

export const convertDBEMSToState = (data: ComponentResponseDB): FilterableBaseComponent => {
  if (data) {
    const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
    return {
      extendedWarranty,
      item: item ?? null,
      quantity,
      isExcludeCost,
      preferences: convertDBPreferences(preferences),
    };
  } else {
    return defaultComponent;
  }
};

export const convertDBInverter = (data: InverterResponseDB): BaseComponent => {
  const { extendedWarranty, isExcludeCost, quantity, item } = data;
  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    isExcludeCost,
  };
};

export const convertDBWallbox = (data: ComponentResponseDB): FilterableBaseComponent => {
  const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBCar = (data: CarResponseDB): CarComponent => {
  const {
    buildingStandard,
    consumptionPer100Km,
    electricityCostPer100Km,
    evKmsTravelled,
    evYearlyConsumption,
    gasPricePer100Km,
    id,
    isLeasing,
    quantity,
    solarPowerForVehicle,
    item,
  } = data;

  return {
    extendedWarranty: false, // todo
    item: item ?? null,
    quantity,
    id,
    isLeasing,
    name: id,
    configuration: {
      buildingStandard,
      consumptionPer100Km,
      electricityCostPer100Km,
      evKmsTravelled,
      evYearlyConsumption,
      gasPricePer100Km,
      solarPowerForVehicle,
    },
    isExcludeCost: false,
  };
};

export const convertDBHeatpump = (data: HeatpumpResponseDB): HeatpumpComponent => {
  const { configuration, extendedWarranty, isExcludeCost, quantity, item, preferences } = data;

  const {
    blockingTimes,
    buildingStandard,
    consumptionHeatpump,
    electricityCostHeatpump,
    energyDemand,
    heatDistribution,
    heatLoadBuilding,
    heatingCapacity,
    heatpump_dimension: heatpumpDimension,
    oilGasCost,
    solarPowerForHeatpump,
    totalAreaToBeHeated,
    futureOilGasCostIncrease,
    jaz,
  } = configuration;

  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    configuration: {
      blockingTimes,
      buildingStandard,
      consumptionHeatpump,
      electricityCostHeatpump,
      energyDemand,
      heatDistribution,
      heatingCapacity,
      heatLoadBuilding,
      heatpumpDimension,
      oilGasCost,
      solarPowerForHeatpump,
      totalAreaToBeHeated,
      futureOilGasCostIncrease,
      jaz: jaz ?? 3.5,
    },
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBFinancialDetails = (
  data: FinancialDetailsResponseDB,
): ProjectFinancialDetails => {
  const {
    _id,
    amortizationTime,
    buy,
    electricityCostBefore,
    estimatedYearlyPriceIncrease,
    expectedReturnTime,
    feedInSavings,
    feedInTariff,
    increaseRateByBank,
    isFinancing,
    loan,
    pricePerKwh,
    selectedFeedInReason,
  } = data;

  return {
    ...cloneDeep(defaultProjectState.financialDetails),
    pricePerKwh,
    estimatedYearlyPriceIncrease,
    increaseRateByBank,
    feedInTariff,
    selectedFeedInReason: selectedFeedInReason as FeedInReason,
    isFinancing,
    expectedReturnTime,
    amortizationTime,
    feedInSavings,
  };
};

export const convertDBHousehold = (data: HouseholdDetailsResponseDB): HouseholdEnergyParams => {
  const {
    _id,
    buildingType = initialState.household.buildingType,
    consumptionProfile = initialState.household.consumptionProfile,
    enteredConsumption = initialState.household.consumption,
    kwaPerEmployee = initialState.household.kwaPerEmployee,
    people = initialState.household.people,
    systemConsept = initialState.household.systemConsept,
    households = initialState.household.households,
    expectedTenants = initialState.household.expectedTenants,
    electricityPrice = initialState.household.electricityPrice,
    expectedSolar = initialState.household.expectedSolar,
    expectedHardware = initialState.household.expectedHardware,
    expectedSoftware = initialState.household.expectedSoftware,
    measuringPoint = initialState.household.measuringPoint,
    generalElectricity = initialState.household.generalElectricity,
    multiTenantLicenceFees = initialState.household.multiTenantLicenceFees,
    operationConcept = initialState.household.operationConcept,
    operationModal = initialState.household.operationModal,
    selfSupply,
  } = data;

  return {
    buildingType,
    calculatedConsumption: 0,
    consumption: enteredConsumption,
    consumptionProfile,
    kwaPerEmployee,
    people,
    systemConsept,
    households,
    expectedTenants,
    multiTenantLicenceFees,
    electricityPrice,
    expectedSolar,
    expectedHardware,
    expectedSoftware,
    measuringPoint,
    generalElectricity,
    selfSupply,
    operationConcept,
    operationModal,
  };
};

export const converProjectImagesDBToState = (
  dbProjectImages: ProjectImagesResponseDB,
): ProjectImages => {
  return {
    electricMeter: {
      imageURLs: dbProjectImages.electricMeter.imageURLs ?? [],
      description:
        dbProjectImages.electricMeter.description ?? initialState.images.electricMeter.description,
      meterTypeId:
        dbProjectImages.electricMeter.meterTypeId ?? initialState.images.electricMeter.meterTypeId,
    },
    houseBottom: {
      imageURLs: dbProjectImages.houseBottom.imageURLs ?? [],
      description:
        dbProjectImages.houseBottom.description ?? initialState.images.houseBottom.description,
    },
    ThreeDModels: {
      imageURLs: dbProjectImages?.ThreeDModels?.imageURLs ?? [],
      description:
        dbProjectImages?.ThreeDModels?.description ?? initialState.images.ThreeDModels.description,
      planYourSelf:
        dbProjectImages?.ThreeDModels?.planYourSelf ??
        initialState.images.ThreeDModels.planYourSelf,
      pvSolModifyModel:
        dbProjectImages?.ThreeDModels?.pvSolModifyModel ??
        initialState?.images?.ThreeDModels?.pvSolModifyModel,
      threeDpvModelSunnyDesign:
        dbProjectImages?.ThreeDModels?.threeDpvModelSunnyDesign ??
        initialState.images.ThreeDModels.threeDpvModelSunnyDesign,
      threeDpvModelSolarEdgeDesign:
        dbProjectImages?.ThreeDModels?.threeDpvModelSolarEdgeDesign ??
        initialState.images.ThreeDModels.threeDpvModelSolarEdgeDesign,
      selectedFiles: [],
    },
    houseTop: {
      imageURLs: dbProjectImages.houseTop.imageURLs ?? [],
      buildingYearId:
        dbProjectImages.houseTop.buildingYearId ?? initialState.images.houseTop.buildingYearId,
      description: dbProjectImages.houseTop.description ?? initialState.images.houseTop.description,
    },
  };
};

const products: any = [
  {
    extendedWarranty: false,
    quantity: 1,
    isExcludeCost: true,
    item: {
      _id: "65211b4efa816b7ca8edaa31",
      userHistory: "645f1c2f693025f963624a8b",
      subtype: "hybrid",
      extendedWarrantyCost: 0,
      objectId: "I57",
      priceB2b: 3300,
      priceB2c: 3300,
      imgUrls: [
        "https://cdn.solarhub24.de/products/firebase/66d2ed7f-7b30-4759-af6b-69cbc71227bd.png",
      ],
      properties: {
        hybrid: true,
        "Data visualization": true,
        "Emergency Power": true,
      },
      type: "inverter",
      longDescription:
        "Der Growatt SPH8000TL3-BH-UP ist ein Wechselrichter mit hoher Leistung für kleinere bis mittlere Solaranlagen. Dieser Wechselrichter ist einfach zu montieren und macht die Solarenergie für jeden zugänglich. Die einfache Montage und die intuitive Bedienung über das Touch-Display garantieren eine einfache und schnelle Inbetriebnahme. Der Wechselrichter verfügt über 2 integrierte MPPT-Eingänge und einer maximalen Ausgangsleistung von 8.000W. Die maximale Eingangsleistung (PV) beträgt 12.000W.\nDas ansprechende Design und die die geringe Betriebslautstärke sind ideal für den Einsatz im Innenbereich. ",
      buyingCarPrice: "0",
      TechnischeProduktdaten: [
        "Nennleistung in W: 8000",
        "Wirkungsgrad in %: 97.4",
        "Anschlussphasen: 3",
        "Anzahl MPP-Tracker: 2",
        "Anzahl Stränge pro MPPT: 1",
        "Montage im Außenbereich: Ja",
        "Maße (BxHxT) in mm: 505 x 453 x 198",
        "Gewicht in kg: 30",
        "Betriebstemperatur in °C: -25 bis 60",
      ],
      recommand: false,
      warrantyLink: "",
      capacity: null,
      pdfUrl:
        "https://solar24.sharepoint.com/:b:/s/Solarhub/EXYbHmnRL8RPlOBBPwegWl0B-z9gVBOiKA-59P02e6u2lQ?e=PDR0do",
      instock: true,
      applicableMaxCapacity: 12000,
      electricCarConsumption: 0,
      range: "0",
      Highlights:
        "\n    100 % dreiphasiger Unsymmetrieausgang\n    Intelligente Begrenzung des Leistungsexports auf Phasenebene\n    Breite Batteriespannung 100-550 V\n    USV-Funktion, 10 ms Übergang\n    Skalierbare Systemkonfiguration\n    VPP-Schnittstelle bereit\n    DC/AC Typ II SPD\n    1,5 DC/AC-Verhältnis",
      brand: "GROWATT",
      videoLink: "",
      name: "SPH 8000TL3 BH-UP ",
      Herstellerinformation:
        "Growatt ist einer der größten Hersteller von PV-Wechselrichtern und hat weltweit eine installierte Leistung von über 5 GW erreicht. Growatt ist für kostengünstige Solarwechselrichter mit hohem Wirkungsgrad bekannt. Die Wechselrichter von Growatt sind mit der neuesten Technologie ausgestattet.",
      allowed: null,
      allowedInverter: null,
      colors: ["white"],
      Garantie: "10 Jahre",
      compatible_products: [],
      bullet_point_list: [
        "Ersatzstrom (optional)",
        " kompatibel mit Batteriespeicher ",
        "10 Jahre Produktgarantie",
      ],
      createdBy: "63e75283a3643aaf02a223bb",
      createdAt: "2023-10-07T08:48:14.046Z",
      updatedAt: {
        $date: "2025-01-17T14:55:35.334Z",
      },
      __v: 0,
      installerAccess: {
        "64883f2bb04be7a2eb96cd0c": {
          isStandard: false,
        },
        "65e87e8e8d3acd56c3afd0a7": {
          isStandard: false,
        },
        "65c52c4f2a669a24850f88d7": {
          isStandard: false,
        },
      },
      tax: 0,
      imageMigrationDone: true,
      internalCost: 2640,
      numberOfMPPTrackers: 3,
    },
  },
  {
    extendedWarranty: false,
    quantity: 2,
    isExcludeCost: true,
    item: {
      _id: "65211b4efa816b7ca8eda9ff",
      userHistory: "645f1c2f693025f963624a8b",
      subtype: "premium",
      extendedWarrantyCost: 0,
      objectId: "I36",
      priceB2b: 7280,
      priceB2c: 7280,
      imgUrls: [
        "https://cdn.solarhub24.de/products/firebase/6fcd1c6d-cb91-4a41-a522-90d46ffaabc7.png",
      ],
      properties: {
        "Made in Germany": true,
      },
      type: "inverter",
      longDescription:
        "Der SMA Sunny Tripower CORE 2 ist mit seinen 12 MPP Tracker hervorragend für dezentrale Dach- und Freiflächen sowie überdachte Parkplätze bestens geeignet. Gewerbliche PV-Installationen sind damit schnell und einfach zu realisieren. Die Überspannungsableiter vom Typ II bringt der Core 2 standardmäßig mit.",
      buyingCarPrice: "0",
      TechnischeProduktdaten: [
        "Nennleistung in W: 110000",
        "Wirkungsgrad in %: 98.4",
        "Anschlussphasen: 3",
        "Anzahl MPP-Tracker: 12",
        "Anzahl Stränge pro MPPT: 2",
        "Montage im Außenbereich: Ja",
        "Maße (BxHxT) in mm: 1117 x 682 x 363",
        "Gewicht in kg: 93.5",
        "Betriebstemperatur in °C: -30 bis 60",
        "optimale Betriebstemperatur in °C: -30 bis 60",
      ],
      recommand: false,
      warrantyLink: "",
      capacity: null,
      pdfUrl:
        "https://solar24.sharepoint.com/:b:/s/Solarhub/EX3o75zD9wJEgoraqXuRer4Bb0_m6fcwDxI4BSKY622KOA?e=LcjapI",
      instock: true,
      applicableMaxCapacity: 165000,
      electricCarConsumption: 0,
      range: "0",
      Highlights: "\n\n\n\n",
      brand: "SMA",
      videoLink: "",
      name: "SUNNY TRIPOWER CORE2 110",
      Herstellerinformation:
        "Als global führender Spezialist für Photovoltaik-Systemtechnik schafft SMA heute die Voraussetzungen für die dezentrale, digitale und erneuerbare Energieversorgung von morgen. Dieser Aufgabe widmen sich über 3.000 SMA Mitarbeiter in 18 Ländern mit großer Leidenschaft. Mit unseren innovativen Lösungen für alle Photovoltaikanwendungen ermöglichen wir Menschen und Unternehmen weltweit mehr Unabhängigkeit in der Anwendung von Energie. Gemeinsam mit unseren Kooperationspartnern und Kunden machen wir den Umbau zu einer unabhängigen, dezentralen und erneuerbaren Energieversorgung möglich.",
      allowed: null,
      allowedInverter: null,
      colors: ["white"],
      Garantie:
        "Die Werksgarantie für SMA Sunny Tripower Wechselrichter beträgt 5 Jahre. Garantieleistungen:SMA trägt Kosten für neues Gerät oder Ersatzteilenach Defekt findet entweder ein Austausch oder eine Instandsetzung vor Ort  statt.Der Austausch oder die Instandsetzung ist kostenfrei, wenn der Defekt nicht auf unzulässige Nutzung, Unfälle oder höhere Gewalt zurückzuführen ist.",
      compatible_products: [],
      bullet_point_list: [
        "Flexibler Wechselrichter für große Projekte",
        "Deutschlands bekanntester Wechselrichterhersteller",
        "5 Jahre Produktgarantie",
      ],
      createdBy: "63e75283a3643aaf02a223bb",
      createdAt: "2023-10-07T08:48:14.042Z",
      updatedAt: {
        $date: "2025-01-17T14:57:06.858Z",
      },
      __v: 0,
      installerAccess: {
        "64883f2bb04be7a2eb96cd0c": {
          isStandard: false,
        },
        "65e83b976fcbad0c438c00d4": {
          isStandard: false,
        },
        "64600925dd9f2257dd8f5f7b": {
          isStandard: false,
        },
        "65c52c4f2a669a24850f88d7": {
          isStandard: false,
        },
      },
      tax: 0,
      imageMigrationDone: true,
      minimalCapacity: 120000,
      internalCost: 5824,
      numberOfMPPTrackers: 3,
    },
  },
];

export const convertProjectDBToState = (dbProject: ProjectResponseDB): Partial<ProjectState> => {
  return {
    id: dbProject._id,
    profile: dbProject.profile as QuoteProfileName,
    uid: dbProject.uid,
    version: dbProject.version,
    projectName: dbProject.projectName,
    type: "b2c", // TODO
    bakeModel: dbProject?.bakeModel || defaultBakeModeldata, // todo pure funciton,
    trackProgress: dbProject?.trackProgress || defaultBakeModelProgressdata,
    accessCount: dbProject?.accessCount,
    components: {
      battery: convertDBBattery(dbProject.components.battery),
      cars: dbProject.components.cars.map(convertDBCar),
      heatpump: convertDBHeatpump(dbProject.components.heatpump),
      inverter: {
        // items: products.map(convertDBInverter),
        items: [],
        preferences: convertDBPreferences(dbProject.components.inverter.preferences),
        // items: dbProject.components.inverter.items.map(convertDBInverter),
        // preferences: convertDBPreferences(dbProject.components.inverter.preferences),
      },
      solarPanel: convertDBSolarPanel(dbProject.components.solar_panel),
      waterHeating: dbProject.components.waterHeating,
      wallbox: convertDBWallbox(dbProject.components.wallBox),
      additionalSoftware: convertDBAdditionalSoftware(dbProject.components.additionalSoftware),
      additionalHardware: convertDBAdditionalHardware(dbProject.components.additionalHardware),
      EMS: convertDBEMS(dbProject?.components?.EMS || defaultComponent),
    },
    deal: dbProject.deal,
    energyProductionParams: dbProject.energyProductionParams,
    financialDetails: convertDBFinancialDetails(dbProject.financialDetails),
    household: convertDBHousehold(dbProject.household),
    images: converProjectImagesDBToState(dbProject.images),
    payment: {
      ...dbProject.payment,
      status: dbProject.payment.status as PaymentStatus,
    },
    quoteId: dbProject?.quoteID?._id,

    bakeModelAdminGenerated: dbProject.quoteID.bakeModel,

    shortId: dbProject.shortId,
    quote: convertQuoteFromDB(dbProject.quoteID),
    selectedInstallers: dbProject.selectedInstallers,
    installerDetails: dbProject.installerDetails,
    threeDObjDetails: dbProject.threeDObjDetails,
    googleModel: dbProject.googleModel,
    polygonViseDetails: dbProject.polygonViseDetails,
    maxWidth3DViewer: dbProject.maxWidth3DViewer,
    maxWidth3DViewerWithImages: dbProject.maxWidth3DViewerWithImages,
    userInfo: dbProject?.userInfo,
    userDetails: dbProject?.userDetails,
    subsidy: dbProject?.subsidy,
    manualSubsidyDetails: dbProject?.manualSubsidyDetails,
  };
};
