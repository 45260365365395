import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useAddBatteryInverterChange, useAppSelector, useIsComponentAdded } from "src/hooks";

import { getAverageCapacity } from "src/helpers";
import { useNumberFormat } from "src/hooks/useNumberFormat";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { ProjectActions } from "src/redux/actionCreators";
import { AppSelectors, ProductSelectors, ProjectSelectors } from "src/redux/selectors";

const SystemCapacityLine: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filteredSolarPanels = useSelector(ProductSelectors.getFilteredSolarPanels);
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const { quantity } = useSelector(ProjectSelectors.getSolarPanel);
  const { isProductApiLoading } = useAppSelector(AppSelectors.getApiStates);
  const preferences = useAppSelector(ProjectSelectors.getInverterPreferences);
  const isInverterHybrid = preferences?.hybrid;

  const { isBatteryNotAdded } = useIsComponentAdded();
  const { formatNumber } = useNumberFormat();
  const { noHybridInverterAvailable, showContactSolarhubToast, restoreInverterPreference } =
    useAddBatteryInverterChange();

  const capacity = useMemo(() => {
    return getAverageCapacity(filteredSolarPanels);
  }, [filteredSolarPanels]);

  let systemCapacity = (capacity * quantity) / 1000;
  if (solarPanelProduct?.item) {
    systemCapacity = (Number(solarPanelProduct?.item.capacity) * quantity) / 1000;
  }
  useEffect(() => {
    if (isProductApiLoading) return;
    if (isBatteryNotAdded) return;
    if (!isInverterHybrid) return;

    if (noHybridInverterAvailable(systemCapacity * 1000)) {
      dispatch(ProjectActions.deleteBattery());
      showContactSolarhubToast();
      restoreInverterPreference();
    }
  }, [
    systemCapacity,
    dispatch,
    isBatteryNotAdded,
    isProductApiLoading,
    noHybridInverterAvailable,
    showContactSolarhubToast,
    restoreInverterPreference,
    isInverterHybrid,
  ]);

  return (
    <ParameterStyles.Line isPadding>
      <ParameterStyles.Label>{t("SYSTEM CAPACITY")}</ParameterStyles.Label>
      <ParameterStyles.Value>
        {formatNumber(systemCapacity < 1 ? systemCapacity : Math.round(systemCapacity))} kWp
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default SystemCapacityLine;
