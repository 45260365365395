import { cloneDeep } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { filterProducts } from "src/helpers";
import { getNumber } from "src/utils";

import { ProductFilters } from "./useProductFilter";

import { AppActions, ProjectActions } from "src/redux/actionCreators";
import { getApplicaitonFilters } from "src/redux/application/selector";
import { AppSelectors, ProductSelectors, ProjectSelectors } from "src/redux/selectors";

const HYBRID_INVERTER_CHANGE_TOAST_ID = "hybrid-inverter-change-toast";
const INVERTER_PREFERENCE_RESTORE_TOAST_ID = "inverter-preference-restore-toast";

const hybridChangeText =
  "We changed your inverter to hybrid due to the addition of battery in the system";

interface IReturn {
  convertInverterToHybrid: () => boolean;
  restoreInverterPreference: () => void;
  showContactSolarhubToast: () => void;
  noHybridInverterAvailable: (capacity: number) => boolean;
  showHybridChangeToast: () => void;
}

export const useAddBatteryInverterChange = (): IReturn => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const inverterPref = useSelector(ProjectSelectors.getInverterPreferences);
  const inverterPreviousPref = useSelector(AppSelectors.getPreviousInverterPref);
  const allInverterProducts = useSelector(ProductSelectors.getInverters);
  const defaultFilters = useSelector(getApplicaitonFilters);

  const dismissAllToasts = useCallback(() => {
    toast.dismiss(HYBRID_INVERTER_CHANGE_TOAST_ID);
    toast.dismiss(INVERTER_PREFERENCE_RESTORE_TOAST_ID);
  }, []);

  const noHybridInverterAvailable = useCallback(
    (capacity: number) => {
      if (allInverterProducts.length === 0) return false;

      return (
        filterProducts({
          filters: {
            ...cloneDeep(defaultFilters),
            applicableMaxCapacity: capacity,
            hybrid: true,
          },
          products: allInverterProducts,
        }).length === 0
      );
    },
    [allInverterProducts, defaultFilters],
  );

  const showContactSolarhubToast = useCallback(() => {
    dismissAllToasts();
    toast.warn(
      t(
        "Hybrid inverter for current system capacity is not available. Please contact experte@solarhub24.de",
      ),
    );
  }, [dismissAllToasts, t]);

  const showHybridChangeToast = useCallback(() => {
    dismissAllToasts();
    toast.info(t(hybridChangeText), {
      toastId: HYBRID_INVERTER_CHANGE_TOAST_ID,
    });
  }, [dismissAllToasts, t]);

  const convertInverterToHybrid = useCallback(() => {
    const isInverterHybrid = inverterPref.hybrid;
    if (isInverterHybrid) {
      return true;
    }

    const requiredCapacity = getNumber(inverterPref.applicableMaxCapacity);
    if (noHybridInverterAvailable(requiredCapacity)) {
      showContactSolarhubToast();
      return false;
    }

    const defaultFiltersInverter: Partial<ProductFilters> = cloneDeep(defaultFilters);
    delete defaultFiltersInverter.applicableMaxCapacity;

    dispatch(AppActions.updateInverterPreviousPreference(inverterPref));
    dispatch(
      ProjectActions.updateInverter({
        preferences: {
          ...cloneDeep(defaultFiltersInverter),
          hybrid: true,
        },
      }),
    );

    showHybridChangeToast();

    return true;
  }, [
    defaultFilters,
    dispatch,
    inverterPref,
    noHybridInverterAvailable,
    showContactSolarhubToast,
    showHybridChangeToast,
  ]);

  const restoreInverterPreference = useCallback(() => {
    const restorable: Partial<ProductFilters> = cloneDeep(inverterPreviousPref ?? defaultFilters);
    delete restorable.applicableMaxCapacity;

    dispatch(
      ProjectActions.updateInverter({
        preferences: {
          ...restorable,
        },
      }),
    );

    dispatch(AppActions.updateInverterPreviousPreference(null));
    dismissAllToasts();

    toast.info(t("restoreText"), {
      toastId: INVERTER_PREFERENCE_RESTORE_TOAST_ID,
    });
  }, [defaultFilters, dismissAllToasts, dispatch, inverterPreviousPref, t]);

  return {
    convertInverterToHybrid,
    restoreInverterPreference,
    showContactSolarhubToast,
    noHybridInverterAvailable,
    showHybridChangeToast,
  };
};
