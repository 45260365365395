import _ from "lodash";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { BackendApis, RentingData } from "src/api";

import { useGetRentingDataFromGolfStorm } from "./apis";

import { UserActions } from "src/redux/actionCreators";
import { getAddress, getProject, getProjectType, getQuote } from "src/redux/project/selectors";
import { ProductSelectors } from "src/redux/selectors";

import { useAppDispatch, useIsComponentAdded } from ".";

export const useGetRentingOption = (): void => {
  const dispatch = useAppDispatch();
  const project = useSelector(getProject);
  const mapboxAddress = useSelector(getAddress);
  const projectType = useSelector(getProjectType);
  const solarPanels = useSelector(ProductSelectors?.getSolarPanels);
  const inverters = useSelector(ProductSelectors?.getInverters);
  const batteries = useSelector(ProductSelectors?.getBatteries);
  const wallboxes = useSelector(ProductSelectors?.getWallboxes);

  const isB2b = projectType === "b2b";

  const { fetchData, error, response } = useGetRentingDataFromGolfStorm();

  useEffect(() => {
    if (!error) return;
    console.error(error);
  }, [error]);

  useEffect(() => {
    if (response) {
      dispatch(
        UserActions.setRantingData({
          rate_without_vat: response?.data?.rate_without_vat,
          rate_with_vat: response?.data?.rate_with_vat,
        }),
      );
    }
  }, [response, dispatch]);

  const memoizedFetchData = useMemo(() => {
    return _.debounce(fetchData, 500);
  }, [fetchData]);

  useEffect(() => {
    if (mapboxAddress.address) {
      const components = [
        {
          name: project.components.solarPanel.item?.name || solarPanels?.[0]?.name,
          quantity: project.components.solarPanel.quantity,
          type: "solarmodul",
          price: isB2b
            ? project.components.solarPanel.item?.priceB2b || solarPanels?.[0]?.priceB2b
            : project.components.solarPanel.item?.priceB2c || solarPanels?.[0]?.priceB2c,
          output:
            project.components.solarPanel.item?.applicableMaxCapacity ||
            solarPanels?.[0]?.applicableMaxCapacity,
        },
        {
          name: project.components.inverter.items[0]?.item?.name || inverters?.[0]?.name,
          quantity: project.components.inverter.items[0]?.quantity || 1,
          type: "inverter",
          price: isB2b
            ? project.components.inverter.items[0]?.item?.priceB2b || inverters?.[0]?.priceB2b
            : project.components.inverter.items[0]?.item?.priceB2c || inverters?.[0]?.priceB2c,
          output:
            project.components.inverter.items[0]?.item?.applicableMaxCapacity ||
            inverters?.[0]?.applicableMaxCapacity,
        },
      ];

      if (project.components.battery.quantity > 0) {
        components.push({
          name: project.components.battery.item?.name || "",
          quantity: project.components.battery.quantity,
          type: "battery",
          price: isB2b
            ? project.components.battery.item?.priceB2b || batteries?.[0]?.priceB2b
            : project.components.battery.item?.priceB2c || batteries?.[0]?.priceB2c,
          output: project.components.battery.item?.applicableMaxCapacity,
        });
      }

      if (project.components.wallbox.quantity > 0) {
        components.push({
          name: project.components.wallbox.item?.name || "",
          quantity: project.components.wallbox.quantity,
          type: "wallbox",
          price: isB2b
            ? project.components.wallbox.item?.priceB2b || wallboxes?.[0]?.priceB2b
            : project.components.wallbox.item?.priceB2c || wallboxes?.[0]?.priceB2c,
          output: project.components.wallbox.item?.applicableMaxCapacity,
        });
      }

      if (project.financialDetails.costs.totalProjectCost) {
        memoizedFetchData({
          ...BackendApis.getRentingData,
          data: {
            address: {
              city: mapboxAddress.city,
              postcode: mapboxAddress.postcode,
              street1: mapboxAddress.address,
            },
            partner_id: uuid(),
            planed_construction_date: new Date().toISOString().split("T")[0],
            price: project.financialDetails.costs.totalProjectCost,
            components,
          },
        });
      }
    }
  }, [
    project.financialDetails.costs.totalProjectCost,
    project.components,
    mapboxAddress,
    isB2b,
    solarPanels,
    inverters,
    batteries,
    wallboxes,
    memoizedFetchData,
  ]);
};
