import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MasterInput } from "src/shared/components";

import { ReactComponent as Angle } from "../../assets/angle1.svg";
import { ReactComponent as Flat } from "../../assets/flat1.svg";
import { ReactComponent as Tent } from "../../assets/tent1.svg";

interface ModuleSelectionType {
  activeGridChanged: any;
  documentRef: any;
  setSolarDataReload: any;
  setShowSelectionMenu: any;
}

const ModuleSelection: React.FC<ModuleSelectionType> = ({
  activeGridChanged,
  documentRef,
  setSolarDataReload,
  setShowSelectionMenu,
}) => {
  const { t } = useTranslation();
  const [angleValue, setAngleValue] = useState<number>(
    documentRef.current?.activeGrid?.angle ?? 0
  )
  const [xSpacingValue, setXSpacingValue] = useState<number>(
    documentRef.current?.activeGrid?.spacingX ?? 0.005
  )
  const [zSpacingValue, setZSpacingValue] = useState<number>(
    documentRef.current?.activeGrid?.spacingZ ?? 0.005
  )
  const [key, setKey] = useState<number>(0);

  const handleButtonClick = (buttonType: string):void => {
    switch(buttonType) {
      case "parallel":
        documentRef.current.activeGrid.angle = 30;
        break;
      case "tent":
        documentRef.current.activeGrid.angle = 10;
        break;
      default:
        documentRef.current.activeGrid.angle = 0;
        break;
    }
    setAngleValue(documentRef.current.activeGrid.angle);
    documentRef.current.activeGrid.setup = buttonType;
    documentRef.current.activeGrid.disposeOccupancy();
    documentRef.current.activeGrid.recomputeOccupancy({
      setup: documentRef.current.activeGrid.setup,
      spacingX: documentRef.current.activeGrid.spacingX,
      spacingZ: documentRef.current.activeGrid.spacingZ,
      angle: documentRef.current.activeGrid.angle,
      gridFaceUp: documentRef.current.activeGrid.gridFaceUp,
      targets: documentRef.current.terrainGroup.children,
    });
    documentRef.current.activeGrid.redrawOccupancy(documentRef.current.activeGrid.setup, documentRef.current.activeGrid.spacingX, documentRef.current.activeGrid.spacingZ, documentRef.current.activeGrid.angle);
    setKey(Math.random());
    setSolarDataReload(Math.random());  
  };

  const handleAngleChange = (value: number):void => {
    documentRef.current.activeGrid.angle = Number(value);
    setAngleValue(Number(value));
    documentRef.current.activeGrid.disposeOccupancy();
    documentRef.current.activeGrid.recomputeOccupancy({
      setup: documentRef.current.activeGrid.setup,
      spacingX: documentRef.current.activeGrid.spacingX,
      spacingZ: documentRef.current.activeGrid.spacingZ,
      angle: documentRef.current.activeGrid.angle,
      gridFaceUp: documentRef.current.activeGrid.gridFaceUp,
      targets: documentRef.current.terrainGroup.children,
    });
    documentRef.current.activeGrid.redrawOccupancy(documentRef.current.activeGrid.setup, documentRef.current.activeGrid.spacingX, documentRef.current.activeGrid.spacingZ, documentRef.current.activeGrid.angle);
  };

  const handleXSpacingChange = (value: number):void => {
    documentRef.current.activeGrid.spacingX = Number(value);
    setXSpacingValue(Number(value));
    documentRef.current.activeGrid.disposeOccupancy();
    documentRef.current.activeGrid.recomputeOccupancy({
      setup: documentRef.current.activeGrid.setup,
      spacingX: documentRef.current.activeGrid.spacingX,
      spacingZ: documentRef.current.activeGrid.spacingZ,
      angle: documentRef.current.activeGrid.angle,
      gridFaceUp: documentRef.current.activeGrid.gridFaceUp,
      targets: documentRef.current.terrainGroup.children,
    });
    documentRef.current.activeGrid.redrawOccupancy(documentRef.current.activeGrid.setup, documentRef.current.activeGrid.spacingX, documentRef.current.activeGrid.spacingZ, documentRef.current.activeGrid.angle);
  };

  const handleZSpacingChange = (value: number):void => {
    documentRef.current.activeGrid.spacingZ = Number(value);
    setZSpacingValue(Number(value));
    documentRef.current.activeGrid.disposeOccupancy();
    documentRef.current.activeGrid.recomputeOccupancy({
      setup: documentRef.current.activeGrid.setup,
      spacingX: documentRef.current.activeGrid.spacingX,
      spacingZ: documentRef.current.activeGrid.spacingZ,
      angle: documentRef.current.activeGrid.angle,
      gridFaceUp: documentRef.current.activeGrid.gridFaceUp,
      targets: documentRef.current.terrainGroup.children,
    });
    documentRef.current.activeGrid.redrawOccupancy(documentRef.current.activeGrid.setup, documentRef.current.activeGrid.spacingX, documentRef.current.activeGrid.spacingZ, documentRef.current.activeGrid.angle);
  };

  useEffect(() => {
    setAngleValue(documentRef.current?.activeGrid?.angle ?? 0);
    setXSpacingValue(documentRef.current?.activeGrid?.spacingX ?? 0.005);
    setZSpacingValue(documentRef.current?.activeGrid?.spacingZ ?? 0.005);
  }, [activeGridChanged]);
  
  return (
    <div className="flex-1 p-2 bg-white" key={key}>
      <Button className="w-2/3 mb-4 max-w-md bg-[#FFD66B] hover:bg-[#FFD66B]/90 text-[#2B3674] hover:text-[#2B3674] hover:border-transparent h-12 rounded-full flex justify-center items-center gap-2 m-auto" onClick={() => setShowSelectionMenu({
        open: true, from: "solarPanel"
      })}>
        <Plus className="w-5 h-5 mr-2" />
        {t("Add New Array")}
      </Button>
      {documentRef.current.activeGrid ? (
        <div>
          {/* Text */}
          <span className="text-xl font-semibold text-[#2B3674] block mb-4">
            {t("3D_VIEW_MOUNTING")}
          </span>
          
          {/* Buttons Container */}
          <div className="flex space-x-1 mb-4">
            {/* Button 1 */}
            <button className="w-24 p-2 rounded border border-blue-700 flex flex-col items-center justify-center space-y-2">
              <Flat
                className="w-14 h-14"
                onClick={() => handleButtonClick("flat")}
              />
              <span>
                {t("3D_VIEW_FLUSH")}
              </span>
            </button>
      
            {/* Button 2 */}
            <button className="w-24 p-2 rounded border border-blue-700 flex flex-col items-center justify-center space-y-2">
              <Angle
                className="w-14 h-14"
                onClick={() => handleButtonClick("parallel")}
              />
              <span>
                {t("3D_VIEW_ANGLED")}
              </span>
            </button>
      
            {/* Button 3 */}
            <button className="w-24 p-2 rounded border border-blue-700 flex flex-col items-center justify-center space-y-2">
              <Tent
                className="w-14 h-14"
                onClick={() => handleButtonClick("tent")}
              />
              <span>
                {t("3D_VIEW_TENT")}
              </span>
            </button>
          </div>
          <div key={key} className="flex flex-col space-y-2">
            <div className="flex justify-between items-center">
              <label htmlFor="xspacinginput" className="block text-sm font-medium text-[#2B3674]">
                {t("3D_VIEW_SPACING_X")} {"(cm)"}
              </label>
              <input
                id="xspacinginput"
                type="number"
                value={xSpacingValue}
                onChange={(event) => handleXSpacingChange(Number(event.target.value))}
                step={0.001}
                className="mt-1 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm text-[#2B3674] p-1"
              />
            </div>
            <div className="flex justify-between items-center">
              <label htmlFor="zspacinginput" className="block text-sm font-medium text-[#2B3674]">
                {t("3D_VIEW_SPACING_Y")} {"(cm)"}
              </label>
               <input
                id="xspacinginput"
                type="number"
                value={zSpacingValue}
                onChange={(event) => handleZSpacingChange(Number(event.target.value))}
                step={0.001}
                className="mt-1 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm text-[#2B3674] p-1"
              />
            </div>
            {(documentRef.current.activeGrid.setup === "parallel" || documentRef.current.activeGrid.setup === "tent") && (
              <div className="flex justify-between items-center">
              <label htmlFor="angleinput" className="block text-sm font-medium text-[#2B3674]">
                {t("3D_VIEW_INCLINATION")}
              </label>
               <input
                id="xspacinginput"
                type="number"
                value={angleValue}
                onChange={(event) => handleAngleChange(Number(event.target.value))}
                step={0.001}
                className="mt-1 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm text-[#2B3674] p-1"
              />
              </div>
            )}
          </div>
        </div>
      ):(
        <span>
          {t("3D_VIEW_NO_GRID_SELECTED")}
        </span>
      )}
      
    </div>
  );
};

function Plus(props: React.ComponentProps<"svg">) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 5v14" />
      <path d="M5 12h14" />
    </svg>
  );
}

export default ModuleSelection;