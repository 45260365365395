import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { TabBar } from "src/shared/components";

const SunConfiguration = ({sunSimulationActive, setSunSimulationActive, sunTime, setSunTime, setRefreshSun}: {
  sunSimulationActive: any,
  setSunSimulationActive: any,
  sunTime: any,
  setSunTime: any,
  setRefreshSun: any
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const ArrowIcon: React.FC = () => {
    return (
      <img
      src={
        open
          ? "/images/application_step_3/arrowLeftWithoutBorder.svg"
          : "/images/application_step_3/arrowDown.svg"
      }
      className="mb-1 hover:cursor-pointer"
      onClick={() => {
        setOpen((prev) => !prev);
        setSunSimulationActive(!open);
      }}
      style={{ zIndex: 99999, position: "absolute", top: "12px", right: "-13px" }}
      alt="Toggle Arrow"
    />
    );
  };

  /* const handleDateFieldChange = (date: any, dateString: any) => {
    setSunTime(sunTime.set({
      date: moment(dateString, "DD/MM/YYYY").date(),
      month: moment(dateString, "DD/MM/YYYY").month(),
      year: moment(dateString, "DD/MM/YYYY").year(),
    }));
  }; */

  const timeString = sunTime.format("HH:mm");
  const dateString = sunTime.format("DD.MM");

  const convertTimeRange = (oldValue:number):number => {
    return (oldValue / 720) * 100;
  };

  const convertDateRange = (oldValue:number):number => {
    return (oldValue / 365) * 100;
  };
  
  const AnalysisSlider: React.FC = () => {
  const [value, setValue] = useState(8);
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
      setValue(Number(event.target.value));
    };
  
    return (
      <div className="flex items-center space-x-2 w-full">
        {/* Left Label */}
        <span className="text-sm font-medium">08:00</span>
  
        {/* Slider Container */}
        <div className="relative w-full">
          {/* Gradient Background */}
          <div className="absolute top-1/2 left-0 w-full h-2 rounded-full transform -translate-y-1/2 bg-gradient-to-r from-black via-purple-500 to-green-500"></div>
  
          {/* Slider Input (Hidden Thumb) */}
          <input
            type="range"
            min="0"
            max="100"
            value={value}
            onChange={handleChange}
            className="relative w-full h-2 bg-transparent appearance-none focus:outline-none cursor-pointer"
            style={{
              WebkitAppearance: "none",
              MozAppearance: "none",
            }}
          />
  
          {/* Custom Slider Thumb */}
          <div
            className="absolute top-1/2 transform -translate-y-1/2 bg-white border-2 border-yellow-400 rounded-full w-5 h-5 pointer-events-none"
            style={{
              left: `calc(${value}% - 10px)`, // Position dynamically
            }}
          ></div>
        </div>
  
        {/* Right Label */}
        <span className="text-sm font-medium">1000 kWh/m²</span>
  
        {/* Hide Default Thumb via Tailwind and Custom CSS */}
        <style>
          {`
            /* Hide default Webkit (Chrome, Safari) range thumb */
            input[type="range"]::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 0;
              height: 0;
              background: transparent;
              border: none;
            }
  
            /* Hide default Firefox (Mozilla) range thumb */
            input[type="range"]::-moz-range-thumb {
              width: 0;
              height: 0;
              background: transparent;
              border: none;
            }
          `}
        </style>
      </div>
    );
  };

  const [timeValue, setTimeValue] = useState(0);
  
  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
    setTimeValue(Number(event.target.value));
    setSunTime(sunTime.set({ hour: 8, minute: 0 }).add(Number(event.target.value), "minutes"));
    setRefreshSun(Math.random());
  };

  const [dateValue, setDateValue] = useState(0);

  const convertPercentageToDate = (value: number, year: number = moment().year()): Moment => {
    if (value < 0 || value > 365) {
      throw new Error("Value must be between 0 and 365");
    }
  
    // Get the total days in the year (handles leap years automatically)
    const totalDays = moment([year]).isLeapYear() ? 366 : 365;
  
    // Calculate the corresponding day of the year
    const dayOfYear = Math.round((value / 365) * (totalDays - 1)) + 1;
  
    // Convert day of year to a date
    return moment(`${year}-01-01`, "YYYY-MM-DD").dayOfYear(dayOfYear);
  };
  
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
    setDateValue(Number(event.target.value));
    const newDate = convertPercentageToDate(Number(event.target.value), 2025);
    setSunTime(sunTime.set({
      date: moment(newDate, "DD/MM/YYYY").date(),
      month: moment(newDate, "DD/MM/YYYY").month()
    }));
    setRefreshSun(Math.random());
  };

  const TAB_KEYS = {
    RADIATION_ANALYSIS: "RADIATION_ANALYSIS",
    SUN_SIMULATION: "SUN_SIMULATION",
  };

  const [selectedTab, setSelectedTab] = useState(TAB_KEYS.RADIATION_ANALYSIS);

  const tabs = useMemo(() => {
    const tabs = [];

    tabs.push({
      key: TAB_KEYS.RADIATION_ANALYSIS,
      label: t("3D_VIEW_RADIATION_ANALYSIS"),
    });

    tabs.push({
      key: TAB_KEYS.SUN_SIMULATION,
      label: t("3D_VIEW_SUN_SIMULATION"),
    });

    return tabs;
  }, []);

  const handleTabClick = useCallback(
    (tabKey) => {
      setSelectedTab(tabKey);
    },
    [setSelectedTab],
  );

  return (
    <div className="bg-white rounded-md relative z-[1000] mt-2">
      <h1 className="p-2 text-[#2B3674] text-2xl font-semibold">
        {t("3D_VIEW_SUN_SIMULATION")}
      </h1>
      <ArrowIcon />
      <div className="px-2">
        {open && <div>
          <div className="w-full">
            <TabBar tabs={tabs} clickHandler={handleTabClick} selected={selectedTab}></TabBar>
          </div>
          {selectedTab === TAB_KEYS.RADIATION_ANALYSIS && (
            <div className="pt-4 pb-4 space-y-2">
              <AnalysisSlider />
              <div className="text-slate-800 text-sm w-full max-w-xs">
                <div className="flex flex-col space-y-1">
                  <div className="flex justify-between">
                    <span>Jahresdurchschnitt</span>
                    <span>1894 kWh/m²</span>
                  </div>

                  <div className="flex justify-between">
                    <span>Sonnenexposition</span>
                    <span>11,08h/Tag</span>
                  </div>

                  <div className="flex justify-between">
                    <span>Sonnenexposition untertags</span>
                    <span>92%</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedTab === TAB_KEYS.SUN_SIMULATION && (
            <div className="pt-4 pb-4 space-y-2">
              {/* Obere Leiste */}
              <div className="flex items-center justify-between w-full px-4">
                <span className="text-lg text-slate-800">{timeString}</span>

                <span className="border-2 border-slate-500 rounded-full px-2 text-lg">{dateString}</span>
                
                {/* Ant Design DatePicker */}
                {/* <DatePicker
                  format={"DD/MM/YYYY"}
                  onChange={handleDateFieldChange}
                  defaultValue={sunTime}
                  className="border-2 border-slate-500 rounded-full px-2 text-lg"
                  popupClassName="rounded-lg shadow-lg"
                /> */}

                <div className="flex items-center text-slate-500">
                  <span className="text-lg">jetzt</span>
                </div>
              </div>
              <div className="flex items-center space-x-2 w-full">
                {/* Left Label */}
                <span className="text-sm font-medium">08:00</span>
          
                {/* Slider Container */}
                <div className="relative w-full">
                  {/* Gradient Background */}
                  <div className="absolute top-1/2 left-0 w-full h-2 rounded-full transform -translate-y-1/2 bg-gradient-to-r from-yellow-100 to-yellow-500"></div>
          
                  {/* Slider Input (Hidden Thumb) */}
                  <input
                    type="range"
                    min="0"
                    max="720"
                    value={timeValue}
                    onChange={handleTimeChange}
                    className="relative w-full h-2 bg-transparent appearance-none focus:outline-none cursor-pointer"
                    style={{
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                    }}
                  />
          
                  {/* Custom Slider Thumb */}
                  <div
                    className="absolute top-1/2 transform -translate-y-1/2 bg-white border-2 border-yellow-400 rounded-full w-5 h-5 pointer-events-none"
                    style={{
                      left: `calc(${convertTimeRange(timeValue)}% - 10px)`, // Position dynamically
                    }}
                  ></div>
                </div>
          
                {/* Right Label */}
                <span className="text-sm font-medium">20:00</span>
          
                {/* Hide Default Thumb via Tailwind and Custom CSS */}
                <style>
                  {`
                    /* Hide default Webkit (Chrome, Safari) range thumb */
                    input[type="range"]::-webkit-slider-thumb {
                      -webkit-appearance: none;
                      appearance: none;
                      width: 0;
                      height: 0;
                      background: transparent;
                      border: none;
                    }
          
                    /* Hide default Firefox (Mozilla) range thumb */
                    input[type="range"]::-moz-range-thumb {
                      width: 0;
                      height: 0;
                      background: transparent;
                      border: none;
                    }
                  `}
                </style>
              </div>
              <div className="flex items-center space-x-2 w-full">
                {/* Left Label */}
                <span className="text-sm font-medium">01.01.</span>
          
                {/* Slider Container */}
                <div className="relative w-full">
                  {/* Gradient Background */}
                  <div className="absolute top-1/2 left-0 w-full h-2 rounded-full transform -translate-y-1/2 bg-cyan-950">
                  {/* Kleine Markierungen */}
                    {[25, 50, 75].map((pos) => (
                        <div
                          key={pos}
                          className="absolute w-1 h-1 bg-yellow-400 rounded-full"
                          style={{ left: `${pos}%`, top: "50%", transform: "translate(-50%, -50%)" }}
                        ></div>
                    ))}</div>
          
                  {/* Slider Input (Hidden Thumb) */}
                  <input
                    type="range"
                    min="0"
                    max="365"
                    value={dateValue}
                    onChange={handleDateChange}
                    className="relative w-full h-2 bg-transparent appearance-none focus:outline-none cursor-pointer"
                    style={{
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                    }}
                  />
          
                  {/* Custom Slider Thumb */}
                  <div
                    className="absolute top-1/2 transform -translate-y-1/2 bg-white border-2 border-yellow-400 rounded-full w-5 h-5 pointer-events-none"
                    style={{
                      left: `calc(${convertDateRange(dateValue)}% - 10px)`, // Position dynamically
                    }}
                  ></div>
                </div>
          
                {/* Right Label */}
                <span className="text-sm font-medium">31.12.</span>
          
                {/* Hide Default Thumb via Tailwind and Custom CSS */}
                <style>
                  {`
                    /* Hide default Webkit (Chrome, Safari) range thumb */
                    input[type="range"]::-webkit-slider-thumb {
                      -webkit-appearance: none;
                      appearance: none;
                      width: 0;
                      height: 0;
                      background: transparent;
                      border: none;
                    }
          
                    /* Hide default Firefox (Mozilla) range thumb */
                    input[type="range"]::-moz-range-thumb {
                      width: 0;
                      height: 0;
                      background: transparent;
                      border: none;
                    }
                  `}
                </style>
              </div>
            </div>
          )}
        </div>}
      </div>
    </div>
  );
};

export default SunConfiguration;
