import axios from "axios";
import * as GeoTIFF from "geotiff";
import * as THREE from "three";

import { ThirdPartyApis } from "src/api";

import { parseReverseGeoGoogle } from "src/utils";


let mesh, minElevation, maxElevation;

const radiusMeters = 30;

export const checkIfGoogleModelAvailable = async (quoteDetails) => {
  try {
    const placeId = await getPlaceId(quoteDetails.mapboxAddress.complete);
    const coordinates = await getGeoCoords(placeId);
    const latitude = coordinates[1];
    const longitude = coordinates[0];
    await getGeoTIFFURL({ lat: latitude, lng: longitude });

    return true;
  } catch (error) {
    if (error.code === 404) {
      return false;
    }else {
      console.error("Error occurred:", error);
    }
  }
}

export const fetchDSM = async (quoteDetails) => {
    try {
        const placeId = await getPlaceId(quoteDetails.mapboxAddress.complete);
        const coordinates = await getGeoCoords(placeId);
        const latitude = coordinates[1];
        const longitude = coordinates[0];
        const result = await getGeoTIFFURL({ lat: latitude, lng: longitude });
        const terrainData = await loadGeoTIFF(result.dsmUrl);
        const obj = await initTerrain(terrainData, result.rgbUrl);
        return obj;
    } catch (error) {
        console.error("Error occurred:", error); 
    }
}

const getPlaceId = async (mapboxAddress) => {
  const respose = await axios.get(
    ThirdPartyApis.googleMapsAutocompleteQuery(mapboxAddress),
  );
  const {
    data: { predictions },
  } = respose.data;
  return predictions[0].place_id;
}

const getGeoCoords = async (placeId) => {
  const mapboxAddr = await parseReverseGeoGoogle(placeId);
  return mapboxAddr.geometry.coordinates;
}

export async function getGeoTIFFURL(homeLocation) {
  const endpoint = `https://solar.googleapis.com/v1/dataLayers:get?location.latitude=${homeLocation.lat}&location.longitude=${homeLocation.lng}&radiusMeters=${radiusMeters}&view=FULL_LAYERS&requiredQuality=LOW&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
  return await new Promise((resolve, reject) => {
    try {
      fetch(endpoint)
        .then(async (response) => await response.json())
        .then((result) => {
          if (result.error) {
            reject(result.error);
          } else {
            resolve(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } catch (err) {
      reject(err);
    }
  });
}

async function loadGeoTIFF(URL) {
  const dsmUrl = URL + `&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;

  // Read the GeoTIFF file
  const response = await fetch(dsmUrl);
  const arrayBuffer = await response.arrayBuffer();
  const tiff = await GeoTIFF.fromArrayBuffer(arrayBuffer);
  const image = await tiff.getImage();
  
  // Get raster data
  const rasters = await image.readRasters();
  const width = image.getWidth();
  const height = image.getHeight();
  
  // Assuming single band elevation data
  const elevationData = rasters[0];
  
  // Normalize elevation data
  minElevation = elevationData.reduce((min, val) => Math.min(min, val), Infinity);
  maxElevation = elevationData.reduce((max, val) => Math.max(max , val), -Infinity);

  const normalizedData = elevationData.map(
      value => (value - minElevation) / (maxElevation - minElevation) 
  );

  return {
      data: normalizedData,
      width,
      height
  };
}

async function loadRGBGeoTIFF(URL) {
  const dsmUrl = URL + `&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;

  // Read the GeoTIFF file
  const response = await fetch(dsmUrl);
  const arrayBuffer = await response.arrayBuffer();
  const tiff = await GeoTIFF.fromArrayBuffer(arrayBuffer);
  const image = await tiff.getImage();

  // Get raster data (interleaved RGB bands)
  const raster = await image.readRasters({ interleave: true }); // Combine bands into RGB array
  const width = image.getWidth();
  const height = image.getHeight();

  // Create RGBA data from RGB raster
  const rgbaData = new Uint8Array(width * height * 4);
  for (let i = 0; i < width * height; i++) {
      rgbaData[i * 4] = raster[i * 3];       // Red
      rgbaData[i * 4 + 1] = raster[i * 3 + 1]; // Green
      rgbaData[i * 4 + 2] = raster[i * 3 + 2]; // Blue
      rgbaData[i * 4 + 3] = 255;            // Alpha
  }

  // Create a Three.js DataTexture
  const texture = new THREE.DataTexture(rgbaData, width, height, THREE.RGBAFormat);
  texture.needsUpdate = true;

  return texture; // Return the texture for use in materials
}

async function initTerrain(terrainData, rgbURL) { 
    // Create geometry
    const geometry = new THREE.PlaneGeometry(
        radiusMeters * 2, 
        radiusMeters * 2, 
        terrainData.width - 1, 
        terrainData.height - 1
    );
    geometry.rotateX(-Math.PI / 2);

    // Modify vertex heights
    const vertices = geometry.attributes.position.array;
    for (let i = 0, j = 0, l = vertices.length; i < l; i++, j += 3) {
        vertices[j + 1] = terrainData.data[i] || 0;
        vertices[j + 1] *= 16;
    }

    /* const mapTexture = await loadRGBGeoTIFF(rgbURL); */

    // Mirror the texture
/*     mapTexture.center.set(0.5, 0.5); // Set the mirroring center
    mapTexture.repeat.set(1, -1);    // Mirror horizontally; for vertical mirroring, use (1, -1)
    mapTexture.wrapS = THREE.RepeatWrapping; // Allow horizontal wrapping
    mapTexture.wrapT = THREE.RepeatWrapping; // Allow vertical wrapping  */

    // Create the material that reacts to lights
    /* const material = new THREE.MeshPhongMaterial({
      map: mapTexture, // Apply the texture to the material
      emissive: new THREE.Color(0x000000), // Optional: Add emissive glow to material
      shininess: 30,  // Adjust the shininess of the material (higher = shinier)
      specular: new THREE.Color(0x111111), // Color of specular highlights (shiny parts)
      transparent: true,  // Allow transparency
      opacity: 1.0,       // Set full opacity (change if needed)
    }); */

    // Create MeshLambertMaterial that reacts to lights
    /* const material = new THREE.MeshLambertMaterial({
      map: mapTexture,                  // Set the texture for the material
      emissive: new THREE.Color(0x000000), // Optional: Set emissive color (adds glow effect)
      transparent: true,             // If you need transparency
      opacity: 1.0,                  // Set opacity if using transparency
    }); */

    /* // Create material that reacts to lights
    const material = new THREE.MeshStandardMaterial({
      map: mapTexture,
      emissive: new THREE.Color(0x000000), // Optional: Set emissive color for brightness
      roughness: 0.5,                      // Roughness factor (controls how shiny it looks)
      metalness: 0.5,                      // Metalness factor (controls the metal-like behavior)
      transparent: true,                   // Allow transparency
      opacity: 1.0,                        // Full opacity
    }); */

    // const material = new THREE.MeshBasicMaterial({ map: mapTexture });
    
/*     // Set up mirroring
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping; */

    // Access the ambient light color from the scene
    /* const ambientLight = scene.getObjectByName("ambientLight");  // Assuming the ambient light has this name
    const ambientColor = ambientLight ? ambientLight.color : new THREE.Color(0xffffff);
    console.debug("color", ambientColor); */


    const texture = await loadRGBGeoTIFF(rgbURL);

    const material = new THREE.ShaderMaterial({
      uniforms: {
          map: { value: texture },
          center: { value: new THREE.Vector2(0.5, 0.5) }, // Center for rotation/mirroring
          mirrorX: { value: true },  // Enable horizontal mirroring
          mirrorY: { value: false }, // Disable vertical mirroring
          rotation: { value: Math.PI }, // Rotate 90 degrees (in radians)
      },
      vertexShader: `
          varying vec2 vUv;
          void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
      `,
      fragmentShader: `
          uniform sampler2D map;
          uniform vec2 center;
          uniform bool mirrorX;
          uniform bool mirrorY;
          uniform float rotation;
          varying vec2 vUv;
  
          void main() {
              vec2 uv = vUv;
  
              // Apply mirroring
              if (mirrorX) {
                  uv.x = center.x - (uv.x - center.x);
              }
              if (mirrorY) {
                  uv.y = center.y - (uv.y - center.y);
              }
  
              // Apply rotation
              float cosTheta = cos(rotation);
              float sinTheta = sin(rotation);
              uv -= center; // Translate UV to the center for rotation
              uv = vec2(
                  uv.x * cosTheta - uv.y * sinTheta,
                  uv.x * sinTheta + uv.y * cosTheta
              );
              uv += center; // Translate UV back to its original position
  
              vec4 color = texture2D(map, uv);
              gl_FragColor = color;
          }
      `
    });

    // Create mesh
    mesh = new THREE.Mesh( 
        geometry,
        material
    );


    // Set model layers for cameras
    mesh.layers.enable(0);
    mesh.layers.enable(1);


    mesh.castShadow = true;   // ✅ Allows this object to cast shadows
    mesh.receiveShadow = true; // ✅ Allows this object to receive shadows

    // mesh.scale.set(0.005, 15, 0.005) 
    return mesh;
}