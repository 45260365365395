import { Popover } from "@mui/material";
import { Button, Tooltip } from "antd";
import axios from "axios";
import JSZip from "jszip";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useAppSelector } from "src/hooks";

import { bakeModelHost } from "src/api/backend-endpoints";
import { RemoveItIcon, DeleteItIcon3, PauseItIcon } from "src/assets/svgs";
import DownloadIcon from "src/assets/svgs/download-icons/download.svg";
import useGetSaveProjectFun from "src/hooks/useGetSaveProjectFun";
import useRequestBakeModelHook from "src/hooks/useRequestBakeModel";
import ConfirmationDialog from "src/PanelPlacement/PopUp/ConfirmationDialog";
import { InfoIcon, TabBar } from "src/shared/components";
import { Bar } from "src/shared/components/HorizontalBarCardChart/HorizontalBarCardChart.styles";
import { Tab } from "src/shared/components/TabBar/TabBar";
import rolechecker from "src/shared/RoleChecker/rolechecker";
import { PrimaryButton } from "src/shared/StyledComponents";
import { isValidURL } from "src/utils";

import { useAddressWithCountryReplacement } from "../ApplicationStatus/hooks";

import {
  BigContainer,
  DeleteModelButton,
  GreenButton,
  InnerContainer,
  StyledDownloadButton,
  UploadBox,
  UploadingActionButtons,
} from "./ApplicationStep3.styles";
import { UploadCard } from "./components";
import ThreeDViewer from "./components/3DViewer/3DViewer";
import { checkIfGoogleModelAvailable } from "./CreateGoogleModel";
import ThreeDViewerWithImages from "./ThreeDViewerWithImages";

import { AppActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { updateBakeModelProgress } from "src/redux/project/action/action.creators";
import { getAddress, getProject } from "src/redux/project/selectors";
import { AppSelectors, ProjectSelectors } from "src/redux/selectors";
import { getSolarAPIData } from "src/redux/solarAPI/selector";
import { getUser } from "src/redux/user/selectors";

interface ApplicationStep3Props {
  isFromWebinarPopup?: boolean;
}

export const ApplicationStep3: React.FC<ApplicationStep3Props> = (props: ApplicationStep3Props) => {
  const { isFromWebinarPopup } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quote = useSelector(ProjectSelectors.getQuoteProfile);
  const quoteData = useSelector(ProjectSelectors.getQuote);
  const houseImageDetails = useAppSelector(ProjectSelectors.getDroneData);
  const selectedDronFiles = useAppSelector(ProjectSelectors.getSelectedFiles);
  const project = useSelector(getProject);
  const [fileUploadProgress, setFileUploadProgress] = useState<any>({});
  const [isUploadPaused, setIsUploadPaused] = useState(false);
  const address = useSelector(getAddress);
  const { requestBakeModel } = useRequestBakeModelHook();
  const { saveProject } = useGetSaveProjectFun();
  const [query] = useSearchParams();
  const isUploadRequired = query.get("upload");
  const solarAPIData = useSelector(getSolarAPIData);
  const selectedInstallers = useSelector(ProjectSelectors.getSelectedInstaller);
  const nextStepBtnClickHandler = () => {
    dispatch(AppActions.setCurrentStep(3));
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoadDialog, setIsLoadDialog] = useState(false);
  const [dialogText, setDialogText] = useState(t("GOOGLE_API_DIALOG_TEXT"));
  const [dialogConfirm, setDialogConfirm] = useState(t("GOOGLE_API_DIALOG_CONFIRMATION"));
  const [dialogCancel, setDialogCancel] = useState(t("GOOGLE_API_DIALOG_CANCEL"));
  const [disableDialogConfirm, setDisableDialogConfirm] = useState(false);
  const addressText = useAddressWithCountryReplacement();

  const imgURLs: any = useMemo(() => {
    return quote.imgURLs.find((img) => img.includes(".obj"));
  }, [quote.imgURLs]);

  const providerImgURLs = useMemo(() => {
    return quote?.imgURLs.filter((img) => {
      const fileExtension: any = img?.split(".").pop()?.toLowerCase();
      const isInvalidExtension = ["obj", "mtl", "pvprj"].includes(fileExtension);
      if (isInvalidExtension) {
        return false;
      }
      if (img.includes("Destination") && isValidURL(img)) {
        if (address.city === "Gundelfingen") {
          return !img.includes("Baked") && isValidURL(img);
        } else {
          return img.includes("Screenshot");
        }
      }
      return isValidURL(img);
    });
  }, [address, quote.imgURLs]);
  const installerUploadedObj = selectedInstallers[0]?.files?.find((a) => a.includes(".obj"));
  const installerUploadedMtl = selectedInstallers[0]?.files?.find((a) => a.includes(".obj.mtl"));

  const user = useSelector(getUser);

  const adminGeneratedBakeModelUrls = useSelector(ProjectSelectors.getAdminGeneratedBakeModelUrls);
  const [currentViewer, setCurrentViewer] = useState(
    imgURLs || adminGeneratedBakeModelUrls || (installerUploadedObj && installerUploadedMtl)
      ? "3D-Viewer"
      : providerImgURLs.length
      ? "3D-Viewer with pictures"
      : "Uploader",
  );

  const currentStep = useAppSelector(AppSelectors.getCurrentStep);

  const TABS = useMemo(() => {
    const tabs: Tab[] = [];
    if (
      imgURLs ||
      adminGeneratedBakeModelUrls ||
      project.bakeModel?.artifacts?.length ||
      installerUploadedObj ||
      project.googleModel
    ) {
      tabs.push({
        key: "3D-Viewer",
        label: t("3D-Viewer"),
      });
    }

    if (
      (providerImgURLs.length ||
        adminGeneratedBakeModelUrls ||
        project.bakeModel?.artifacts?.length ||
        installerUploadedObj ||
        project.googleModel) 
        // && (adminGeneratedBakeModelUrls || !solarAPIData)
    ) {
      tabs.push({
        key: "3D-Viewer with pictures",
        label: t("3D-Viewer with pictures"),
      });
    }

    tabs.push({
      key: "Uploader",
      label: t("Uploader"),
    });

    return tabs;
  }, [t, imgURLs, providerImgURLs, project, solarAPIData]);

  const tabClickHandler = useCallback((key: string): void => {
    setCurrentViewer(key);
  }, []);

  const handleObjdownload = async () => {
    try {
      toast.info(t("Downloading images, please wait..."));
      const zip = new JSZip();
      const validUrls = quote?.imgURLs.filter((img) => {
        const fileExtension: any = img?.split(".").pop()?.toLowerCase();
        const isvalidExtension = !["pvprj"].includes(fileExtension);
        if (isvalidExtension) {
          return imgURLs;
        }
      });
      for (const imageObj of validUrls) {
        const encodedUrl = imageObj.replaceAll(" ", "+") + "?randome=" + Math.random();
        const response = await fetch(encodedUrl);
        const data = await response.blob();
        const filename = imageObj.substring(imageObj.lastIndexOf("/") + 1);
        zip.file(filename, data);
      }
      const content = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = `${
        quote?.ModelNo ? quote?.ModelNo : quoteData?.mapboxAddress?.address
      }-images.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(t("Images downloaded successfully"));
    } catch (err) {
      console.error(err);
      toast.error(t("Failed to download images"));
    }
  };

  const checkForGoogleModel = async () => {
    if(project.googleModel) {
      setIsLoadDialog(false);
      setDisableDialogConfirm(true);
      setDialogText(t("STEP3_ALREADY_GOOGLE_MODEL"));
      return;
    }else if(imgURLs) {
      setIsLoadDialog(false);
      setDisableDialogConfirm(true);
      setDialogText(t("STEP§_ALREADY_MODEL"));
      return;
    }
    setIsLoadDialog(true);
    setDialogText(t("STEP3_CHECKING_GOOGLE"));
    if(await checkIfGoogleModelAvailable(quoteData)) {
      setIsLoadDialog(false);
      setDisableDialogConfirm(true);
      setDialogText(t("STEP3_GOOGLE_MODEL_FOUND"));

      dispatch(
        ProjectActions.updateProject({
          ...project,
          googleModel: true
        })
      );
    }else {
      setIsLoadDialog(false);
      setDisableDialogConfirm(true);
      setDialogText(t("STEP3_NO_GOOGLE_MODEL"));
    }
  }

  const closeDialog = () => {
    setIsDialogOpen(false);
    setIsLoadDialog(false);
    setDisableDialogConfirm(false);
    setDialogText(t("GOOGLE_API_DIALOG_TEXT"));
  }

  const handleImageDownload = async () => {
    try {
      toast.info(t("Downloading images, please wait..."));
      const zip = new JSZip();

      const droneImages: any = houseImageDetails?.imageURLs;
      for (const imageObj of droneImages) {
        const encodedUrl = imageObj.url.replaceAll(" ", "+") + "?randome=" + Math.random();
        const response = await fetch(encodedUrl);
        const data = await response.blob();
        const filename = imageObj.url.substring(imageObj.url.lastIndexOf("/") + 1);
        zip.file(filename, data);
      }
      const content = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = `${
        quote?.ModelNo ? quote?.ModelNo : quoteData?.mapboxAddress?.address
      }-images.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(t("Images downloaded successfully"));
    } catch (err) {
      console.error(err);
      toast.error(t("Failed to download images"));
    }
  };

  const formatFileSize = (sizeInBytes: number) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;

    if (sizeInBytes < kilobyte) {
      return sizeInBytes + " B";
    } else if (sizeInBytes < megabyte) {
      return (sizeInBytes / kilobyte).toFixed(2) + " KB";
    } else if (sizeInBytes < gigabyte) {
      return (sizeInBytes / megabyte).toFixed(2) + " MB";
    } else {
      return (sizeInBytes / gigabyte).toFixed(2) + " GB";
    }
  };

  const avrageProgress = useMemo(() => {
    const values = Object.values(fileUploadProgress);
    const keys = Object.keys(fileUploadProgress);
    const QueuedFiles = selectedDronFiles.filter(
      (selectedFile: any) =>
        !houseImageDetails?.imageURLs?.some(
          (image: any) => image.fileName === selectedFile.name || keys.includes(selectedFile.name),
        ),
    );
    const progressValue = values.filter((a) => a !== 100);

    let sum: any = progressValue.reduce((acc: any, value) => acc + value, 0);
    sum = sum + houseImageDetails.imageURLs.length * 100;
    const average =
      sum / (progressValue.length + houseImageDetails?.imageURLs.length + QueuedFiles.length);

    // if (average === 100 && !project.bakeModel.id) {
    //   dispatch(openPopup(POPUP_KEYS.request_pv_model));
    // }

    return average || 0;
  }, [fileUploadProgress, houseImageDetails, project.bakeModel.id]);

  const fileDeleteHandler = useCallback(
    (deleteURL: any) => {
      const action = ProjectActions.updateDroneData;
      dispatch(
        action({
          imageURLs: houseImageDetails.imageURLs.filter((url) => url !== deleteURL),
        }),
      );

      dispatch(
        AppActions.updateAppState({
          actions: {
            updateDeal: true,
          },
        }),
      );

      const remainingSelectedFiles = selectedDronFiles.filter(
        (file: any) => file.name !== deleteURL.fileName,
      );
      dispatch(ProjectActions.addSelectedFiles(remainingSelectedFiles));
    },
    [dispatch, houseImageDetails.imageURLs, selectedDronFiles],
  );

  const handlePauseClick = useCallback(() => {
    if (isUploadPaused) {
      setIsUploadPaused(false);
    } else {
      setIsUploadPaused(true);
    }
  }, [isUploadPaused]);

  const handledeleteImages = () => {
    const action = ProjectActions.updateDroneData;
    dispatch(
      action({
        imageURLs: [],
      }),
    );

    dispatch(
      AppActions.updateAppState({
        actions: {
          updateDeal: true,
        },
      }),
    );

    const remainingSelectedFiles: never[] = [];
    dispatch(ProjectActions.addSelectedFiles(remainingSelectedFiles));
  };

  const stopModel = () => {
    dispatch(ProjectActions.updateBakeModelId({ id: "", artifacts: "" }));
    localStorage.setItem("progress", "0");
    localStorage.setItem("returnTime", "");
    dispatch(updateBakeModelProgress({ percentage: 0, time: "", elapsed: 0 }));
  };

  const deletePVModel = async () => {
    try {
      const bakedModelId = project?.bakeModel?.id;

      if (!bakedModelId) return toast.error(t("Model not found"));

      if (project?.bakeModel?.artifacts) {
        stopModel();
      } else {
        const response = await axios.post(`${BackendApis.requestBakeModel.url}/stop`, {
          jobName: bakedModelId,
        });
        if (response) {
          const message = response?.data?.message;

          toast.success(t(message));
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (isUploadRequired) {
      setCurrentViewer("Uploader");
    }
  }, [isUploadRequired]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseConfirm = () => {
    setAnchorEl(null);
  };

  const isRoleValid = rolechecker(user?.role);

  return (
    <>
      <BigContainer>
        <TabBar tabs={TABS} clickHandler={tabClickHandler} defaultSelected={currentViewer} />
        {currentViewer === "3D-Viewer" && <ThreeDViewer />}
        {currentViewer === "3D-Viewer with pictures" && (
          <ThreeDViewerWithImages setCurrentViewer={setCurrentViewer} TABS={TABS} />
        )}
        {currentViewer === "Uploader" && (
          <>
            {(houseImageDetails?.imageURLs.length > 0 || selectedDronFiles.length > 0) &&
              avrageProgress > 0 && (
                <>
                  <InnerContainer>
                    {project.bakeModel.id && (
                      <DeleteModelButton onClick={deletePVModel}>
                        <DeleteItIcon3 />
                      </DeleteModelButton>
                    )}
                    <div className="address">
                      {addressText}
                      <span className="des">
                        {t("With precise drone images to your perfect PV-System")}
                      </span>
                    </div>
                    <div className="progress">
                      {project.bakeModel.id ? (
                        <>
                          <div className="head">
                            {project?.bakeModel?.artifacts?.length
                              ? t("hurray, Your Pv-model has been sucessfully created")
                              : t(
                                  "Your photogrammetric 3D-Model is being created and will be available in about",
                                ) + " "}
                            <Tooltip
                              placement="topRight"
                              title={t(
                                "Das Modell wird nach Fertigstellung hier angezeigt. Dies dauert im Schnitt 45min. Wenn nach 60min kein Modell angezeigt wird wende Dich bitte an unseren Support unter experte@solarhub24.de ",
                              )}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </div>

                          {project?.bakeModel?.artifacts?.length ? (
                            <PrimaryButton onClick={() => setCurrentViewer(TABS[1].key)}>
                              {t("View your model")}
                            </PrimaryButton>
                          ) : (
                            // <Bar
                            //   bgColor={"#FFD75D"}
                            //   topColor={"#4D7E6D"}
                            //   topPercent={Number(project.trackProgress.percentage)}
                            // >
                            //   <div className="top-bar"></div>
                            // </Bar>
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          <GreenButton
                            onClick={() => {
                              if (selectedDronFiles?.length) {
                                dispatch(openPopup(POPUP_KEYS.request_pv_model));
                              } else if (houseImageDetails.imageURLs.length) {
                                requestBakeModel();
                              }
                            }}
                          >
                            {`${t("ADD 3d-Model / PV Planning")}`}
                          </GreenButton>
                        </>
                      )}
                    </div>
                  </InnerContainer>
                  <div className="grid grid-cols-3 w-full  items-center">
                    <div className="address progress-total space-x-2 flex items-center col-start-2 mx-auto">
                      <span>
                        {t("Progress total {{ total }} uploaded", {
                          total: `${houseImageDetails.imageURLs.length}/${
                            selectedDronFiles.length || houseImageDetails.imageURLs.length
                          }`,
                        })}
                      </span>
                      <StyledDownloadButton
                        onClick={handleImageDownload}
                        className="address progress-total space-x-1 justify-center items-center"
                      >
                        <img src={DownloadIcon} alt="download" />
                        <span>{t("Download drone images")}</span>
                      </StyledDownloadButton>
                    </div>
                    {isRoleValid && (
                      <div className="flex justify-end">
                        <PrimaryButton
                          className="!self-end !p-3 !mx-8"
                          onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          {t("Remove quote Images")}
                        </PrimaryButton>
                      </div>
                    )}
                  </div>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseConfirm}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div
                      style={{
                        background: "white",
                        border: "2px solid white",
                        borderRadius: "5px",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div>{t("Are you sure you want to delete all images?")}</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "right",
                        }}
                      >
                        <Button size="small" color="error" onClick={handleCloseConfirm}>
                          {t("Cancel")}
                        </Button>
                        <Button size="small" color="success" onClick={handledeleteImages}>
                          {t("Confirm")}
                        </Button>
                      </div>
                    </div>
                  </Popover>

                  <Bar
                    bgColor={"#eee"}
                    topColor={avrageProgress < 100 ? "#FFD75D" : "#4D7E6D"}
                    topPercent={avrageProgress}
                    style={{ width: "80%" }}
                  >
                    <div className="top-bar">{avrageProgress.toFixed(1) || 0} %</div>
                  </Bar>

                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>{t("Name")}</th>
                        <th>{t("Size")}</th>
                        <th>{t("Progress")}</th>
                        <th>{t("Status")}</th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {houseImageDetails?.imageURLs?.length > 0 &&
                        houseImageDetails?.imageURLs?.map((items: any, index) => {
                          return (
                            <tr key={index}>
                              <td>{items.fileName}</td>
                              <td>{formatFileSize(items.size)}</td>
                              <td>
                                <Bar bgColor={"#eee"} topColor={"#4D7E6D"} topPercent={100}>
                                  <div className="top-bar">100 %</div>
                                </Bar>
                              </td>
                              <td>{t("Uploaded Successful")}</td>
                              <td onClick={() => fileDeleteHandler(items)}>
                                <div className="icon-div">
                                  <DeleteItIcon3 />
                                </div>
                              </td>
                            </tr>
                          );
                        })}

                      {selectedDronFiles?.length > 0 &&
                        selectedDronFiles.map((items: any, index: number) => {
                          const uploadPercentage = fileUploadProgress[items.name] || 0;
                          return (
                            uploadPercentage < 100 && (
                              <tr key={index}>
                                <td>{items.name}</td>
                                <td>{formatFileSize(items.size)}</td>
                                <td>
                                  <Bar
                                    bgColor={"#eee"}
                                    topColor={uploadPercentage < 100 ? "#FFD75D" : "#4D7E6D"}
                                    topPercent={uploadPercentage}
                                  >
                                    <div className="top-bar">{uploadPercentage} %</div>
                                  </Bar>
                                </td>
                                <td>{uploadPercentage !== 0 ? t("Uploading...") : t("Queued")}</td>
                                <td onClick={handlePauseClick}>
                                  <div className="icon-div">
                                    {uploadPercentage === 0 ? (
                                      <RemoveItIcon />
                                    ) : (
                                      <UploadingActionButtons>
                                        <PauseItIcon />
                                        <DeleteItIcon3 />
                                      </UploadingActionButtons>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )
                          );
                        })}
                    </tbody>
                  </table>
                </>
              )}

            <UploadBox>
              <UploadCard
                cardType="houseTop"
                setPercentage={setFileUploadProgress}
                selectedFiles={selectedDronFiles}
              />
              <UploadCard
                cardType="electricMeter"
                setPercentage={setFileUploadProgress}
                selectedFiles={selectedDronFiles}
              />
              <UploadCard
                cardType="ThreeDModels"
                setPercentage={setFileUploadProgress}
                selectedFiles={selectedDronFiles}
                isUploadPaused={isUploadPaused}
                setIsDialogOpen={setIsDialogOpen}
              />
            </UploadBox>
            {!isFromWebinarPopup && (
              <PrimaryButton
                onClick={() => {
                  dispatch(AppActions.setCurrentStep(currentStep + 1));
                  saveProject();
                }}
                style={{
                  width: "230px",
                }}
              >
                {`${t("NEXT STEP")}`}
              </PrimaryButton>
            )}
            <ConfirmationDialog
              isOpen={isDialogOpen}
              onClose={() => {}}
              onCancel={closeDialog}
              onConfirm={checkForGoogleModel}
              text={dialogText}
              confirmText={dialogConfirm}
              cancelText={dialogCancel}
              loader={isLoadDialog}
              disableConfirm={disableDialogConfirm}
            />
          </>
        )}
      </BigContainer>
    </>
  );
};
