import { Select, Typography, Table, Input } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useAppDispatch, useNumberFormat } from "src/hooks";

import { sendResultMailToInstaller } from "src/api/backend-endpoints";
import { useSaveMedia, useUpdateInstallerAPI } from "src/hooks/apis";
import { PopupSkeleton } from "src/shared/components";
import { generateSettingsForOffers } from "src/utils";

import "./detailedOffer.css";
import {
  StyledButton,
  StyledContentDetails,
  StyledContentHeading,
  StyledContentTitle,
  StyledContentMiddle,
  StyledContentBottom,
  RealizationModalWrapper,
  StyledHR,
  TotalBox,
  HrWrapper,
  BottomContentWrapper,
  FinalOptionsWrapper,
} from "./detailedOfferStyled";

import { PopupActions } from "src/redux";
import { ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { getAllProducts } from "src/redux/products/selector";

import { DownOutlined, PlusSquareFilled } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { Accordion, AccordionDetails, AccordionSummary, MenuItem } from "@mui/material";


import axios from "axios";


import { getProject } from "src/redux/project/selectors";
import { PopupSelectors } from "src/redux/selectors";

interface IDetailedOffer {
  quote: any;
  t: any;
}

const FinalOfferCalculation = ({ t, total, mwst, kwp, final, discount, setDiscount }: any) => {
  const { formatNumber } = useNumberFormat();

  return (
    <table className="final-offer-table">
      <tbody>
        <tr>
          <td>{t("Total")}</td>
          <td align="center">€</td>
          <td>{formatNumber(total?.toFixed(2) || 0)}</td>
        </tr>
        <tr>
          <td>{t("Discount")}</td>
          <td align="center">€</td>
          <td colSpan={2} align="right">
            <Input value={discount} disabled type="number" style={{ width: "82px" }} />
          </td>
        </tr>
        <tr>
          <td>MwSt.</td>
          <td align="center">€</td>
          <td>{formatNumber(mwst?.toFixed(2) || 0)}</td>
        </tr>
        <tr>
          <td>€ / kWp</td>
          <td align="center">€</td>
          <td>{formatNumber(kwp?.toFixed(2) || 0)}</td>
        </tr>
        <tr>
          <td>{t("Final price")} (inkl. MwSt.)</td>
          <td align="center">€</td>
          <td>{formatNumber((total - discount)?.toFixed(2) || 0)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const DetailedOffer = () => {
  // const isLoading = useAppState(selectMatchedProjectIsLoading);
  const isLoading = false;
  const dispatch = useAppDispatch();
  const popups = useSelector(PopupSelectors.getPopup);
  const quote = popups.INSTALLER_OFFER.data?.quote;
  const project = useSelector(getProject);

  const productList = useSelector(getAllProducts);
  const { t } = useTranslation();
  const { Column, ColumnGroup } = Table;
  const [productIdList, setProductIdList] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [additionalProducts, setAdditionalProducts] = useState<any>([]);
  const [selectedCondition, setSelectedCondition] = useState("");

  const [description, setDescription] = useState("");
  const [discount, setDiscount] = useState<number>(0);
  const sigCanvas: any = useRef({});

  const [displayedConstants, setDisplayedConstants] = useState<any>([]);
  const { formatNumber } = useNumberFormat();
  const { error, fetchData, loading, response } = useSaveMedia();
  const { fetchData: updateInstallerApi, response: updateResponse } = useUpdateInstallerAPI();
  const boxRef = useRef<HTMLDivElement>(null);
  const [sliderSettings, setSliderSettings] = useState(generateSettingsForOffers(500));

  // set the states from API data
  useEffect(() => {
    setProductIdList(quote?.selectedProducts);
    setDescription(quote?.offerDescription);
    setSelectedCondition(quote?.condition);
    setDiscount(quote?.discount || 0);
  }, [quote]);

  const selectedProductsTotal = useMemo(() => {
    const total = selectedProducts.reduce(
      (acc: number, product: any) => acc + product.quantity * product.priceB2c,
      0,
    );
    return total;
  }, [selectedProducts, productIdList]);

  const selectedConstantsTotal = useMemo(() => {
    const total = displayedConstants?.reduce((acc: number, constant: any) => {
      if (constant.type === "percentage of project cost") {
        return acc + selectedProductsTotal * (constant.priceB2c / 100);
      } else {
        return acc + constant.priceB2c;
      }
    }, 0);
    return total;
  }, [displayedConstants, selectedProductsTotal]);

  useEffect(() => setDisplayedConstants(quote?.selectedConstants), [quote]);

  useEffect(() => {
    // get all item ids from the quote
    if (quote?.selectedProducts?.length) return;

    const itemIds: any = [];
    Object.values(quote?.project?.components || {}).forEach((component: any) => {
      if (typeof component === "object") {
        if (component?.item) {
          itemIds.push({
            id: component?.item,
            quantity: component?.quantity,
          });
        }
      } else if (Array.isArray(component)) {
        component.forEach((c) => {
          if (typeof c === "object" && c?.item) {
            itemIds.push({ id: c?.item, quantity: c?.quantity });
          }
        });
      }
    });
    setProductIdList(itemIds);
  }, [quote, productList]);

  useEffect(() => {
    // get products for the product ids
    const filteredProducts: any = [];

    productIdList.forEach((c: any) => {
      const product = productList?.find((product) => product._id === c.id);
      if (product) {
        const updatedProduct = {
          ...product,
          quantity: c.quantity !== undefined ? c.quantity : 1,
        };
        filteredProducts.push(updatedProduct);
      }
    });

    // get products from additional product ids
    additionalProducts.forEach((c: any) => {
      const product = productList?.find((product) => product._id === c.id);
      if (product) {
        const updatedProduct = {
          ...product,
          quantity: c.quantity !== undefined ? c.quantity : 1,
        };
        filteredProducts.push(updatedProduct);
      }
    });
    setSelectedProducts(filteredProducts);
  }, [productList, productIdList, additionalProducts]);

  const [expanded, setExpanded] = useState(false);
  const handleCancel = () => {
    dispatch(PopupActions.closePopup(POPUP_KEYS.installer_offer));
  };

  const handleAccordionChange = (expanded: boolean) => {
    setExpanded(expanded);
  };

  const dataURLtoFile = (dataUrl: string, filename: string): File => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    if (!mime) {
      throw new Error("MIME type is not available");
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleAcceptOffer = async ({ customerSignature }: { customerSignature: string }) => {
    void updateInstallerApi({
      ...BackendApis.updateInstaller({
        id: quote.matchedId,
      }),
      data: { customerSignature, offerAccepted: true },
    });
  };

  const handleRejectOffer = () => {
    void updateInstallerApi({
      ...BackendApis.updateInstaller({
        id: quote.matchedId,
      }),
      data: { offerAccepted: false, offerRejected: true, isDetailedOffer: false },
    });
  };

  const sendResultMail = async (img: string) => {
    const result = await axios.post(sendResultMailToInstaller.url, { iid: quote?.iid, img });
  };

  useEffect(() => {
    if (response?.data?.data) {
      const url: string | undefined = response.data?.data?.[0]?.url;
      if (url) {
        handleAcceptOffer({ customerSignature: url });
        sendResultMail(url);
      }
    }
  }, [response]);

  useEffect(() => {
    if (updateResponse?.data?.data) {
      if (updateResponse?.data?.data?.offerAccepted) {
        toast.success("Offer Accepted");
      } else {
        toast.success("Offer Rejected");
      }
      const updatedSelectedInstallers = project.selectedInstallers.map((installer) => {
        if (installer._id === updateResponse.data.data._id) {
          return updateResponse.data.data;
        }
        return installer;
      });
      dispatch(
        ProjectActions.updateProject({
          ...project,
          selectedInstallers: updatedSelectedInstallers,
        }),
      );
      dispatch(PopupActions.closePopup(POPUP_KEYS.installer_offer));
    }
  }, [updateResponse]);

  const handleCustomerOffer = async () => {
    try {
      const dataUrl = sigCanvas.current?.toDataURL();
      if (!dataUrl) {
        throw new Error("Canvas data URL is not available");
      }
      // Check if the canvas is empty
      const isCanvasEmpty = sigCanvas.current?.isEmpty();

      if (isCanvasEmpty) {
        return toast.error("Please provide a signature.");
      }

      const file = dataURLtoFile(dataUrl, "signature.png");

      const formData = new FormData();
      formData.append("type", "savedProject");
      formData.append("file_upload", file);

      await fetchData({
        ...BackendApis.uploadImages,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      });
    } catch (error) {
      console.error(error);
    }
    handleCancel();
  };

  const getTotal = (quantity: number, priceB2c: number) => {
    return quantity * priceB2c;
  };

  const handleConditionChange = (event: any) => {
    setSelectedCondition(event.target.value);
  };
  const clear = () => sigCanvas.current.clear();
  return (
    <RealizationModalWrapper>
      <PopupSkeleton open={true} handleClose={handleCancel} containerRef={boxRef}>
        <StyledContentDetails>
          <StyledContentHeading>
            <StyledContentTitle style={{ textAlign: "center" }}>{t("Offering")}</StyledContentTitle>
          </StyledContentHeading>
          <StyledContentMiddle>
            <Table dataSource={selectedProducts} pagination={false}>
              <ColumnGroup title={t("Hardware pricing strategy")} align="left">
                <Column title="Product" dataIndex="name" key="name" />
                <Column title="Type" dataIndex="type" key="type" />
                {/* <Column title="Description" dataIndex="longDescription" key="longDescription" /> */}
                <Column title="Quantity" dataIndex="quantity" key="quantity" />
                <Column title="Price B2B" dataIndex="priceB2b" key="priceB2b" />
                <Column title="Price B2C" dataIndex="priceB2c" key="priceB2c" />
                <Column
                  title="Total"
                  dataIndex="tags"
                  key="tags"
                  render={(_, record: any) => (
                    <span>{getTotal(record.quantity, record.priceB2c)}</span>
                  )}
                />
              </ColumnGroup>
            </Table>
            <StyledHR />
            <TotalBox>
              <div>{t("Subtotal")}</div>
              <div key={"news"}>€ {formatNumber(selectedProductsTotal?.toFixed(2) || 0)}</div>
            </TotalBox>
            <Table dataSource={displayedConstants}>
              <ColumnGroup title={t("Pricing for Installation & Registration")} align="left">
                <Column title="Title" dataIndex="title" key="title" />
                <Column title="Type" dataIndex="type" key="type" />
                <Column title="Price B2B" dataIndex="priceB2b" key="priceB2b" />
                <Column title="Price B2C" dataIndex="priceB2c" key="priceB2c" />
              </ColumnGroup>
            </Table>
            <StyledHR />
            <TotalBox>
              <div>{t("Subtotal")}</div>
              <div>€ {formatNumber(selectedConstantsTotal?.toFixed(2) || 0)}</div>
            </TotalBox>
            <HrWrapper>
              <StyledHR />
              <StyledHR />
            </HrWrapper>

            <BottomContentWrapper>
              <FinalOptionsWrapper>
                <TextArea
                  maxLength={100}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={t("Description")}
                  style={{ height: 120, resize: "none", borderRadius: 4 }}
                />

                <Select
                  value={selectedCondition}
                  onChange={handleConditionChange}
                  placeholder={t("Buying conditions")}
                  style={{ zIndex: 1000 }}
                >
                  <MenuItem value="Zahlungsbedingungen">{t("Zahlungsbedingungen")}</MenuItem>
                  <MenuItem value="Garantie und Wartung">{t("Garantie und Wartung")}</MenuItem>
                  <MenuItem value="Lieferung und Installation">
                    {t("Lieferung und Installation")}
                  </MenuItem>
                  <MenuItem value="Rückgabe und Erstattung">
                    {t("Rückgabe und Erstattung")}
                  </MenuItem>
                  <MenuItem value="Rabatte und Aktionen">{t("Rabatte und Aktionen")}</MenuItem>
                  <MenuItem value="Service Level Agreements (SLAs)">
                    {t("Service Level Agreements (SLAs)")}
                  </MenuItem>
                  <MenuItem value="Vertragliche Verpflichtungen">
                    {t("Vertragliche Verpflichtungen")}
                  </MenuItem>
                </Select>

                <Accordion onChange={(e, expanded) => handleAccordionChange(expanded)}>
                  <AccordionSummary
                    expandIcon={<DownOutlined />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>{t("Add realization time")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="container-calender">23 june to 13 july</div>
                  </AccordionDetails>
                </Accordion>
              </FinalOptionsWrapper>
              <div className="FinalOfferCalculation">
                <FinalOfferCalculation
                  t={t}
                  total={selectedConstantsTotal + selectedProductsTotal}
                  setDiscount={setDiscount}
                  discount={discount}
                />
                <div className="signatureCanvas">
                  <SignatureCanvas
                    penColor="green"
                    canvasProps={{ width: "100%", height: 140, className: "sigCanvas" }}
                    ref={sigCanvas}
                  />
                  <button style={{ paddingLeft: 5 }} onClick={clear}>
                    Clear
                  </button>
                </div>
              </div>
            </BottomContentWrapper>
          </StyledContentMiddle>
          <StyledContentBottom>
            <StyledButton loading={isLoading} color="#2D4764" onClick={handleCustomerOffer}>
              {t("Accept offer")}
            </StyledButton>
            <StyledButton loading={isLoading} color="#2D4764" onClick={handleRejectOffer}>
              {t("Reject offer")}
            </StyledButton>
          </StyledContentBottom>
        </StyledContentDetails>
      </PopupSkeleton>
    </RealizationModalWrapper>
  );
};

export default DetailedOffer;
