import { Slider } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import * as ParameterStyles from "src/shared/StyledComponents/Parameters";
import { isQuotePresent } from "src/utils";

import { ProjectActions } from "src/redux/actionCreators";
import { QuoteProfileName } from "src/redux/project";
import { getProject } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";

interface SolarModuleInputProps {
  openSolarMessageWindow: () => void;
}

const TAB_KEYS: Record<string, QuoteProfileName> = {
  ECO: "eco",
  SMART: "smart",
  GREEN: "green",
};

const SolarModuleInput: React.FC<SolarModuleInputProps> = (props: SolarModuleInputProps) => {
  const { openSolarMessageWindow } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quote = useSelector(ProjectSelectors.getQuote);
  const quoteProfiles = useSelector(ProjectSelectors.getQuoteProfile);

  let { quantity } = useSelector(ProjectSelectors.getSolarPanel);
  const greenQuoteModuleQty = useMemo(()=>quote.profiles.green.moduleQuantity,[quote.profiles.green.moduleQuantity]);

  const [maximumPanel, setMaximumPanel] = useState(71);

  const imgURLs: any = useMemo(() => {
    return quoteProfiles.imgURLs.find((img) => img.includes(".obj"));
  }, [quoteProfiles.imgURLs]);
  const adminGeneratedBakeModelUrls = useSelector(ProjectSelectors.getAdminGeneratedBakeModelUrls);
  const project = useSelector(getProject);
  const selectedInstallers = useSelector(ProjectSelectors.getSelectedInstaller);
  const installerUploadedObj = selectedInstallers[0]?.files?.find((a) => a.includes(".obj"));
  let sliderDisabled = false;

  if (
    imgURLs ||
    adminGeneratedBakeModelUrls ||
    project.bakeModel?.artifacts?.length ||
    installerUploadedObj ||
    project.googleModel
  ) {
    sliderDisabled = true;
    quantity = quote.profiles.green.moduleQuantity;
  }

  const updateQuantityIntoRedux = (ecoQuantity: number, smartQuantity: number): void => {
    if(quantity <= ecoQuantity) {
      dispatch(
        ProjectActions.updateOuterDetails({
          profile: TAB_KEYS.ECO,
        }),
      );
    } else if(quantity <= (smartQuantity) && quantity >= (ecoQuantity)) {
      dispatch(
        ProjectActions.updateOuterDetails({
          profile: TAB_KEYS.SMART,
        }),
      );
    } else {
      dispatch(
        ProjectActions.updateOuterDetails({
          profile: TAB_KEYS.GREEN,
        }),
      );
    }
  }

  const solarSliderChangeHandler = (value: number): void => {
    const adjustedValue = Math.max(6, value);
    openSolarMessageWindow();
    const action = ProjectActions.updateSolarPanel({
      quantity: adjustedValue,
    });
    dispatch(action);

    if(quote.profiles.smart.imgURLs.length > 1 || quote.profiles.eco.imgURLs.length > 1) {
      const smartQuantity = quote.profiles.smart.moduleQuantity;
      const ecoQuantity = quote.profiles.eco.moduleQuantity;
      if(value <= ecoQuantity) {
        updateQuantityIntoRedux(ecoQuantity, smartQuantity)
      }
      return;
    }
    const smartQuantity = greenQuoteModuleQty / 2;
    const ecoQuantity = greenQuoteModuleQty / 3;
    updateQuantityIntoRedux(ecoQuantity, smartQuantity);
  };

  useEffect(() => {
    if (isQuotePresent(quote)) {
      const greenQuoteModuleQty = quote.profiles.green.moduleQuantity;
      setMaximumPanel(greenQuoteModuleQty);
    }
  }, [dispatch, quote]);

  return (
    <ParameterStyles.Line hasSlider isPadding>
      <ParameterStyles.Label hasSlider bold>
        <div>{t("SOLAR MODULES")}</div> 
      </ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          defaultValue={Math.round(quantity)}
          onChange={solarSliderChangeHandler}
          value={Math.round(quantity)}
          max={Math.round(quote.profiles.green.moduleQuantity)}
          disabled={sliderDisabled}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>{Math.round(quantity)}</ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default SolarModuleInput;
