import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useIsComponentAdded } from "src/hooks";

import { ProductDB } from "src/db-types";
import useAddDeleteComponent from "src/hooks/useAddDeleteComponent";
import { assignInvertersToGrids } from "src/utils";

import InverterSelection from "./configuration/InverterSelection";
import ModuleSelection from "./configuration/ModuleSelection";
import OverviewSelection from "./configuration/OverviewSelection";
import ProductSection from "./configuration/ProductSection";

import { PopupSelectors, ProjectSelectors } from "src/redux/selectors";

interface SolarConfigurationType {
  menu: Array<{
    label: string;
    isActive: boolean;
    isDisable: boolean;
    icon: JSX.Element;
  }>;
  onActiveMenuItem: (label: string) => void;
  onClickOfAutoStrings: () => boolean;
  selectedProducts: {
    inverters: Array<ProductDB | null>;
    interterOutput: number;
    solarPanel: ProductDB | null;
  };
  cablingLoops: any[];
  setCablingLoops: (_: any[]) => void;
  activeGridChanged: any;
  documentRef: any;
  setSolarDataReload: any;
  setHistoryCablingPolygons: (_: any[]) => void;
  setShowSelectionMenu: any;
}

export default function SolarConfiguration({
  menu,
  setCablingLoops,
  cablingLoops,
  selectedProducts,
  onActiveMenuItem,
  onClickOfAutoStrings,
  activeGridChanged,
  documentRef,
  setSolarDataReload,
  setHistoryCablingPolygons,
  setShowSelectionMenu,
}: SolarConfigurationType): JSX.Element {
  const overviewItem = menu.find((item) => item.label === "Overview");
  const modulesItem = menu.find((item) => item.label === "Modules");
  const inverterItem = menu.find((item) => item.label === "Inverter");
  const batteryItem = menu.find((item) => item.label === "Battery");
  const carItem = menu.find((item) => item.label === "Wallbox");
  const heatpumpItem = menu.find((item) => item.label === "Heatpump");
  const emsItem = menu.find((item) => item.label === "EMS");
  const inverterProduct = useSelector(ProjectSelectors.getInverter);
  const batteryProduct = useSelector(ProjectSelectors.getBattery);
  const heatPumpProduct = useSelector(ProjectSelectors.getHeatpump);
  const EMSProduct = useSelector(ProjectSelectors.getProductEMS);
  const wallBoxProduct = useSelector(ProjectSelectors.getWallbox);
  const {
    addBatteryHandler,
    addHeatpump,
    addWallbox,
    addEMS,
    deleteBattery,
    deleteHeatpump,
    deleteWallbox,
    deleteEMS
  } = useAddDeleteComponent();
  const { isBatteryAdded, isWallboxAdded, isHeatpumpAdded, isEMSAdded } =
    useIsComponentAdded();

  const {
    data: { standalone },
  } = useSelector(PopupSelectors.getProductPopup);

  useEffect(() => {
    const noIverterAssigned = cablingLoops.some((loop) => !loop[0]?.inverter);
    if (noIverterAssigned) {
      const newGrids = assignInvertersToGrids(
        cablingLoops,
        inverterProduct.filter((inv) => inv.item !== null).map((inv) => inv.item as ProductDB),
      );
      setCablingLoops(newGrids.assignments ?? []);
      setHistoryCablingPolygons(newGrids.assignments ?? []);
    }
  }, [inverterProduct]);

  return (
    <div className="flex bg-gray-50 w-full"  onClick={(e) => e.stopPropagation()} >
      <div className="w-[91px] bg-[#EFEFEF] shadow-sm flex flex-col items-center py-3">
        {menu.map((item) => (
          <NavItem
            onClick={onActiveMenuItem}
            icon={<>{item.icon}</>}
            key={item.label}
            label={item.label}
            active={item.isActive}
            isDisable={item.isDisable}
          />
        ))}
      </div>
      <div className="min-w-[24rem] flex flex-col gap-4 p-2 bg-white overflow-auto min-h-[500px] max-h-[508px]">
      {modulesItem?.isActive && (
        <ModuleSelection
          activeGridChanged={activeGridChanged}
          documentRef={documentRef}
          setSolarDataReload={setSolarDataReload}
          setShowSelectionMenu={setShowSelectionMenu}
        />
      )}
      {overviewItem?.isActive && <OverviewSelection documentRef={documentRef}/>}
      {inverterItem?.isActive && (
        <InverterSelection
          cablingLoops={cablingLoops}
          setCablingLoops={setCablingLoops}
          selectedProducts={selectedProducts}
          documentRef={documentRef}
          onClickOfAutoStrings={onClickOfAutoStrings}
          setHistoryCablingPolygons={setHistoryCablingPolygons}
        />
      )}
      {batteryItem?.isActive && (
        <ProductSection
          productName="battery"
          item={batteryProduct.item}
          onAddProduct={addBatteryHandler}
          onDeleteClick={deleteBattery}
          isItemAdded={isBatteryAdded}
          standalone={standalone}
        />
      )}
      {carItem?.isActive && (
        <ProductSection
          productName="wallbox"
          item={wallBoxProduct.item}
          onAddProduct={addWallbox}
          onDeleteClick={deleteWallbox}
          isItemAdded={isWallboxAdded}
          standalone={standalone}
        />
      )}
      {heatpumpItem?.isActive && (
        <ProductSection
          productName="heatpump"
          item={heatPumpProduct.item}
          onAddProduct={addHeatpump}
          onDeleteClick={deleteHeatpump}
          isItemAdded={isHeatpumpAdded}
          standalone={standalone}
        />
      )}
      {emsItem?.isActive && (
        <ProductSection
          productName="EMS"
          item={EMSProduct.item}
          onAddProduct={addEMS}
          onDeleteClick={deleteEMS}
          isItemAdded={isEMSAdded}
          standalone={standalone}
        />
      )}
      </div>
     
    </div>
  );
}

function NavItem({
  icon,
  label,
  active,
  onClick,
  isDisable,
}: {
  icon: React.ReactNode;
  label: string;
  active?: boolean;
  onClick: (label: string) => void;
  isDisable: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <div
      className={`flex flex-col items-center gap-1 ${active ? "bg-white" : ""} hover:bg-white w-full pt-1 pb-1 ${
        isDisable ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      aria-disabled={isDisable}
      onClick={() => onClick(label)}
    >
      <div
        className={`w-[28px] h-[28px] rounded-full flex items-center justify-center ${"bg-[#FFD66B]"}  ${
          isDisable ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <div className={"text-[#2B3674]"}>{icon}</div>
      </div>
      <span className="text-xs text-[#2B3674]">{t(label)}</span>
    </div>
  );
}
