import { Elements } from "@stripe/react-stripe-js";
import { Suspense, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { AppPage } from "./layout";
import ObjViewer from "./ObjViewer";
import { ApplicationPage, UserOverview } from "./pages";
import { ApplicationLoader } from "./pages/ApplicationPage/components";
import { Payment } from "./pages/Payment/Payment";
import { stripeLoader } from "./utils";
// import ApplicationLoader from "src/skeleton-loaders/ApplicationLoader";
// import { isOnProduction } from "src/utils";

// import { promise } from "./utils/utilsfunc";

// const Utilities = lazy(async () => await import("src/utils/utilities"));
// const FeedBack = lazy(async () => await import("src/pages/feedback/FeedBack"));
// const Payment = lazy(
//   async () => await import("src/pages/application/applicationSteps/step4/payment/Payment"),
// );
// const CommunityRoutes = lazy(async () => await import("src/components/community/partials/routes"));
// const Application = lazy(async () => await import("src/pages/application/ApplicationPage"));
// const UserOverView = lazy(async () => await import("src/components/useroverview/UserOverView"));

const Routers: React.FC = () => {
  const defaultLoadingDiv = useMemo(() => {
    return <div>Loading...</div>;
  }, []);

  // const utilityComponent = useMemo(
  //   () => (
  //     <Suspense fallback={defaultLoadingDiv}>
  //       <Utilities />
  //     </Suspense>
  //   ),
  //   [defaultLoadingDiv],
  // );

  // const communityComponent = useMemo(
  //   () => (
  //     <Suspense fallback={defaultLoadingDiv}>
  //       <CommunityRoutes />
  //     </Suspense>
  //   ),
  //   [defaultLoadingDiv],
  // );

  const userOverviewComponent = useMemo(
    () => (
      <Suspense fallback={defaultLoadingDiv}>
        <UserOverview />
      </Suspense>
    ),
    [defaultLoadingDiv],
  );

  // const feedbackComponent = useMemo(
  //   () => (
  //     <Suspense fallback={defaultLoadingDiv}>
  //       <FeedBack />
  //     </Suspense>
  //   ),
  //   [defaultLoadingDiv],
  // );

  const paymentComponent = useMemo(
    () => (
      <Elements stripe={stripeLoader}>
        <Suspense fallback={<ApplicationLoader />}>
          <Payment />
        </Suspense>
      </Elements>
    ),
    [],
  );

  const applicationComponent = useMemo(
    () => (
      <Elements stripe={stripeLoader}>
        <Suspense fallback={<ApplicationLoader />}>
          <ApplicationPage />
        </Suspense>
      </Elements>
    ),
    [],
  );



  return (
    <>
      <Routes>
        <Route element={<AppPage />}>
          <Route path="/useroverview/:projectId" element={userOverviewComponent} />
          <Route path="/application/*" element={applicationComponent} />
          <Route path="/obj-viewer/*" element={<ObjViewer />} />
        </Route>
        {/* <Route path="/community/*" element={communityComponent}></Route> */}
        {/* <Route path="/feedback/:projectId" element={feedbackComponent} /> */}
        {/* <Route path="/userdashboard" element={<Elements stripe={promise}></Elements>} /> */}
        <Route path="/payment" element={paymentComponent} />
        <Route path="/" element={<Navigate replace to="/application" />} />
      </Routes>
    </>
  );
};

export default Routers;
