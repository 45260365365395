import { Button } from "antd";
import { ChevronRight, Repeat } from "lucide-react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useNumberFormat } from "src/hooks";

import { ProductDB, ProductWithPrice } from "src/db-types";
import { ProductPopupProps } from "src/popups";

import { ReactComponent as Bulb } from "../../assets/configuration/Bulb.svg";

import AutoStringInput from "./AutoStringInput";

import { PopupActions, ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";
import { getInverters } from "src/redux/products/selector";

interface InverterSelectionType {
  onClickOfAutoStrings: () => boolean;
  selectedProducts: {
    inverters: Array<ProductDB | null>;
    interterOutput: number;
    solarPanel: ProductDB | null ;
  };
  cablingLoops: any[];
  setCablingLoops: any;
  documentRef: any;
  setHistoryCablingPolygons: any;
}
interface CablingLoop {
  color: string;
  name: string;
}
type OnEditFunction = (groupId: number, name: string) => void;

const InverterSelection: React.FC<InverterSelectionType> = ({
  onClickOfAutoStrings,
  cablingLoops,
  selectedProducts,
  setCablingLoops,
  documentRef,
  setHistoryCablingPolygons
}) => {
  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();
  const dispatch = useDispatch();
  const obj: any = {};
  selectedProducts?.inverters?.forEach((inverter: any) => {
    obj[inverter?._id] = true;
  });
  const [expandedInverters, setExpandedInverters] = useState<any>(obj);
  
  const organizedData: any = {};
  cablingLoops.forEach(group => {
    group.forEach((item: any) => {
      const inverterId = item.inverter;
      const name = item.name;
      
      if (!organizedData[inverterId]) {
        organizedData[inverterId] = [];
      }

      let subArray = organizedData[inverterId].find((arr: any) => arr[0]?.name === name);
      if (!subArray) {
        subArray = [];
        organizedData[inverterId].push(subArray);
      }

      subArray.push(item);
    });
  });

  const toggleExpand = (id: string) => {
    setExpandedInverters((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const inverters = useSelector(getInverters).filter((inverter) => inverter.instock);

  const onEdit: OnEditFunction = (groupId, name) => {
    setCablingLoops((loops: CablingLoop[][]) => {
      return loops.map((loop, index) => {
        return loop.map((lp: CablingLoop) => {
          if (index === groupId) {
            lp.name = name;
          }
          return lp;
        });
      });
    });
    setHistoryCablingPolygons((prevLoops: any) => {
      return prevLoops.map((loop: any, index: any) => {
        return loop.map((lp: any) => {
          if (index === groupId) {
            lp.name = name;
          }
          return lp;
        });
      });
    });
  };
  const maxDcVoltage = useMemo(() => {
    if (!selectedProducts?.solarPanel) return 0;

    const { VOC_STC, T_COEFFICIENT, T_MIN, T_STC } = selectedProducts.solarPanel as ProductWithPrice;
    const VOC_ADJUSTED = VOC_STC * (1 + T_COEFFICIENT * (T_MIN - T_STC));

    const gridWisePlacement: Record<number, number> = {};

    cablingLoops.forEach((loop) => {
      documentRef.current.panelGrids.forEach((ele: any, gridIndex: number) => {
        ele.children.slice(1).forEach((e: any) => {
          if (e.name === "supported" && e.uuid === loop[0]?.panel?.uuid) {
            gridWisePlacement[gridIndex] = Math.max(gridWisePlacement[gridIndex] || 0, loop.length);
          }
        });
      });
    });

    return Object.values(gridWisePlacement).reduce((totalVoltage, connectedPanels) => {
      return totalVoltage + VOC_ADJUSTED * connectedPanels;
    }, 0);
  }, [cablingLoops, documentRef, selectedProducts?.solarPanel]);

  const maxDcCurrent = useMemo(() => {
    if (!selectedProducts?.solarPanel) return 0;

    const { I_SC } = selectedProducts.solarPanel as ProductWithPrice;
    const gridWisePlacement: Record<number, number> = {};

    cablingLoops.forEach((loop) => {
      documentRef.current.panelGrids.forEach((ele: any, gridIndex: number) => {
        ele.children.slice(1).forEach((e: any) => {
          if (e.name === "supported" && e.uuid === loop[0]?.panel?.uuid) {
            gridWisePlacement[gridIndex] = (gridWisePlacement[gridIndex] || 0) + 1;
          }
        });
      });
    });

    const connectedPanelsToString = Object.values(gridWisePlacement).reduce(
      (total, connectedPanels) => total + connectedPanels,
      0
    );

    return I_SC * connectedPanelsToString * 1.25;
  }, [cablingLoops, documentRef, selectedProducts?.solarPanel]);

  const chooseOptimalInverter = (): void => {
    if (!cablingLoops.length) {
      const isSuccess = onClickOfAutoStrings();
      if (!isSuccess) return;
    }

    const isFisibleInverter = inverters
      .filter(
        (inverter) =>
          inverter.maxDCInputVoltage >= maxDcVoltage && inverter.maxDCCurrent >= maxDcCurrent,
      )
      .sort((a, b) => a.maxDCInputVoltage - b.maxDCInputVoltage);
    if (isFisibleInverter.length > 0) {
      dispatch(
        ProjectActions.updateInverter({
          item: isFisibleInverter[0],
        }),
      );
    } else {
      toast.warn(t("No inverter available for this configuration"));
    }
  };

  const warning = useMemo(() => {
    // Need to fix static
    if(!selectedProducts.inverters?.[0]) return null;
    const maxDCInputVoltage = selectedProducts?.inverters[0]?.maxDCInputVoltage;
    const maxDCInputCurrent = selectedProducts?.inverters[0]?.maxDCCurrent;
    if (maxDcVoltage >= maxDCInputVoltage || maxDcCurrent >= maxDCInputCurrent) {
      return (
        <div className="warning-message">
          <p className="warning-text">⚠️ Warning:</p>
          <ul className="warning-details list-disc pl-4">
            {maxDcVoltage >= maxDCInputVoltage && (
              <li>
                Max DC Voltage ({formatNumber(maxDcVoltage.toFixed(2))}V) exceeds inverter limit (
                {maxDCInputVoltage}V).
              </li>
            )}
            {maxDcCurrent >= maxDCInputCurrent && (
              <li>
                Max DC Current ({formatNumber(maxDcCurrent.toFixed(2))}A) exceeds inverter limit (
                {maxDCInputCurrent}A).
              </li>
            )}
          </ul>
        </div>
      );
    }
    return null;
  }, [formatNumber, maxDcCurrent, maxDcVoltage, selectedProducts.inverters]);

  const onPopupOpen = (maxVoltage: any, selectedInverter: string) => {
    dispatch(
      openPopup(POPUP_KEYS.inverter_auto_string_details, {
        selectedProducts: {
          inverter: selectedProducts.inverters.find((inverter) => inverter?._id === selectedInverter),
          interterOutput: selectedProducts.interterOutput,
          solarPanel: selectedProducts.solarPanel
        },
        maxDcVoltage,
        maxDcCurrent,
        cablingLoops: organizedData?.[selectedInverter],
        onEdit,
        maxVoltage,
        numberOfInverter: selectedProducts?.inverters?.length || 0,
        numberOfStrings: cablingLoops?.length || 0
      })
    )
  }

  const openProductPopup = useCallback(() => {
    const data: ProductPopupProps = {
      type: "inverter",
      onSelectCallback: (prod) => {
        dispatch(
          ProjectActions.updateInverter({
            item: prod,
          }),
        );
      },
    };
    dispatch(PopupActions.openPopup(POPUP_KEYS.product, data));
  }, [dispatch]);
  console.log({ organizedData })

  return (
    <div className="flex-1 p-2 bg-white max-w-[350px]">
      <div className="mb-6">
        {
          warning !== null && (
            <Button
            onClick={chooseOptimalInverter}
            className="w-auto text-sm max-w-md bg-[#FFD66B] hover:bg-[#FFD66B]/90 text-[#2B3674] h-[38px] rounded-full flex justify-center items-center gap-2"
          >
            <Bulb />
            {t("Choose optimal inverter")}
          </Button>
          )
        }
      </div>
      {warning}
      <div className="space-y-4">
        {selectedProducts?.inverters?.map((inverter) => inverter && (
          <div className="bg-white p-2 rounded-lg" key={inverter._id}>
            <div className="flex items-center gap-2 mb-4">
              <ChevronRight 
                className={`w-5 h-5 text-[#2B3674] ${expandedInverters[inverter._id] && "rotate-90"}`} 
                onClick={() => toggleExpand(inverter._id)}
              />
              <h2 className="text-lg font-medium text-[#2B3674]">
                {inverter?.name}
              </h2>
              <Repeat className="w-5 h-5 text-[#2B3674] cursor-pointer" onClick={openProductPopup}/>
              {/* <span className="bg-[#FFD66B] px-2 py-0.5 rounded-full text-sm">{90}%</span> */}
            </div>

            {expandedInverters[inverter._id] &&
              <div className="ml-6 space-y-2 text-[#2B3674]">
                {
                  organizedData?.[inverter._id]?.reduce((curr: any, prev: any) => curr + prev?.length, 0) && (
                    <p>
                    {organizedData?.[inverter._id]?.reduce((curr: any, prev: any) => curr + prev?.length, 0)} * {selectedProducts.solarPanel?.applicableMaxCapacity} Watt ={" "}
                  {((organizedData?.[inverter._id]?.reduce((curr: any, prev: any) => curr + prev?.length, 0) * (selectedProducts.solarPanel?.applicableMaxCapacity ?? 1))/1000).toFixed(2)} kWP
                </p>
                  )
                }
                {!!maxDcVoltage && (
                  <p>
                    {t("Maximum voltage")} (Voc): {maxDcVoltage.toFixed(2)} V
                  </p>
                )}
                {!isNaN(maxDcCurrent) && !!maxDcCurrent && (
                  <p>
                    {t("Maximum current")} (Isc): {maxDcCurrent.toFixed(2)} A
                  </p>
                )}
                {inverter?.maxOutputPower && (
                  <p>
                    {t("Power")} {inverter?.maxOutputPower} kWp
                  </p>
                )}
                <div className="mt-4">
                  {organizedData?.[inverter._id]?.map((loop: any, index: number) => (
                    <AutoStringInput
                      loop={loop}
                      onEdit={onEdit}
                      key={index}
                      index={index}
                      selectedProducts={{
                        interterOutput: selectedProducts.interterOutput, solarPanel: selectedProducts.solarPanel, inverter
                      }}
                      onPopupOpen={onPopupOpen}
                    />
                  ))}
                </div>
              </div>
            }
          </div>
        ))}
        {/* <Button className="w-1/2 max-w-md bg-[#FFD66B] hover:bg-[#FFD66B]/90 text-[#2B3674] h-12 rounded-full flex justify-center items-center gap-2">
          <Plus className="w-5 h-5 mr-2" />
          Add Inverter
        </Button> */}

        <Button
          onClick={onClickOfAutoStrings}
          className="w-1/2 max-w-md bg-[#FFD66B] hover:bg-[#FFD66B]/90 text-[#2B3674] hover:text-[#2B3674] hover:border-transparent h-10 rounded-full flex justify-center items-center gap-2"
        >
          <Plus className="w-5 h-5 mr-2" />
          {t("Auto-String")}
        </Button>
      </div>
    </div>
  );
};

function Plus(props: React.ComponentProps<"svg">) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 5v14" />
      <path d="M5 12h14" />
    </svg>
  );
}

export default InverterSelection;
