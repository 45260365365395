import { clone } from "lodash";

import { actionCreators, ProjectState } from "src/redux/project";

export const addUserInfo = (
  currentState: ProjectState,
  { payload }: ReturnType<typeof actionCreators.addUserInfo>,
): ProjectState => {
  const userInfo = clone(payload);
  
  return {
    ...currentState,
    userInfo,
  };
};
