import { useSelector } from "react-redux";

import { ProductFilters, useAppDispatch } from "src/hooks";

import { ProductDB, ProductWithPrice } from "src/db-types";
import { PopupSkeleton, ProductCard } from "src/shared/components";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { ProjectComponents } from "src/redux/project";
import { FilterableBaseComponent } from "src/redux/project/types/components";
import { ComponentCosts } from "src/redux/project/types/financial-details";
import { PopupSelectors } from "src/redux/selectors";

export interface ProductPopupProps {
  type: Exclude<keyof ProjectComponents, "waterHeating" | "cars">;
  onSelectCallback?: (prod: ProductDB) => void;
  standalone?: {
    filteredProducts: ProductWithPrice[];
    productState: FilterableBaseComponent;
    componentCosts: ComponentCosts;
    onPreferenceChange: (filters: ProductFilters) => void;
    isBatteryAdded?: boolean;
  };
  item?: ProductDB | null;
}

export const ProductPopup: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    data: { type: productType, standalone, item },
  } = useSelector(PopupSelectors.getProductPopup);

  const handleClose = (): void => {
    dispatch(PopupActions.closePopup(POPUP_KEYS.product));
  };

  return (
    <PopupSkeleton
      handleClose={handleClose}
      open={true}
      styless={{ height: "auto", minWidth: "min(90vw, 1000px)" }}
    >
      <div style={{ marginTop: "3rem" }}>
        <ProductCard standalone={standalone} productName={productType} item={item} />
      </div>
    </PopupSkeleton>
  );
};

export default ProductPopup;
