import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useNumberFormat } from "src/hooks/useNumberFormat";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { getProject } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";

const ProductionPerYearLine: React.FC = () => {
  const { t } = useTranslation();

  let production = useSelector(ProjectSelectors.getEnergy).solarPanelProduction;

  const { formatNumber } = useNumberFormat();

  const quote = useSelector(ProjectSelectors.getQuote);
  const quoteProfiles = useSelector(ProjectSelectors.getQuoteProfile);
  const imgURLs: any = useMemo(() => {
    return quoteProfiles.imgURLs.find((img) => img.includes(".obj"));
  }, [quoteProfiles.imgURLs]);
  const adminGeneratedBakeModelUrls = useSelector(ProjectSelectors.getAdminGeneratedBakeModelUrls);
  const project = useSelector(getProject);
  const selectedInstallers = useSelector(ProjectSelectors.getSelectedInstaller);
  const installerUploadedObj = selectedInstallers[0]?.files?.find((a) => a.includes(".obj"));

  if (
    imgURLs ||
    adminGeneratedBakeModelUrls ||
    project.bakeModel?.artifacts?.length ||
    installerUploadedObj ||
    project.googleModel
  ) {
    production = quote.profiles.green.annualSolarGeneration;
  }

  return (
    <ParameterStyles.Line isPadding>
      <ParameterStyles.Label bold>
        <div>{t("PRODUCTION PER YEAR")}</div>
      </ParameterStyles.Label>
      <ParameterStyles.Value bold>{formatNumber(production)} kWh</ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default ProductionPerYearLine;
