import { Battery, Car, Home, Power, Settings, Sun } from "lucide-react";
import React, { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useIsComponentAdded, useNumberFormat } from "src/hooks";

import { BatteryButtonIcon, WallboxButtonIcon } from "src/assets/svgs";
import { ProductDB } from "src/db-types";
import { getAverageCapacity, getAverageMaxCapacity, getCheapestProduct } from "src/helpers";
import { calculatePanelCenter, getSurfacePosition } from "src/PanelPlacement/Cabeling";
import getPanelData, { getDirectionFromAzimuth } from "src/PanelPlacement/PopUp/getPanelData";
import { assignInvertersToGrids, calculateOptimalCabling, findOptimalInverter } from "src/utils";

import { ReactComponent as Edit } from "../assets/EditIcon.svg";
import { ReactComponent as PowerButton } from "../assets/PowerButton.svg";
import { ReactComponent as Solarpanel } from "../assets/Solarpanel.svg";

import SolarConfiguration from "./SelectedConfiguration";

import { ProjectActions } from "src/redux/actionCreators";
import { getInverters } from "src/redux/products/selector";
import { updateProject } from "src/redux/project/action/action.creators";
import { getProject } from "src/redux/project/selectors";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

const ArrowIcon = ({ open, setOpen }: { open: boolean; setOpen: (_: boolean) => void }) => (
  <img
    src={
      open
        ? "/images/application_step_3/arrowLeftWithoutBorder.svg"
        : "/images/application_step_3/arrowDown.svg"
    }
    className="mb-1 hover:cursor-pointer"
    onClick={(e) => {
      e.stopPropagation();
      setOpen(!open);
    }}
    style={{ zIndex: "99999", position: "absolute", top: "34px", right: "-13px" }}
    alt=""
  />
);

const menuItems = [
  {
    label: "Overview",
    isActive: false,
    icon: <Power className="w-[18px] h-[18px]" />,
    isDisable: false,
  },
  {
    label: "Modules",
    isActive: false,
    icon: <Sun className="w-[18px] h-[18px]" />,
    isDisable: false,
  },
  {
    label: "Inverter",
    isActive: true,
    icon: <Settings className="w-[18px] h-[18px]" />,
    isDisable: false,
  },
  {
    label: "Battery",
    isActive: false,
    icon: <Battery className="w-[18px] h-[18px]" />,
    isDisable: false,
  },
  {
    label: "Wallbox",
    isActive: false,
    icon: <Car className="w-[18px] h-[18px]" />,
    isDisable: false,
  },
  {
    label: "Heatpump",
    isActive: false,
    icon: <Home className="w-[18px] h-[18px]" />,
    isDisable: false,
  },
  {
    label: "EMS",
    isActive: false,
    icon: <WallboxButtonIcon />,
    isDisable: false,
  },
];

const SystemConfiguration = ({
  solardata,
  documentRef,
  cablingLoops,
  getCableOnInit,
  setCablingLoops,
  activeGridChanged,
  setSolarDataReload,
  setHistoryCablingPolygons,
  setShowSelectionMenu,
}: {
  solardata: any[];
  getCableOnInit: (panelGrids: [], cableGroups: any[]) => void;
  documentRef: any;
  cablingLoops: any[];
  setCablingLoops: (_: any[]) => void;
  activeGridChanged: any;
  setSolarDataReload: any;
  setHistoryCablingPolygons: (_: any[]) => void;
  setShowSelectionMenu: any;
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(menuItems);
  const filteredSolarPanels = useSelector(ProductSelectors.getFilteredSolarPanels);
  const filteredInterters = useSelector(ProductSelectors.getFilteredInverters);
  const filteredBatteries = useSelector(ProductSelectors.getFilteredBatteries);
  const { quantity } = useSelector(ProjectSelectors.getSolarPanel);
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const inverterProduct = useSelector(ProjectSelectors.getInverter);
  const batterProduct = useSelector(ProjectSelectors.getBattery);
  const { isBatteryAdded } = useIsComponentAdded();
  const { formatNumber } = useNumberFormat();
  const dispatch = useDispatch();
  const inverters = useSelector(getInverters);
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const project = useSelector(getProject);
  const [configName, setConfigName] = useState<any>(
    project?.projectName || t("Configuration name"),
  );

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfigName(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      dispatch(
        ProjectActions.updateProject({
          projectName: configName,
        }),
      );
      setIsEditing(false);
    }
  };

  const cheapestProduct = useMemo(() => {
    return getCheapestProduct(filteredInterters);
  }, [filteredInterters]);

  const cheapestSolarpanels = useMemo(() => {
    return getCheapestProduct(filteredSolarPanels);
  }, [filteredSolarPanels]);

  const onActiveMenuItem = (label: string) => {
    setMenu((prevMenu) =>
      prevMenu.map((m) => {
        if (m.label === label) {
          return { ...m, isActive: true };
        }
        return { ...m, isActive: false };
      }),
    );
  };
  const capacity = useMemo(() => {
    return getAverageCapacity(filteredSolarPanels);
  }, [filteredSolarPanels]);
  const inverterCap = useMemo(() => {
    return getAverageMaxCapacity(filteredInterters);
  }, [filteredInterters]);

  const batteryCap = useMemo(() => {
    return getAverageMaxCapacity(filteredBatteries);
  }, [filteredBatteries]);

  let systemCapacity = (capacity * quantity) / 1000;
  if (solarPanelProduct?.item) {
    systemCapacity = (Number(solarPanelProduct?.item.capacity) * quantity) / 1000;
  }

  let interterOutput = inverterCap / 1000;
  if (Array.isArray(inverterProduct)) {
    interterOutput = inverterProduct.reduce((sum, product) => {
      return sum + (Number(product?.item?.applicableMaxCapacity) || 0) / 1000;
    }, 0);
  }

  let batteryOutput = batteryCap / 1000;
  if (batterProduct?.item) {
    batteryOutput = Number(batterProduct?.item.applicableMaxCapacity) / 1000;
  }

  const onClickOfAutoStrings = (): boolean => {
    let isError: boolean = false;
    let numberOfMpptCount = cheapestProduct?.numberOfMPPTrackers;
    if (Array.isArray(inverterProduct)) {
      numberOfMpptCount = inverterProduct.reduce((sum, product) => {
        return sum + (Number(product?.item?.numberOfMPPTrackers) || 0) / 1000;
      }, 0);
    }
    if (!documentRef.current?.panelGrids?.length) {
      isError = true;
      toast.warning(t("Please Draw one panel Grid!!"));
      return isError;
    }
    if (!numberOfMpptCount) {
      isError = true;
      toast.warning(t("Please include the number of MPPTs for the selected inverter."));
      return isError;
    }
    const panelData = getPanelData(documentRef.current.panelGrids);
    // const generatedAutoStrings = autoStringAssignment(formatedSolarData, numberOfMpptCount);
    const data = documentRef.current.panelGrids.map((ele: any, gridIndex: number) => {
      return ele.children
        .slice(1)
        .filter((e: any) => e.name === "supported")
        .map((e: any, panelIndex: number) => {
          const position = calculatePanelCenter(e.children[0]);
          let randomColor;
          do {
            randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
          } while (randomColor === "#ffffff");

          const surfacePosition = getSurfacePosition(position, e.children[0], 0.1);
          const azimuth = panelData?.[gridIndex]?.azimuth;
          const direction = getDirectionFromAzimuth(azimuth);
          return {
            point: surfacePosition,
            panel: e,
            color: randomColor,
            name: `string-${direction}-${gridIndex}`,
          };
        });
    });
    const updatedDataWithOrder = data.map((ele: any) => {
      const opOrder = calculateOptimalCabling(ele);
      return opOrder;
    });
    const newGrids = assignInvertersToGrids(
      updatedDataWithOrder,
      inverterProduct.filter((inv) => inv.item !== null).map((inv) => inv.item as ProductDB),
    );
    setCablingLoops(newGrids.assignments ?? []);
    setHistoryCablingPolygons(newGrids.assignments ?? []);
    return isError;
  };

  const selectedProducts = useMemo(
    () => ({
      inverters:
        inverterProduct.length > 0
          ? inverterProduct.map((product) => product.item || cheapestProduct)
          : [cheapestProduct],
      interterOutput,
      solarPanel: solarPanelProduct?.item ? solarPanelProduct.item : cheapestSolarpanels,
    }),
    [cheapestProduct, cheapestSolarpanels, interterOutput, inverterProduct, solarPanelProduct],
  );

  useEffect(() => {
    let quantityToUse = quantity;
    const gridsQuantity = solardata.reduce((acc, data) => acc + data.details.moduleQuantity, 0);
    if (gridsQuantity) {
      quantityToUse = gridsQuantity;
    }
    let systemCapacity = quantityToUse * getAverageCapacity(filteredSolarPanels);
    if (solarPanelProduct?.item) {
      systemCapacity = Number(solarPanelProduct?.item.capacity) * quantityToUse;
    }
    dispatch(ProjectActions.deleteInverter());
    const bestCombination = findOptimalInverter(systemCapacity, inverters);
    if (bestCombination.inverters) {
      bestCombination.inverters.forEach((inverter) => {
        dispatch(ProjectActions.addInverter(inverter));
      });
    }
  }, [open, solardata]);

  return (
    <div
      className="bg-white rounded-md relative z-[1000] mt-2 cursor-pointer"
      onClick={() => setOpen(!open)}
    >
      <div className="p-2">
        <div className="flex items-center gap-2 mb-4">
          {isEditing ? (
            <input
              type="text"
              value={configName}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              autoFocus
              className="border border-gray-300 rounded px-2 py-1 text-[#2B3674] text-2xl font-semibold focus:outline-none"
            />
          ) : (
            <h1 className="text-[#2B3674] text-2xl font-semibold">{configName}</h1>
          )}
          <button
            className="text-[#2B3674] hover:text-[#1B254B] transition-colors"
            onClick={handleEditClick}
          >
            <Edit className="w-5 h-5" />
            <span className="sr-only">Edit configuration name</span>
          </button>
        </div>

        <div className="flex flex-wrap items-center gap-2">
          <div className="flex items-center gap-3">
            <Solarpanel className="w-[28px] h-[28px]" />
            <div>
              <span className="text-xl font-semibold text-[#2B3674]">
                {formatNumber(systemCapacity < 1 ? systemCapacity : Math.round(systemCapacity))}
              </span>
              <span className="text-[#2B3674] ml-1">kWp</span>
            </div>
          </div>

          <div className="flex items-center gap-3">
            <PowerButton className="h-[28px] w-[28px]" />
            <div>
              <span className="text-xl font-semibold text-[#2B3674]">
                {interterOutput?.toFixed(2)}
              </span>
              <span className="text-[#2B3674] ml-1">kWh</span>
            </div>
          </div>
          {isBatteryAdded && (
            <div className="flex items-center gap-3">
              <BatteryButtonIcon className="w-[28px] h-[28px]" />
              <div>
                <span className="text-xl font-semibold text-[#2B3674]">
                  {batteryOutput?.toFixed(2)}
                </span>
                <span className="text-[#2B3674] ml-1">kWh</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <ArrowIcon open={open} setOpen={setOpen} />
      {open && (
        <SolarConfiguration
          cablingLoops={cablingLoops}
          setCablingLoops={setCablingLoops}
          selectedProducts={selectedProducts}
          onClickOfAutoStrings={onClickOfAutoStrings}
          menu={menu}
          onActiveMenuItem={onActiveMenuItem}
          activeGridChanged={activeGridChanged}
          documentRef={documentRef}
          setSolarDataReload={setSolarDataReload}
          setHistoryCablingPolygons={setHistoryCablingPolygons}
          setShowSelectionMenu={setShowSelectionMenu}
        />
      )}
      {/* <SystemInspection /> */}
    </div>
  );
};

export default SystemConfiguration;
