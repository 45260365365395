import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ProductFilters, useNumberFormat } from "src/hooks";

import {
  BatteryButtonIcon,
  DeleteIconStep2,
  HeatpumpButtonIcon,
  WallboxButtonIcon,
} from "src/assets/svgs";
import { ProductDB, ProductWithPrice } from "src/db-types";
import { getCheapestProduct, productHasImage } from "src/helpers";
import { AddProductButton } from "src/pages/ApplicationPage/components/ApplicationStep2/components/AddProductButtons/components";
import { ProductPopupProps } from "src/popups";
import {
  getImageFromProductName,
  getTitleFromProductName,
  InfoIcon,
  Step2ComponentKeys,
} from "src/shared/components";
import {
  BatteryFilters,
  HeatpumpFilters,
  InverterFilters,
  SolarPanelFilters,
  WallboxFilters,
} from "src/shared/components/ProductCard/components/Filters";

import { PopupActions, ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { FilterableBaseComponent, ProjectComponentsKeys } from "src/redux/project/types/components";
import { ComponentCosts } from "src/redux/project/types/financial-details";
import { ProductSelectors, ProjectSelectors, UserSelectors } from "src/redux/selectors";

interface ProductCardProps {
  productName: Step2ComponentKeys;
  onDeleteClick?: () => void;
  item?: ProductDB | null;
  onAddProduct?: () => void;
  isToShowButtons?: boolean;
  isItemAdded?: boolean;
  standalone?: {
    filteredProducts: ProductWithPrice[];
    productState: FilterableBaseComponent;
    componentCosts: ComponentCosts;
    onPreferenceChange: (filters: ProductFilters) => void;
    isBatteryAdded?: boolean;
  };
  documentRef?: any;
  isToShowReplaceButton?: boolean;
}

const ProductSection: React.FC<ProductCardProps> = (props) => {
  const {
    productName,
    onDeleteClick,
    onAddProduct,
    standalone,
    item,
    isToShowButtons = true,
    isItemAdded = false,
    documentRef,
    isToShowReplaceButton = true
  } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const filtered = useSelector(ProductSelectors.getAllFilteredProducts);
  const installer = useSelector(UserSelectors.getInstaller);
  const panelGrids = documentRef?.current?.panelGrids;
  const panelCounts = panelGrids
    ?.flatMap((grid: any) => grid.children || [])
    ?.filter((ele: any) => ele.name === "supported" && !ele?.isNotVisible)?.length || 0;
    
  const {
    costs: { componentCosts },
  } = useSelector(ProjectSelectors.getFinancialDetails);
  const components = useSelector(ProjectSelectors.getComponents);
  const productState =
    productName === "inverter"
      ? components.inverter.items.find((data) => data.item?._id === item?._id)
      : components[productName];

  const filteredProducts = useMemo(() => {
    let products = [];
    switch (productName) {
      case "solarPanel":
        products = filtered.solarPanels;
        break;
      case "battery":
        products = filtered.batteries;
        break;
      case "heatpump":
        products = filtered.heatpumps;
        break;
      case "inverter":
        products = filtered.inverters;
        break;
      case "wallbox":
        products = filtered.wallboxes;
        break;
      case "additionalSoftware":
        products = filtered.additionalSoftware;
        break;
      case "additionalHardware":
        products = filtered.additionalHardware;
        break;
      case "EMS":
        products = filtered.EMS;
        break;
    }
    return products;
  }, [
    filtered.batteries,
    filtered.heatpumps,
    filtered.inverters,
    filtered.solarPanels,
    filtered.wallboxes,
    filtered.additionalSoftware,
    filtered.additionalHardware,
    filtered.EMS,
    productName,
  ]);

  const buttonIcon = useMemo(() => {
    switch (productName) {
      case "battery":
        return <BatteryButtonIcon />;
      case "heatpump":
        return <HeatpumpButtonIcon />;
      case "wallbox":
      case "EMS":
        return <WallboxButtonIcon />;
      default:
        return isItemAdded ? <Minus className="w-5 h-5 mr-2" /> : <Plus className="w-5 h-5 mr-2" />;
    }
  }, [productName, isItemAdded]);

  const cheapestProduct = useMemo(() => {
    return getCheapestProduct(filteredProducts);
  }, [filteredProducts]);

  const cheapestProductHasImage = productHasImage(cheapestProduct);

  const imageSrc = useMemo(() => {
    return getImageFromProductName(productName);
  }, [productName]);

  const openInfoPopup = useCallback(
    (productName: Exclude<ProjectComponentsKeys, "cars" | "waterHeating">) => {
      dispatch(
        PopupActions.openProductInfoPopup({
          type: productName,
        }),
      );
    },
    [dispatch],
  );

  const openProductPopup = useCallback(() => {
    const productActions: Record<string, (prod: any) => void> = {
      inverter: (prod) => dispatch(ProjectActions.updateInverter({ item: prod })),
      battery: (prod) => dispatch(ProjectActions.updateBattery({ item: prod })),
      heatpump: (prod) => dispatch(ProjectActions.updateHeatpump({ item: prod })),
      wallbox: (prod) => dispatch(ProjectActions.updateWallbox({ item: prod })),
    };
  
    const onSelectCallback = (prod: any) => {
      if (productActions[productName]) {
        productActions[productName](prod);
        dispatch(PopupActions.closePopup(POPUP_KEYS.product));
      } else {
        console.warn("Unknown product type:", productName);
      }
    };
  
    const data: ProductPopupProps = {
      type: productName,
      onSelectCallback,
      item
    };
  
    dispatch(PopupActions.openPopup(POPUP_KEYS.product, data));
  }, [dispatch, item, productName]);
  
  const { formatNumber } = useNumberFormat();

  const generatePriceString = useCallback(
    (price: number) => {
      return `~€${formatNumber(price)}`;
    },
    [formatNumber],
  );

  const getRelaventPrice = useCallback(
    (productName: Step2ComponentKeys) => {
      const {
        solarPanelCost,
        batteryCost,
        heatpumpCost,
        inverterCost,
        wallboxCost,
        additionalSoftwareCost,
        additionalHardwareCost,
        EMSCost,
      } = standalone?.componentCosts ?? componentCosts;

      switch (productName) {
        case "battery":
          return batteryCost;
        case "heatpump":
          return heatpumpCost;
        case "inverter":
          return inverterCost;
        case "wallbox":
          return wallboxCost;
        case "solarPanel":
          return solarPanelCost;
        case "additionalSoftware":
          return additionalSoftwareCost;
        case "additionalHardware":
          return additionalHardwareCost;
        case "EMS":
          return EMSCost;
      }
    },
    [componentCosts, standalone?.componentCosts],
  );

  const Price = useMemo(() => {
    const cost = getRelaventPrice(productName);
    const bulkPriceProduct = item?.bulkPrice?.[`${installer?._id || "ADMIN"}`];
    return !item
      ? generatePriceString(cost)
      : productState &&
          generatePriceString(
            (bulkPriceProduct
              ?.filter((bulkPrice) => Number(bulkPrice.quantity) <= productState.quantity)
              .sort((a, b) => Number(b.quantity) - Number(a.quantity))[0]?.priceb2c ||
              item?.priceB2c) *
              (Number(productState.quantity) || Number(standalone?.productState.quantity)),
          );
  }, [generatePriceString, getRelaventPrice, productName, productState, item, installer, panelCounts]);

  const Subtitle = useMemo(() => {
    const cost = getRelaventPrice(productName);
    if (productName === "solarPanel")
      return `${panelCounts} ${t("Panels")}`;
    else
      return `${standalone?.productState.quantity ?? productState?.quantity} x €${formatNumber(
        cost,
      )}`;
  }, [
    formatNumber,
    getRelaventPrice,
    productName,
    productState?.quantity,
    standalone?.productState.quantity,
    panelCounts,
  ]);

  return (
    <div className="flex-1 p-2 bg-white min-w-72 text-[#2B3674]">
      {isToShowButtons && !isItemAdded && (
        <div className="flex justify-center mb-4">
          <AddProductButton
            text={`${i18n.language === "en" ? `${t("Add")} ${t(getTitleFromProductName(productName))}` : `${t(getTitleFromProductName(productName))} ${t("Add")}`}`}
            ImageComponent={buttonIcon}
            onClick={onAddProduct ?? (() => {})}
            onInfoIconClick={() => openInfoPopup(productName)}
          />
        </div>
      )}
      {isItemAdded && (
        <div className="max-w-sm rounded-3xl overflow-hidden shadow-[1px_1px_10px_0px_#0000001A] bg-white p-6">
          <div className="flex justify-between items-baseline">
            <div className="flex gap-4 mb-4">
              <div className="font-bold text-xl">{t(getTitleFromProductName(productName))}</div>
              <InfoIcon onClick={() => openInfoPopup(productName)} />
            </div>
            {onDeleteClick && (
              <button className="delete-button" onClick={onDeleteClick}>
                <DeleteIconStep2 />
              </button>
            )}
          </div>
          <h4 className="text-base font-medium text-[#2B3674]">{item?.name}</h4>
          <div className="w-[250px] mt-2 mx-auto">
            {item && <img src={item?.imgUrls?.[0]} className="mt-[5px] px-8 text-[1rem] mx-auto" />}
            {cheapestProductHasImage && !item && (
              <img
                src={cheapestProduct?.imgUrls?.[0]}
                className="mt-[5px] px-8 text-[1rem] mx-auto"
              />
            )}
            {!cheapestProductHasImage && !item && (
              <img src={imageSrc} className="mt-[5px] px-8 text-[1rem] mx-auto" />
            )}{" "}
          </div>
          <div className="my-2">
            <div className="price">{Price}</div>
            <div className="sub-title">{Subtitle}</div>
          </div>
          {productName === "battery" && <BatteryFilters />}
          {productName === "wallbox" && <WallboxFilters />}
          {productName === "heatpump" && <HeatpumpFilters />}
          {productName === "solarPanel" && <SolarPanelFilters />}
          {productName === "inverter" && <InverterFilters />}

          {filteredProducts && filteredProducts?.length > 0 && isToShowReplaceButton && (
            <div
              onClick={openProductPopup}
              className="p-2 bg-primary-color text-[#2B3674] text-center rounded-lg mt-4 cursor-pointer"
            >
              {t(getTitleFromProductName(productName))} {t("Replace")} 
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function Plus(props: React.ComponentProps<"svg">) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 5v14" />
      <path d="M5 12h14" />
    </svg>
  );
}

function Minus(props: React.ComponentProps<"svg">) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
    </svg>
  );
}

export default ProductSection;