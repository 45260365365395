import { Quaternion, Euler, Vector3 } from "three";

// const getPanelData = (panelGrids) => {
//   const RAD2DEG = 180 / Math.PI;
//   const pd = [];

//   panelGrids.forEach((pg) => {
//     const panelData = pg.occupancy.results[0];
//     if (panelData?.indicator) {
//       const worldQuaternion = new Quaternion();

//       if (pg.setup !== "tent") {
//         const supportedPanels = pg.children.filter((ele) => ele.name === "supported");
//         if (supportedPanels.length > 0 && supportedPanels[0].children.length > 0) {
//           supportedPanels[0].children[0].getWorldQuaternion(worldQuaternion);

//           const forward = new Vector3(0, 0, 1).applyQuaternion(worldQuaternion);
//           const upVector = new Vector3(0, 1, 0);
//           const normal = new Vector3(0, 0, 1).applyQuaternion(worldQuaternion);

//           let azimuth = Math.atan2(forward.x, forward.z) * RAD2DEG;
//           azimuth = (azimuth + 360) % 360;
//           if (azimuth > 180) azimuth -= 360;

//           let tilt = Math.acos(normal.dot(upVector)) * RAD2DEG;
//           if (tilt > 90) tilt = 180 - tilt;

//           pd.push({
//             type: panelData.object.name,
//             moduleQuantity: supportedPanels.slice(1).filter((ele) => !ele?.isNotVisible)?.length,
//             orientation: worldQuaternion.clone(),
//             tilt,
//             azimuth,
//           });
//         }
//       } else {
//         const supportedPanels = pg.children.filter((ele) => ele.name === "supported");
//         if (supportedPanels.length > 1 && supportedPanels[0].children.length > 0 && supportedPanels[1].children.length > 0) {
//           supportedPanels[0].children[0].getWorldQuaternion(worldQuaternion);
//           const forward = new Vector3(0, 0, 1).applyQuaternion(worldQuaternion);
//           const upVector = new Vector3(0, 1, 0);
//           const normal = new Vector3(0, 0, 1).applyQuaternion(worldQuaternion);

//           let azimuth = Math.atan2(forward.x, forward.z) * RAD2DEG;
//           azimuth = (azimuth + 360) % 360;
//           if (azimuth > 180) azimuth -= 360;

//           let tilt = Math.acos(normal.dot(upVector)) * RAD2DEG;
//           if (tilt > 90) tilt = 180 - tilt;

//           supportedPanels[1].children[0].getWorldQuaternion(worldQuaternion);
//           const forwardOpposite = new Vector3(0, 0, 1).applyQuaternion(worldQuaternion);
//           const normalOpposite = new Vector3(0, 0, 1).applyQuaternion(worldQuaternion);

//           let azimuthOpposite = Math.atan2(forwardOpposite.x, forwardOpposite.z) * RAD2DEG;
//           azimuthOpposite = (azimuthOpposite + 360) % 360;
//           if (azimuthOpposite > 180) azimuthOpposite -= 360;

//           let tiltOpposite = Math.acos(normalOpposite.dot(upVector)) * RAD2DEG;
//           if (tiltOpposite > 90) tiltOpposite = 180 - tiltOpposite;

//           pd.push({
//             type: panelData.object.name,
//             moduleQuantity: Math.floor(supportedPanels.slice(1).filter((ele) => !ele?.isNotVisible)?.length / 2),
//             orientation: worldQuaternion.clone(),
//             tilt,
//             azimuth,
//             tent: true,
//           });
//           pd.push({
//             type: panelData.object.name,
//             moduleQuantity: Math.floor(supportedPanels.slice(1).filter((ele) => !ele?.isNotVisible)?.length) -
//               Math.floor(supportedPanels.slice(1).filter((ele) => !ele?.isNotVisible)?.length / 2),
//             orientation: worldQuaternion.clone(),
//             tilt: tiltOpposite,
//             azimuth: azimuthOpposite,
//             tent: true,
//           });
//         }
//       }
//     }
//   });
//   return pd;
// };

const getPanelData = (panelGrids) => {
  const RAD2DEG = 180 / Math.PI;

  const normalizeAzimuth = (azimuth) => {
    azimuth = azimuth % 360;
    if (azimuth > 180) {
      azimuth -= 360;
    } else if (azimuth < -180) {
      azimuth += 360;
    }
    return azimuth;
  };

  const pd = [];
  panelGrids.forEach((pg) => {
    const panelData = pg.occupancy.results[0];
    if (panelData?.indicator) {
      const worldQuaternion = new Quaternion();

      if (pg.setup !== "tent") {
        if (
          pg.children.filter((ele) => ele.name === "supported").length > 0 &&
          pg.children.filter((ele) => ele.name === "supported")[0].children.length > 0
        ) {
          pg.children
            .filter((ele) => ele.name === "supported")[0]
            .children[0].getWorldQuaternion(worldQuaternion);

          // Apply Coordinate System Transformation
          const transformedQuaternion = new Quaternion();
          transformedQuaternion.setFromEuler(new Euler(0, Math.PI / 2, 0, "YXZ")); // Rotate 90 degrees around Y-axis
          transformedQuaternion.multiply(worldQuaternion);

          // Extract Azimuth Directly from Transformed Quaternion
          let azimuth = Math.atan2(2 * (transformedQuaternion.y * transformedQuaternion.w - transformedQuaternion.x * transformedQuaternion.z), 1 - 2 * (transformedQuaternion.y * transformedQuaternion.y + transformedQuaternion.z * transformedQuaternion.z)) * RAD2DEG;

          let tilt = RAD2DEG * Math.asin(2 * (transformedQuaternion.x * transformedQuaternion.y + transformedQuaternion.z * transformedQuaternion.w));

          // Normalize tilt to [0, 90]
          tilt = Math.abs(tilt % 360);
          if (tilt > 90) {
            tilt = 180 - tilt;
          }

          // Normalize azimuth to [-180, 180]
          azimuth = normalizeAzimuth(azimuth);

          pd.push({
            type: panelData.object.name,
            moduleQuantity: pg.children
              .slice(1)
              .filter((ele) => ele.name === "supported" && !ele?.isNotVisible)?.length,
            orientation: transformedQuaternion.clone(),
            tilt,
            azimuth,
          });
        }
      } else {
        // ... (tent logic - similar coordinate system correction)
      }
    }
  });
  return pd;
};

export const directions = [
  { min: -180, max: -157.5, dir: "S" },
  { min: -157.5, max: -112.5, dir: "SW" },
  { min: -112.5, max: -67.5, dir: "W" },
  { min: -67.5, max: -22.5, dir: "NW" },
  { min: -22.5, max: 22.5, dir: "N" },
  { min: 22.5, max: 67.5, dir: "NE" },
  { min: 67.5, max: 112.5, dir: "E" },
  { min: 112.5, max: 157.5, dir: "SE" },
  { min: 157.5, max: 180, dir: "S" },
];

export const getDirectionFromAzimuth = (azimuth) => {
  return directions.find(({ min, max }) => azimuth >= min && azimuth < max)?.dir || "";
};

export default getPanelData;
