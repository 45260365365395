export enum POPUP_KEYS {
  "installer_confirmation" = "INSTALLER_CONFIRMATION",
  "auth" = "AUTH_POPUP",
  "webinar" = "WEBINAR_POPUP",
  "webinar_welcome" = "WELCOME_WEBINAR",
  "welcome_address" = "WELCOME_ADDRESS",
  "webinar_project" = "WEBINAR_PROJECT_DETAILS",
  "consumption_profile" = "CONSUMPTION_PROFILE_DIALOG",
  "building_type" = "SELECT_BUILDING_TYPE",
  "product" = "PRODUCT_POPUP",
  "car" = "CAR_SELECTOR",
  "consumption_charts" = "CONSUMPTION_CHARTS",
  "hubspot_meeting" = "HUBSPOT_MEETING",
  "PARTNER_CITY_CONSULTINNG" = "PARTNER_CITY_CONSULTINNG",
  "productDetailsPopup" = "PRODUCT_DETAILS_POPUP",
  "productInfo" = "PRODUCT_INFO",
  "pay_wall" = "PAY_WALL",
  "payment_with_card" = "PAYMENT_WITH_CARD",
  "solar_layout_popup" = "SOLAR_LAYOUT",
  "request_pv_model" = "REQUEST_PV_MODEL",
  "offer_feedback" = "OFFER_FEEDBACK",
  "multi_tenant" = "MULTI_TENANT",
  "installer_offer" = "INSTALLER_OFFER",
  "data_policy" = "DATA_POLICY",
  "saved_project_user_info" = "SAVED_PROJECT_USER_INFO",
  "subsidy" = "SUBSIDY",
  "pdfPreview"="PDF_PREVIEW",
  "threedmodel"="THREEDMODEL",
  "realisationtime"="REALISATIONTIME",
  "pdf_template_selection" = "PDF_TEMPLATE_SELECTION",
  "inverter_auto_string_details" = "INVERTER_AUTO_STRING_DETAILS",
}
