import { Checkbox } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useNumberFormat, useWindowDimensions } from "src/hooks";

import { GermanFlagStep2 } from "src/assets/svgs";
import { ProductDB, ProductPropertiesKey } from "src/db-types";
import { getProductCost } from "src/helpers";

import {
  MainContainer,
  MainText,
  PlusIconWrapper,
  SubContainer1,
  SubContainer2,
  SubContainer21,
  SubContainer3,
  SubText,
  SubText1,
} from "./ProductViewCard.styles";

import { ProjectActions } from "src/redux/actionCreators";
import { openProductDetailsPopup } from "src/redux/popups/action/action.creators";
import {
  addAdditionalHardware,
  addAdditionalSoftware,
  addBatteryProduct,
  addEMSProduct,
  addHeatpumpProduct,
  addInverter,
  addSolarPanel,
  addWallboxProduct,
} from "src/redux/project/action/action.creators";
import { PopupSelectors, ProjectSelectors, UserSelectors } from "src/redux/selectors";

interface ProductViewCardProps {
  product: ProductDB;
  quantityToBuy: number;
  productName: string;
  handleCloseModal?: () => void;
}
export const ProductViewCard: React.FC<ProductViewCardProps> = (props: ProductViewCardProps) => {
  const { product, quantityToBuy, productName, handleCloseModal } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectType = useSelector(ProjectSelectors.getProjectType);
  const installer = useSelector(UserSelectors.getInstaller);
  const popups = useSelector(PopupSelectors.getPopup);
  const { formatNumber } = useNumberFormat();
  const { width } = useWindowDimensions();
  const [showFlag, setShowFlag] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const generatePriceString = useCallback(
    (price: number) => {
      return `~€${formatNumber(price)}`;
    },
    [formatNumber],
  );

  const handleImageClick = useCallback(() => {
    // if (installer?._id || popups.MULTI_TENANT.open) {
      if (productName === "solarPanel") {
        dispatch(addSolarPanel(product));
        dispatch(
          ProjectActions.updateSolarPanel({
            preferences: { ...product?.properties },
          }),
        );
      }
      if (productName === "inverter") {
        dispatch(addInverter(product));
        dispatch(
          ProjectActions.updateInverter({
            preferences: { ...product?.properties },
          }),
        );
      }
      if (productName === "battery") {
        dispatch(addBatteryProduct(product));
        dispatch(
          ProjectActions.updateBattery({
            preferences: { ...product?.properties },
          }),
        );
      }
      if (productName === "wallbox") {
        dispatch(addWallboxProduct(product));
        dispatch(
          ProjectActions.updateWallbox({
            preferences: { ...product?.properties },
          }),
        );
      }
      if (productName === "heatpump") {
        dispatch(addHeatpumpProduct(product));
        dispatch(
          ProjectActions.updateHeatpump({
            preferences: { ...product?.properties },
          }),
        );
      }
      if (productName === "additionalSoftware") {
        dispatch(addAdditionalSoftware(product));
        dispatch(
          ProjectActions.updateAdditionalSoftware({
            preferences: { ...product?.properties },
          }),
        );
      }
      if (productName === "additionalHardware") {
        dispatch(addAdditionalHardware(product));
        dispatch(
          ProjectActions.updateAdditionalHardware({
            preferences: { ...product?.properties },
          }),
        );
      }
      if (productName === "EMS") {
        dispatch(addEMSProduct(product));
        dispatch(
          ProjectActions.updateEMS({
            preferences: { ...product?.properties },
          }),
        );
      }
      setIsSelected(!isSelected);
    // }
    handleCloseModal && handleCloseModal();
  }, [product, installer]);

  useEffect(() => {
    const { properties } = product;
    setShowFlag(properties?.["Made in Germany"] ?? false);
  }, [product]);

  const totalPrice = useMemo(() => {
    const total =
      quantityToBuy * getProductCost({ product, projectType, quantityToBuy, installer });
    return generatePriceString(total);
  }, [generatePriceString, product, projectType, quantityToBuy]);

  const ProductDetails = useMemo(() => {
    return (
      <SubContainer2>
        <MainText>{product.name}</MainText>
        <SubText>
          {(Object.keys(product.properties ?? {}) as ProductPropertiesKey[])
            .filter((key) => product.properties?.[key])
            .map((key) => {
              return (
                <div key={key}>
                  <Checkbox checked={true} style={{ cursor: "not-allowed" }}>
                    <span>{t(key)}</span>
                  </Checkbox>
                </div>
              );
            })}
        </SubText>
        <SubText1
          onClick={() => {
            dispatch(
              openProductDetailsPopup({
                product,
              }),
            );
          }}
        >
          {t("PRODUCT DETAILS")}
        </SubText1>
      </SubContainer2>
    );
  }, [dispatch, product, t]);

  return (
    <>
      <MainContainer>
        <SubContainer1 onClick={handleImageClick}>
          {showFlag && (
            <SubContainer21>
              <GermanFlagStep2 />
            </SubContainer21>
          )}
          <img src={product.imgUrls?.[0]} style={{ width: "153px", height: "115px" }} />
          {/* {(installer?._id || popups.MULTI_TENANT.open) && ( */}
            <PlusIconWrapper className="hoverd">
              <div> + </div>
            </PlusIconWrapper>
          {/* )} */}
        </SubContainer1>
        {width > 700 && ProductDetails}
        <SubContainer3>
          <div className="text1">{totalPrice}</div>
          <div className="text2">
            {quantityToBuy} x €
            {formatNumber(getProductCost({ product, projectType, quantityToBuy, installer }))}
          </div>
        </SubContainer3>
      </MainContainer>
      {width < 700 && ProductDetails}
    </>
  );
};
