import { Input } from "antd";
import { Check, ChevronRight, Edit, Save } from "lucide-react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useNumberFormat } from "src/hooks";

import { ProductWithPrice } from "src/db-types";

import { POPUP_KEYS } from "src/redux/popups";
import { openPopup } from "src/redux/popups/action/action.creators";

interface AutoStringInputProps {
  loop: any;
  index: number;
  onEdit: (index: number, name: string) => any;
  selectedProducts: any;
  onPopupOpen?: any;
}

const AutoStringInput: React.FC<AutoStringInputProps> = ({
  loop,
  index,
  onEdit,
  selectedProducts,
  onPopupOpen,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(loop?.[0]?.name || "");
  const { formatNumber } = useNumberFormat();
  const connectedPanelsToString = loop.length;
  const VOC_STC = selectedProducts?.solarPanel?.VOC_STC;
  const T_COEFFICIENT = selectedProducts?.solarPanel?.T_COEFFICIENT;
  const T_MIN = selectedProducts?.solarPanel?.T_MIN;
  const T_STC = selectedProducts?.solarPanel?.T_STC;

  const VOC_ADJUSTED = VOC_STC * (1 + T_COEFFICIENT * (T_MIN - T_STC));
  const VDC_MAX = VOC_ADJUSTED * connectedPanelsToString;

  const maxDcCurrent = useMemo(() => {
    if (!selectedProducts?.solarPanel) return 0;

    const { I_SC } = selectedProducts.solarPanel as ProductWithPrice;

    return I_SC * 1 * 1.25;
  }, [selectedProducts?.solarPanel]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    onEdit(index, inputValue);
  };

  return (
    <>
      <div className="flex items-center gap-2 pt-2">
        <ChevronRight
          className={`w-5 h-5 cursor-pointer ${isExpanded && "rotate-90"}`}
          onClick={() => setIsExpanded(!isExpanded)}
        />
        <div
          className="cursor-pointer"
          onClick={() => onPopupOpen && onPopupOpen(VDC_MAX, selectedProducts?.inverter?._id)}
        >
          <Check className="h-4 w-4" style={{ color: "green" }} />
        </div>
        {isEditing ? (
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="w-1/2 outline-none rounded-md"
          />
        ) : (
          <h3 className="font-medium">{loop?.[0]?.name}</h3>
        )}
        {isEditing ? (
          <Save className="w-4 h-4 cursor-pointer" onClick={handleSaveClick} />
        ) : (
          <Edit className="w-4 h-4 cursor-pointer" onClick={handleEditClick} />
        )}
        <div className="w-2 h-2 rounded-full" style={{ background: loop?.[0]?.color || "green" }} />
        <div>{formatNumber(VDC_MAX)}V</div>
      </div>
      {isExpanded && (
        <div className="mt-2 space-y-2 text-[#2B3674]">
          <p>
            {connectedPanelsToString} * {selectedProducts.solarPanel?.applicableMaxCapacity} Watt ={" "}
            {(
              (connectedPanelsToString *
                (selectedProducts.solarPanel?.applicableMaxCapacity ?? 1)) /
              1000
            ).toFixed(2)}{" "}
            kWP
          </p>
          {!!VDC_MAX && (
            <p>
              {t("Maximum voltage")} (Voc): {VDC_MAX.toFixed(2)} V
            </p>
          )}
          {!isNaN(maxDcCurrent) && !!maxDcCurrent && (
            <p>
              {t("Maximum current")} (Isc): {maxDcCurrent.toFixed(2)} A
            </p>
          )}
          {selectedProducts?.solarPanel?.maxOutputPower && (
            <p>
              {t("Power")} {selectedProducts?.solarPanel?.maxOutputPower} kWp
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default AutoStringInput;
