import { cloneDeep } from "lodash";
import { toast } from "react-toastify";

import { ProductDB } from "src/db-types";

import { MainUserState } from "src/redux/user";

export const userIsNotAuthenticated = (user: MainUserState | null): boolean => {
  return !user;
};

export const userIsAuthenticated = (user: MainUserState | null): boolean => {
  return !userIsNotAuthenticated(user);
};

export const imageUrlToBase64 = async (url: string): Promise<any> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

export const autoStringAssignment = (
  grids: any[],
  totalMPPTCount: number,
  azimuthTolerance: number = 10,
) => {
  const strings: any[] = [];
  const usedMPPTCount = 0;

  // Group grids by roof
  const groupedGrids: Record<string, any[]> = {};
  grids.forEach((grid) => {
    const roofKey = Object.keys(groupedGrids).find((key) => {
      const azimuthDiff = Math.abs(groupedGrids[key][0].azimuth - grid.azimuth);
      return azimuthDiff <= azimuthTolerance;
    });

    if (roofKey) {
      groupedGrids[roofKey].push(grid);
    } else {
      groupedGrids[`roof-${Object.keys(groupedGrids).length}`] = [grid];
    }
  });

  console.log("Grouped Grids by Roof:", groupedGrids);

  const groupedPanelsCount = Object.values(groupedGrids).map((arr) =>
    arr.reduce((acc, panel) => acc + panel.moduleQuantity, 0),
  );
  console.log("groupedPanelsCount", groupedPanelsCount);

  const totalPanels = groupedPanelsCount.reduce((acc, c) => acc + c, 0);
  let currentMPPTTarget = totalMPPTCount;
  let currentPanelTarget = totalPanels / currentMPPTTarget;

  console.log("currentMPPTTarget", currentMPPTTarget);
  console.log("currentPanelTarget", currentPanelTarget);

  while (currentMPPTTarget > 0) {
    if (groupedPanelsCount.every((c) => !(c % currentPanelTarget))) {
      console.log("Utilised MPP:", currentMPPTTarget);
      console.log("Finalised group count", currentPanelTarget);
      const result: any = [];
      let currentPanelCount = 0;
      let groupIndex = 1;
      let gridsOffset = 0;

      for (const [roofKey, grids] of Object.entries(groupedGrids)) {
        grids.forEach((grid: any, gridIndex: number) => {
          for (let i = 0; i < grid.moduleQuantity; i++) {
            if (currentPanelCount % currentPanelTarget === 0) {
              let randomColor;
              do {
                randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
              } while (randomColor === "#ffffff");

              result.push({
                name: `string-${groupIndex}`,
                color: randomColor,
                value: [],
              });
              groupIndex++;
            }
            result[result.length - 1].value.push({
              gridIndex: gridIndex + gridsOffset,
              panelIndex: i,
            });
            currentPanelCount++;
          }
        });
        gridsOffset += grids.length;
      }

      return result;
    }
    currentMPPTTarget--;
    currentPanelTarget = totalPanels / currentMPPTTarget;
  }

  toast.warning("Grouping of panels not possible for the current inverter");

  return strings;
};

export const findOptimalInverter = (capacity: number, inverters: ProductDB[]) => {
  const sizingFactor = 1.15;
  const requiredCapacity = capacity / sizingFactor; // Adjusted for sizing factor
  const maxTolerance = 1000; // Maximum allowed excess capacity

  // Filter valid inverters
  const availableInverters = inverters
    .filter((inv) => inv.applicableMaxCapacity)
    .map((inv) => ({ ...inv, applicableMaxCapacity: inv.applicableMaxCapacity! })) // Ensure it's defined
    .sort((a, b) => b.applicableMaxCapacity - a.applicableMaxCapacity); // Sort DESCENDING (highest first)

  if (availableInverters.length === 0) {
    return { error: "No inverters available in the database." };
  }

  // 1. **Check for a Single Suitable Inverter (within 1kW tolerance)**
  for (const inverter of availableInverters) {
    if (inverter.applicableMaxCapacity >= requiredCapacity) {
      const excess = inverter.applicableMaxCapacity - requiredCapacity;
      if (excess <= maxTolerance) {
        return { inverters: [inverter] };
      }
    }
  }

  // 2. **Pick the Highest Possible Inverter + Lowest Complement**
  for (const high of availableInverters) {
    for (const low of [...availableInverters].reverse()) {
      if (high === low) continue; // Avoid duplicate check
      const total = high.applicableMaxCapacity + low.applicableMaxCapacity;
      const excess = total - requiredCapacity;
      if (total >= requiredCapacity && excess <= maxTolerance) {
        return { inverters: [high, low] };
      }
    }
  }

  // 3. **Try Two of the Same Inverter**
  for (const inverter of availableInverters) {
    const total = inverter.applicableMaxCapacity * 2;
    const excess = total - requiredCapacity;
    if (total >= requiredCapacity && excess <= maxTolerance) {
      return { inverters: [inverter, inverter] };
    }
  }

  // 4. **Try Three Inverters as a Last Resort**
  let bestCombination: ProductDB[] = [];
  let minWastage = Infinity;
  let foundValidCombination = false;

  const findCombinations = (index: number, selected: ProductDB[], sum: number) => {
    if (sum >= requiredCapacity) {
      const wastage = sum - requiredCapacity;
      if (wastage <= maxTolerance && wastage < minWastage) {
        bestCombination = [...selected];
        minWastage = wastage;
        foundValidCombination = true;
      }
      return;
    }

    for (let i = index; i < availableInverters.length; i++) {
      findCombinations(
        i,
        [...selected, availableInverters[i]],
        sum + availableInverters[i].applicableMaxCapacity,
      );
    }
  };

  findCombinations(0, [], 0);

  if (foundValidCombination) {
    return { inverters: bestCombination };
  }

  // 5. **If No Suitable Combination Found, Return an Explanation**
  const smallestInverter = availableInverters[availableInverters.length - 1].applicableMaxCapacity;
  if (smallestInverter > requiredCapacity) {
    return {
      error: `The smallest available inverter (${smallestInverter}W) is still too large. Minimum required is ${Math.floor(
        requiredCapacity,
      )}W.`,
    };
  }

  return {
    error: `No valid combination of inverters found to match ${Math.floor(
      requiredCapacity,
    )}W with ≤ 1kW excess.`,
  };
};
function getDistance(
  p1: { x: number; y: number; z: number },
  p2: { x: number; y: number; z: number },
): number {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2) + Math.pow(p1.z - p2.z, 2));
}

export const calculateOptimalCabling = (panels: any): any[] => {
  if (panels.length === 0) return [];

  const remainingPanels = [...panels];
  const orderedPanels = [];
  let currentPanel = remainingPanels.shift();

  orderedPanels.push(currentPanel);

  while (remainingPanels.length > 0) {
    let nearestIndex = -1;
    let minDistance = Infinity;

    remainingPanels.forEach((panel, index) => {
      const distance = getDistance(currentPanel.point, panel.point);
      if (distance < minDistance) {
        minDistance = distance;
        nearestIndex = index;
      }
    });

    currentPanel = remainingPanels.splice(nearestIndex, 1)[0];
    orderedPanels.push(currentPanel);
  }

  return orderedPanels;
};

interface Response {
  error?: string;
  assignments?: any[];
}

export const divideCablingLoop = (cablingLoop: any, numParts: number) => {
  const totalPoints = cablingLoop.length;
  const segmentSize = Math.ceil(totalPoints / numParts);
  const dividedLoops = [];

  for (let i = 0; i < numParts; i++) {
    const segmentPoints = cablingLoop.slice(i * segmentSize, (i + 1) * segmentSize);
    if (segmentPoints.length > 0) {
      dividedLoops.push(
        segmentPoints.map((point: any) => ({
          ...point,
          name: `${point.name}-${i + 1}`,
        })),
      );
    }
  }

  return dividedLoops
};

export function assignInvertersToGrids(
  cablingLoops: any[][],
  selectedInverters: ProductDB[],
): Response {
  const assignments: any[] = [];
  const sortedClonedGrids =cablingLoops.sort((a, b) => a.length - b.length);
  if (sortedClonedGrids.length === 0) return { error: "No inverters found" };
  const sortedInverters = selectedInverters.sort(
    (a, b) => (a.applicableMaxCapacity ?? 0) - (b.applicableMaxCapacity ?? 0),
  );
  for (let index = 0; index < sortedInverters.length - 1; index++) {
    const inverter = sortedInverters[index];
    const grid = sortedClonedGrids?.[index];
    if (!grid) return { error: "No grid found for inverter" };
    const assignedInvertersGrid = grid.map((panel: any) => ({
      ...panel,
      inverter: inverter._id,
    }));
    assignments.push(assignedInvertersGrid);
    sortedClonedGrids.splice(index, 1);
    sortedInverters.splice(index, 1);
  }
  console.log({ assignments, sortedClonedGrids })

  // Here we have secound case where always we will have one inverter left
  const inverter = sortedInverters[0];
  let inverterMppCount = inverter.numberOfMPPTrackers;

  // Hanlde the case when we don't have MPP tracker count
  if (!inverterMppCount) {
    if (sortedClonedGrids.length === 1) {
      const dividedCalbingLoops = divideCablingLoop(sortedClonedGrids[0], 2);
      for (const calbelingLoop of dividedCalbingLoops) {
        const assignedInvertersGrid = calbelingLoop.map((panel: any) => ({
          ...panel,
          inverter: inverter._id,
        }));
        assignments.push(assignedInvertersGrid);
      }
    } else {
      const grid = sortedClonedGrids[0];
      const assignedInvertersGrid = grid.map((panel: any) => ({
        ...panel,
        inverter: inverter._id,
      }));
      assignments.push(assignedInvertersGrid);
    }
  } else {
    if(!sortedClonedGrids.length) return { assignments };
    // If number of MPP and grids count are same then just add same inverter to each grid
    if (inverterMppCount === sortedClonedGrids.length) {
      for (let index = 0; index < inverterMppCount; index++) {
        const grid = sortedClonedGrids[index];
        const assignedInvertersGrid = grid.map((panel: any) => ({
          ...panel,
          inverter: inverter._id,
        }));
        assignments.push(assignedInvertersGrid);
      }
    } else if (inverterMppCount < sortedClonedGrids.length) {
      for (let index = 0; index < sortedClonedGrids.length; index++) {
        const grid = sortedClonedGrids[index];
        const assignedInvertersGrid = grid.map((panel: any) => ({
          ...panel,
          inverter: inverter._id,
        }));
        assignments.push(assignedInvertersGrid);
      }
    } else {
      const loopTillonlyOneGridLeft = sortedClonedGrids.length - 1;
      for (let index = 0; index < loopTillonlyOneGridLeft; index++) {
        console.log({ inverterMppCount, loopTillonlyOneGridLeft, sortedClonedGrids }, index)
        const grid = sortedClonedGrids[index];
        const assignedInvertersGrid = grid.map((panel: any) => ({
          ...panel,
          inverter: inverter._id,
        }));
        assignments.push(assignedInvertersGrid);
        inverterMppCount = inverterMppCount - 1;
        sortedClonedGrids.splice(index, 1);
      }

      // Now only one grid left with left MPP tracker
      console.log({firstGrid: sortedClonedGrids[0], inverterMppCount})
      const dividedCalbingLoops = divideCablingLoop(sortedClonedGrids[0], inverterMppCount);
      for (const calbelingLoop of dividedCalbingLoops) {
        const assignedInvertersGrid = calbelingLoop.map((panel: any) => ({
          ...panel,
          inverter: inverter._id,
        }));
        assignments.push(assignedInvertersGrid);
      }
    }
  }

  return { assignments };
}
