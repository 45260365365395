import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAddBatteryInverterChange, useIsComponentAdded } from "src/hooks";

import { ProductCard } from "src/shared/components";

import { AddProductButtonsRow } from "../AddProductButtons/AddProductButtonsRow";

import * as S from "./ProductCards.styles";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

export const ProductCards: React.FC = () => {
  const dispatch = useDispatch();
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const inverterProduct = useSelector(ProjectSelectors.getInverter);
  const wallboxProduct = useSelector(ProjectSelectors.getWallbox);
  const batteryProduct = useSelector(ProjectSelectors.getBattery);
  const heatpumpProduct = useSelector(ProjectSelectors.getHeatpump);
  const EMSProduct = useSelector(ProjectSelectors.getProductEMS)

  const {
    isBatteryAdded,
    isHeatpumpAdded,
    isInvertedAdded,
    isSolarPanelAdded,
    isWallboxAdded,
    isEMSAdded,
  } = useIsComponentAdded();

  const { restoreInverterPreference } = useAddBatteryInverterChange();

  const deleteBattery = useCallback(() => {
    dispatch(ProjectActions.deleteBattery());
    restoreInverterPreference();
  }, [dispatch, restoreInverterPreference]);

  const deleteWallbox = useCallback(() => {
    dispatch(ProjectActions.deleteWallbox());
  }, [dispatch]);

  const deleteHeatpump = useCallback(() => {
    dispatch(ProjectActions.deleteHeatpump());
  }, [dispatch]);

    const deleteEMS = useCallback(() => {
      dispatch(ProjectActions.deleteEMS());
    }, [dispatch]);

  return (
    <>
      <S.ProductCardsContainer>
        {isSolarPanelAdded && (
          <ProductCard productName="solarPanel" item={solarPanelProduct?.item} />
        )}
        {isInvertedAdded && (
          inverterProduct?.map((itm) => (
            <ProductCard key={itm.item?._id} productName="inverter" item={itm.item} />
          ))
        )}
        {isBatteryAdded && (
          <ProductCard
            productName="battery"
            onDeleteClick={deleteBattery}
            item={batteryProduct?.item}
          />
        )}
        {isWallboxAdded && (
          <ProductCard
            productName="wallbox"
            onDeleteClick={deleteWallbox}
            item={wallboxProduct?.item}
          />
        )}
        {isHeatpumpAdded && (
          <ProductCard
            productName="heatpump"
            onDeleteClick={deleteHeatpump}
            item={heatpumpProduct?.item}
          />
        )}
        {isEMSAdded && (
          <ProductCard productName="EMS" onDeleteClick={deleteEMS} item={EMSProduct?.item} />
        )}
        <AddProductButtonsRow />
      </S.ProductCardsContainer>
    </>
  );
};
